import { sendEvent } from 'api';
import CN from 'clsx';
import routes from 'constants/routes';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router';
import type { RootState } from 'store';
import { setSidemenuCollapsed } from 'store/app/thunks';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Icon } from '@albert/shared/components';
import { useMediaMatch } from '@albert/shared/hooks';
import type { Event } from '@albert/shared/services';

import styles from './styles.module.scss';

const SideMenu = () => {
  const { t } = useTranslation('parent');
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const isMobile = useMediaMatch('(max-width: 768px)');
  const SIDEMENU_LINKS = useNavigationLinks();
  const app = useAppSelector(({ app }: RootState) => app);

  const [isMobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [isSidemenuCollapsed, setSidemenuCollapsedState] = useState<boolean>(() => !!app.isSidemenuCollapsed);

  const currentPage =
    Object.values(SIDEMENU_LINKS).find((menuItem) => menuItem.route === pathname) || SIDEMENU_LINKS.home;

  const isCollapsedMenuRoute = [
    routes.SUBSCRIPTION.REACTIVATE,
    routes.SUBSCRIPTION.CHANGE,
    routes.SUBSCRIPTION.CANCEL,
    routes.SUBSCRIPTION.PAYMENT_METHODS,
  ].some((route) => pathname.includes(route));

  useEffect(() => {
    const documentBody = document.body;
    isMobileMenuOpen
      ? documentBody?.classList.add(styles.bodyLockedScroll)
      : documentBody?.classList.remove(styles.bodyLockedScroll);

    if (isMobileMenuOpen) {
      window.scrollTo(0, 0);
    }
  }, [isMobileMenuOpen]);

  useEffect(() => {
    if (isCollapsedMenuRoute) {
      setSidemenuCollapsedState(true);
    } else {
      setSidemenuCollapsedState(!!app.isSidemenuCollapsed);
    }
  }, [isCollapsedMenuRoute]);

  const toggleMobileMenu = () => {
    setMobileMenuOpen((prev) => !prev);
  };

  const toggleSidebarMenu = () => {
    setSidemenuCollapsedState((prev) => !prev);
    dispatch(setSidemenuCollapsed(!isSidemenuCollapsed));
  };

  const handleMenuItemClick = (itemEvent: () => Event) => {
    sendEvent(itemEvent());
    if (isMobile) {
      setMobileMenuOpen(false);
    }
  };

  return (
    <aside
      className={CN(styles.sideMenu, {
        [styles.menuOpen]: isMobileMenuOpen,
        [styles.collapsed]: isSidemenuCollapsed && !isMobile,
      })}
    >
      <div className={styles.innerContainer}>
        {isMobile && (
          <button className={styles.currentPageButton} onClick={toggleMobileMenu}>
            <div>
              <Icon name={currentPage.iconName} size={20} />
              <span>{currentPage.title}</span>
            </div>
            <Icon size={14} name="arrow_down" className={CN({ [styles.arrowOpen]: isMobileMenuOpen })} />
          </button>
        )}

        {!isMobile && (
          <button
            className={CN(styles.toggleSidebarButton, {
              [styles.collapsed]: isSidemenuCollapsed,
            })}
            onClick={toggleSidebarMenu}
          >
            <Icon className={styles.icon} name="sidebar" size={24} />
          </button>
        )}

        {isSidemenuCollapsed && !isMobile ? (
          <div className={styles.separator} />
        ) : (
          <div className={styles.subtitle}>{t('general.pages')}</div>
        )}

        {Object.values(SIDEMENU_LINKS).map((menuItem, index) => {
          if (!menuItem.show) return null;
          return (
            <Fragment key={index}>
              <NavLink
                to={menuItem.route}
                className={CN(styles.menuItem, {
                  [styles.hidden]: isSidemenuCollapsed && !isMobile,
                  [styles.referFriendLink]: menuItem.route === routes.REFER_FRIEND,
                })}
                title={menuItem.title}
                tabIndex={!isMobileMenuOpen ? -1 : undefined}
                onClick={() => handleMenuItemClick(menuItem.event)}
              >
                <Icon name={menuItem.iconName} size={24} />
                <span>{menuItem.title}</span>
              </NavLink>

              {index === 5 && (
                <>
                  {isSidemenuCollapsed && !isMobile ? (
                    <div className={styles.separator} />
                  ) : (
                    <div className={styles.subtitle}>{t('general.membership')}</div>
                  )}
                </>
              )}
            </Fragment>
          );
        })}
      </div>
    </aside>
  );
};

export default SideMenu;
