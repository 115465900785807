import CN from 'clsx';
import type { ReactNode } from 'react';
import { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import Icon from '../Icon';
import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  children: ReactNode;
  overlayClass?: string;
  contentClass?: string;
  closeButtonClass?: string;
  customCloseButton?: ReactNode;
  onClose: () => void;
}

const Modal = ({
  isOpen,
  children,
  overlayClass,
  contentClass,
  closeButtonClass,
  customCloseButton,
  onClose,
}: Props) => {
  const [modalContainer, setModalContainer] = useState<HTMLDivElement | null>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const modalElement = document.createElement('div');
    modalElement.className = 'modal-root';
    document.body.appendChild(modalElement);
    setModalContainer(modalElement);

    return () => {
      if (modalElement.parentNode === document.body) {
        document.body.removeChild(modalElement);
      }
    };
  }, []);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen) {
        onClose();
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (event.button === 0 && modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    if (modalContainer) {
      modalContainer.style.display = isOpen ? 'block' : 'none';

      if (isOpen) {
        modalRef.current?.focus();
        document.body.classList.add(styles.bodyScrollLock);
      }
    }

    return () => {
      document.body.classList.remove(styles.bodyScrollLock);
    };
  }, [isOpen, modalContainer]);

  if (!modalContainer || !isOpen) return null;

  return createPortal(
    <div className={CN(styles.modalOverlay, overlayClass)}>
      <div
        ref={modalRef}
        className={CN(styles.modalContent, contentClass)}
        tabIndex={-1}
        role="dialog"
        aria-modal={isOpen}
      >
        {customCloseButton || (
          <button className={CN(styles.closeButton, closeButtonClass)} onClick={onClose}>
            <Icon size={12} name="close_cross_icon" fill="#5a5a73" />
          </button>
        )}
        {children}
      </div>
    </div>,
    modalContainer,
  );
};

export default Modal;
