import dayjs from 'dayjs';
import { map } from 'lodash';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocale } from 'store/hooks';

import { DATE_FORMAT } from '@albert/shared/constants';

import { WeeklyActivityItem } from '../..';
import styles from './styles.module.scss';

type ActivityWeek = {
  title: string;
  isToday: boolean;
  isHaveProgress: boolean;
  timeSpent: number;
};

interface Props {
  activities?: Record<string, { spentSeconds: number }>;
}

const Activity = ({ activities = {} }: Props) => {
  const { t } = useTranslation('parent');
  const locale = useLocale();

  const _activities = map(activities, (value, key) => ({ date: key, ...value }));
  // The first day should always be Monday
  const firstDay = dayjs().day(1);

  const daysOfWeek: { [key: string]: string } = {
    Mon: t('parentReport.monday'),
    Tue: t('parentReport.tuesday'),
    Wed: t('parentReport.wednesday'),
    Thu: t('parentReport.thursday'),
    Fri: t('parentReport.friday'),
    Sat: t('parentReport.saturday'),
    Sun: t('parentReport.sunday'),
  };

  const getWeekObject = (): ActivityWeek[] =>
    Array.from(new Array(7), (_, i) => {
      const date = firstDay.add(i, 'day');

      const dateActivity = _activities.find(
        (act) => act.date === date.format('ddd MMM DD YYYY') || act.date === date.format(DATE_FORMAT[locale]),
      );

      return {
        title: daysOfWeek[date.format('ddd')],
        isToday: date.isSame(dayjs(), 'day'),
        isHaveProgress: Boolean(dateActivity),
        timeSpent: dateActivity?.spentSeconds ? Math.ceil(dateActivity?.spentSeconds / 60) : 0,
      };
    });

  return (
    <div className={styles.progressCard}>
      <h2 className={styles.cardTitle}>{t('parentReport.activity.title')}</h2>
      <div className={styles.weeklyActivity}>
        {getWeekObject().map((item, index) => (
          <WeeklyActivityItem
            key={index}
            title={item.title}
            isToday={item.isToday}
            isHaveProgress={item.isHaveProgress}
          />
        ))}
      </div>
    </div>
  );
};

export default memo(Activity);
