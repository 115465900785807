import qs from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router';

const useQuery = <T extends Record<string, any>>() => {
  const location = useLocation();
  return useMemo(() => qs.parse(location.search) as T, [location]);
};

export default useQuery;
