import CN from 'clsx';
import { MOBILE_APPS } from 'constants/common';
import routes from 'constants/routes';
import { useJuniorProgress } from 'hooks';
import { lazy, memo, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import type { RootState } from 'store';
import { useLocale } from 'store/hooks';

import { Button } from '@albert/shared/components';
import { Locale, PaymentProvider } from '@albert/shared/types';

import styles from './styles.module.scss';

const Badge = lazy(() => import('../Badge'));
const Progress = lazy(() => import('../Progress'));
const MonthlyReport = lazy(() => import('../MonthlyReport'));
const Activity = lazy(() => import('../Activity'));
const History = lazy(() => import('../History'));
const TeenParentPortalBanner = lazy(() => import('../TeenParentPortalBanner'));
const ArticlesCards = lazy(() => import('../ArticlesCards'));
const UpdatesCard = lazy(() => import('../UpdatesCard'));

interface Props {
  parentReportEnabled: boolean;
  provider?: PaymentProvider;
}

const NUMBER_OF_EXERCISES_TO_GET_DIPLOMA = [50, 100, 150];

const ChildsProgress = ({ provider, parentReportEnabled }: Props) => {
  const { t } = useTranslation('parent');
  const navigate = useNavigate();
  const getJuniorProgress = useJuniorProgress();
  const { childrenProgress, children } = useSelector((state: RootState) => state.user);
  const selectedChild = useSelector((state: RootState) => state.app.selectedChild);
  const numberOfChildren = children?.length ?? 0;
  const updateArticles = useSelector((state: RootState) => state.app.updateArticles);
  const lastUpdateArticle = updateArticles?.[0];
  const locale = useLocale();

  const isInAppCustomer = provider && [PaymentProvider.Apple, PaymentProvider.Google].includes(provider);

  const child = children?.[selectedChild];
  const childProgress = childrenProgress?.[selectedChild];
  const isJunior = child?.productType === MOBILE_APPS.JUNIOR;
  const isTeen = child?.productType === MOBILE_APPS.TEEN;
  const isTeenSE = isTeen && locale === Locale.SE;

  const juniorProgress = getJuniorProgress(childProgress?.contentStats);

  const numberOfSolvedExercises = childProgress?.contentStats
    ? Object.keys(childProgress?.contentStats).filter((item) => {
        const isExerciseSolved = childProgress?.contentStats && childProgress?.contentStats[item].state === 3;
        return isExerciseSolved && item.length > 7;
      }).length
    : 0;

  const showTrophiesCard = numberOfSolvedExercises >= NUMBER_OF_EXERCISES_TO_GET_DIPLOMA[0];

  return (
    <div className={styles.childsProgress}>
      <div className={styles.left}>
        {isTeenSE ? (
          <Suspense fallback={null}>
            <TeenParentPortalBanner />
          </Suspense>
        ) : (
          <Suspense fallback={null}>
            <>
              <div className={styles.basgeWrapper}>
                <Badge
                  isInApp={isInAppCustomer}
                  childName={child?.name}
                  parentReportEnabled={parentReportEnabled}
                  childProgress={childProgress}
                  isJunior={isJunior}
                  numberOfChildren={numberOfChildren}
                  isNoChildren={numberOfChildren === 0}
                />
              </div>

              <div className={styles.progress}>
                <Progress
                  childName={child?.name}
                  parentReportEnabled={parentReportEnabled}
                  isJunior={isJunior}
                  isNoChildren={numberOfChildren === 0}
                  progress={juniorProgress}
                />
              </div>
            </>
          </Suspense>
        )}
      </div>

      <div
        className={CN(
          styles.right,
          { [styles.hasReport]: !isTeenSE },
          { [styles.withTrophies]: showTrophiesCard && parentReportEnabled },
          { [styles.withReport]: !parentReportEnabled && !isTeenSE },
        )}
      >
        {!parentReportEnabled && isJunior && (
          <Suspense fallback={null}>
            <div className={styles.reports}>
              <MonthlyReport
                childName={child?.name}
                contentStats={childProgress?.contentStats}
                history={childProgress?.history}
              />
            </div>
          </Suspense>
        )}

        {!isTeenSE && (
          <Suspense fallback={null}>
            <div className={styles.history}>
              <History
                isActiveUser={Boolean(childProgress?.contentStats && childProgress?.history)}
                childName={child?.name}
                parentReportEnabled={parentReportEnabled}
                isJunior={isJunior}
                isNoChildren={numberOfChildren === 0}
                progress={juniorProgress}
              />
            </div>
          </Suspense>
        )}

        {showTrophiesCard && parentReportEnabled && (
          <div className={styles.trophies}>
            <h1>
              {t('parentReport.diploma', {
                name: child?.name,
              })}
            </h1>
            <Button
              className={styles.button}
              onClick={() =>
                navigate({
                  pathname: routes.TROPHIES,
                  search: 'diploma',
                })
              }
            >
              {t('articles.readMore')}
            </Button>
          </div>
        )}

        {lastUpdateArticle && (
          <Suspense fallback={null}>
            <UpdatesCard lastUpdateArticle={lastUpdateArticle} />
          </Suspense>
        )}

        {!isTeenSE && (
          <Suspense fallback={null}>
            <div className={styles.activity}>
              <Activity activities={childProgress?.activities} />
            </div>
          </Suspense>
        )}

        {parentReportEnabled && (
          <Suspense fallback={null}>
            <ArticlesCards />
          </Suspense>
        )}
      </div>
    </div>
  );
};

export default memo(ChildsProgress);
