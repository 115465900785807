import type { Locales } from '../types';
import type { CurrencyData } from '../types/payment';

export const CHARGE_AMOUNT_PER_COUNTRY: Record<Locales, CurrencyData> = {
  se: {
    amount: 3,
    currency: 'SEK',
  },
  no: {
    amount: 3.5,
    currency: 'NOK',
  },
  pl: {
    amount: 2,
    currency: 'PLN',
  },
  dk: {
    amount: 2.5,
    currency: 'DKK',
  },
  uk: {
    amount: 0.3,
    currency: 'GBP',
  },
  it: {
    amount: 0.5,
    currency: 'EUR',
  },
  fi: {
    amount: 0.5,
    currency: 'EUR',
  },
  es: {
    amount: 0.5,
    currency: 'EUR',
  },
  ro: {
    amount: 2,
    currency: 'RON',
  },
  cz: {
    amount: 15,
    currency: 'CZK',
  },
};
