import type { User } from '@firebase/auth';
import { useEffect } from 'react';
import { useAppDispatch, useAuth } from 'store/hooks';
import { getFirebaseRefs, signOut } from 'utils';

import { Bugsnag, Cookie } from '@albert/shared/services';

import { FirebaseService } from '../services';
import ListenerService from '../services/listener';
import { setAuthInitialized } from '../store/app/thunks';
import { AuthenticationStatus } from '../types';

const useFirebaseAuth = () => {
  const dispatch = useAppDispatch();
  const auth = useAuth();

  useEffect(() => {
    return FirebaseService.onAuthStateChanged(async (user) => {
      const cookie = Cookie.get('auth');
      const hasExpired = Boolean(cookie && cookie?.userId !== user?.uid);

      if (user?.uid && !hasExpired) {
        dispatch(setAuthInitialized(AuthenticationStatus.Loading));
        await initializeUser(user);
      } else {
        try {
          if (hasExpired) {
            await signOut();
          }
          dispatch(setAuthInitialized(AuthenticationStatus.Loaded));
        } catch (err: any) {
          Bugsnag.notify(err);
        }
      }
    });
  }, []);

  const initializeUser = async (user: User) => {
    const cookie = {
      userId: user.uid,
      name: user.email ?? '',
    };

    if (!Cookie.get('auth')?.userId) {
      Cookie.set('auth', cookie, {
        path: '/',
      });
    }

    await FirebaseService.set(getFirebaseRefs(user.uid).receiptValidationRef, 'v');
    ListenerService.initializeListenersForUserId(user.uid);
  };

  return auth;
};

export default useFirebaseAuth;
