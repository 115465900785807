import type { BillingInterval, DiscountData, Membership, PaymentProvider } from '@albert/shared/types';

import type { Receipt } from './receipt';

export interface SubscriptionUpdateProps {
  provider: PaymentProvider;
  active?: boolean;
  plan?: string;
  coupon?: string;
}

export interface ReactivationProps {
  provider: PaymentProvider;
  plan: Membership;
  priceId: string;
  campaign?: string;
}

export enum UserSubscriptionStatus {
  Paying = 'paying',
  Trialing = 'trialing',
}

export interface HistoricalInvoice {
  amountPaid: number;
  amountRefunded: number;
  currency: string;
  interval: BillingInterval;
  intervalCount: number;
  paidAt: Date;
}

export interface HistoricalInvoicesResponse {
  invoices: HistoricalInvoice[];
}

export interface DiscountFullInfo {
  id: string;
  object: string;
  checkout_session: null | string;
  coupon: {
    id: string;
    object: string;
    amount_off: number | null;
    created: number;
    currency: string | null;
    duration: string;
    duration_in_months: number | null;
    livemode: boolean;
    max_redemptions: number | null;
    metadata: Record<string, any>;
    name: string;
    percent_off: number | null;
    redeem_by: number | null;
    times_redeemed: number;
    valid: boolean;
  };
  customer: string;
  end: number | null;
  invoice: null | string;
  invoice_item: null | string;
  promotion_code: null;
  start: number;
  subscription: string;
  subscription_item: null | string;
}

export enum EmailLinkOfferParam {
  Redirect = 'redirect',
  CampaignName = 'campaignName',
  Code = 'code',
  Plan = 'plan',
  Billing = 'billing',
  Giftcard = 'giftcard',
}

export enum SubscriptionPeriod {
  OneMonth = '1 month',
  ThreeMonths = '3 months',
  SixMonths = '6 months',
  OneYear = '12 months',
}

export type SubscriptionData = (Receipt & { billingInterval?: BillingInterval; discountInfo?: DiscountData }) | null;
