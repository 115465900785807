import type { Locales } from '@albert/shared/types';

type ConfigKey = 'websiteUrl';

interface CountrySpecificVariables {
  websiteUrl: string;
}

class ConfigService {
  private variables: Record<ConfigKey, string> | null = null;

  private readonly countrySpecificVariables: Record<Locales, CountrySpecificVariables> = {
    se: {
      websiteUrl: process.env.VITE_WEBSITE_URL_SE as string,
    },
    no: {
      websiteUrl: process.env.VITE_WEBSITE_URL_NO as string,
    },
    dk: {
      websiteUrl: process.env.VITE_WEBSITE_URL_DK as string,
    },
    pl: {
      websiteUrl: process.env.VITE_WEBSITE_URL_PL as string,
    },
    uk: {
      websiteUrl: process.env.VITE_WEBSITE_URL_UK as string,
    },
    it: {
      websiteUrl: process.env.VITE_WEBSITE_URL_IT as string,
    },
    fi: {
      websiteUrl: process.env.VITE_WEBSITE_URL_FI as string,
    },
    es: {
      websiteUrl: process.env.VITE_WEBSITE_URL_ES as string,
    },
    ro: {
      websiteUrl: process.env.VITE_WEBSITE_URL_RO as string,
    },
    cz: {
      websiteUrl: process.env.VITE_WEBSITE_URL_CZ as string,
    },
  };

  initialize(locale: Locales) {
    const isLocalhost = process.env.NODE_ENV === 'development';

    this.variables = this.countrySpecificVariables[locale];

    if (isLocalhost) {
      this.variables.websiteUrl = 'http://localhost:3001';
    }
  }

  get(key: ConfigKey) {
    return this.variables?.[key];
  }
}

export default new ConfigService();
