import { Apps } from 'albert-common';
import dayjs from 'dayjs';
import type { JuniorContentStat, JuniorContentStats, JuniorHistoryType } from 'types';

import type { Locales } from '@albert/shared/types';

import { albertCommon } from '../services';
import { getJuniorLocale } from '../utils';

// NOTE(charlieroth): month is 0-11, 0 = Jan, 1 = Feb, etc.
// NOTE(charlieroth): year format is YYYY
export const getCompletedExercisesByMonth = ({
  exerciseStats,
  month,
  year,
}: {
  exerciseStats: JuniorContentStat[];
  month: number;
  year: number;
}) => {
  return exerciseStats.reduce((acc, exercise) => {
    const finishedExerciseInMonth = Object.values(exercise.attempts!).some((attempt) => {
      const attemptMonth = dayjs(attempt.createdAt).month();
      const attemptYear = dayjs(attempt.createdAt).year();
      const finishedExercise = attempt.state === 3;
      return attemptMonth === month && attemptYear === year && finishedExercise;
    });

    return finishedExerciseInMonth ? (acc += 1) : acc;
  }, 0);
};

export const getStatsOfKindFromContent = ({
  kind,
  contentStats,
}: {
  kind: string;
  contentStats: JuniorContentStats;
}) => {
  return Object.values(contentStats).filter(
    (contentStatValues) => contentStatValues.kind === kind && contentStatValues.attempts,
  );
};

export const getSuccessRateOfExercises = ({
  exerciseStats,
  n,
  month,
  year,
}: {
  exerciseStats: JuniorContentStat[];
  n: number;
  month: number;
  year: number;
}) => {
  const successRateSum = exerciseStats.reduce((acc, exercise) => {
    const successfulAttempts = Object.values(exercise.attempts!).filter((attempt) => {
      const attemptDate = dayjs(attempt.createdAt);
      const finishedExercise = attempt.state === 3;
      return attemptDate.month() === month && attemptDate.year() === year && finishedExercise;
    });

    if (successfulAttempts.length === 0) return acc;

    const successRateForExercise =
      successfulAttempts.reduce((acc, attempt) => {
        return (acc += attempt.successRate);
      }, 0) / successfulAttempts.length;

    return (acc += successRateForExercise);
  }, 0);

  return successRateSum / n;
};

export const getMostRecentHistoryEntry = ({
  locale,
  history,
  eventName,
  month,
  year,
}: {
  locale: Locales;
  history: JuniorHistoryType;
  eventName: string;
  month: number;
  year: number;
}) => {
  const translationLocale = getJuniorLocale(locale);

  const blocks = albertCommon().translation.get({
    app: Apps.junior,
    locale: translationLocale,
    translationKey: 'statictext_texts.blocks',
  }) as Array<{
    block_id: string;
    title: string;
    description: string;
    [key: string]: any;
  }>;

  const mostRecentHistoryEntry = Object.values(history)
    .filter((historyEntry) => historyEntry.eventName === eventName)
    .map((historyEntry) => ({ ...historyEntry, timestamp: Number(historyEntry.timestamp) }))
    .filter((historyEntry) => {
      const entryDate = dayjs(historyEntry.timestamp);
      return entryDate.month() === month && entryDate.year() === year;
    })
    .sort((historyEntryA, historyEntryB) => historyEntryB.timestamp - historyEntryA.timestamp)
    .shift()!;

  const mostRecentlyFinishedBlock = blocks?.find((block) => {
    if (!mostRecentHistoryEntry) return null;
    return String(block.block_id) === String(mostRecentHistoryEntry.block_id);
  });

  return {
    historyEntry: mostRecentHistoryEntry,
    block: mostRecentlyFinishedBlock,
  };
};

export const createMonthlyReport = ({
  locale,
  contentStats,
  history,
  month,
  year,
}: {
  locale: Locales;
  contentStats: JuniorContentStats;
  history: JuniorHistoryType;
  month: number;
  year: number;
}) => {
  const exerciseStats = getStatsOfKindFromContent({
    kind: 'exercise',
    contentStats,
  });

  const completedExercises = getCompletedExercisesByMonth({
    exerciseStats,
    month,
    year,
  });

  if (completedExercises === 0) {
    return {
      completedExercises: 0,
      successRate: 0,
      lastBlock: {
        name: null,
        isNew: false,
      },
    };
  }

  const successRate = getSuccessRateOfExercises({
    exerciseStats,
    n: completedExercises,
    month,
    year,
  });

  const { historyEntry, block } = getMostRecentHistoryEntry({
    locale,
    history,
    month,
    year,
    eventName: 'block-finished',
  });

  // NOTE(charlieroth): Some blocks have title and some have block_name :(
  const lastBlockName = block?.title === undefined ? historyEntry?.block_name : block.title;

  return {
    completedExercises,
    successRate: Math.round(successRate),
    lastBlock: {
      name: lastBlockName ?? null,
      isNew: historyEntry?.is_new ?? false,
    },
  };
};
