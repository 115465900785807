import type { PaymentRequest as StripePaymentRequest, Stripe } from '@stripe/stripe-js';
import type { PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js';
import { useTranslation } from 'next-i18next';
import { useEffect, useState, useRef } from 'react';

import { useTagManager } from '../../../hooks';
import { Bugsnag } from '../../../services';
import type { Locales } from '../../../types';
import type { CurrencyData, PaymentResponse } from '../../../types/payment';
import { PaymentProvider } from '../../../types/payment';
import { getAppStoreLocale } from '../../../utils';

interface Props {
  stripe: Stripe | null;
  currencyData: CurrencyData;
  useSetupIntent?: boolean;
  setLoading: (loading: boolean) => void;
  getClientSecret: () => Promise<string>;
  onFailure?: (err: any) => void;
  onSuccess?: (props: PaymentResponse) => Promise<void>;
}

export const useAppleAndGooglePayHelper = ({
  stripe,
  currencyData,
  useSetupIntent,
  setLoading,
  getClientSecret,
  onFailure,
  onSuccess,
}: Props) => {
  const paymentProcessFuncRef = useRef<((event: PaymentRequestPaymentMethodEvent) => Promise<void>) | null>(null);
  const [paymentRequest, setPaymentRequest] = useState<StripePaymentRequest | null>(null);
  const { i18n } = useTranslation('website');
  const locale = i18n.language as Locales;

  const { tagEvent } = useTagManager();

  paymentProcessFuncRef.current = async (event: PaymentRequestPaymentMethodEvent) => {
    setLoading(true);

    const clientSecret = await getClientSecret();

    if (!stripe || !clientSecret) {
      setLoading(false);
      return;
    }

    const getSetupIntent = async () => {
      const { setupIntent, error } = await stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: event.paymentMethod.id,
        },
        { handleActions: false },
      );
      return { stripeIntent: setupIntent, error };
    };

    const getPaymentIntent = async () => {
      const { paymentIntent, error } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: event.paymentMethod.id,
        },
        { handleActions: false },
      );

      return { stripeIntent: paymentIntent, error };
    };

    const { stripeIntent, error } = useSetupIntent ? await getSetupIntent() : await getPaymentIntent();

    if (error || !stripeIntent) {
      event.complete('fail');
      setLoading(false);
      Bugsnag.notify({
        name: error?.code ?? '',
        message: error?.message ?? '',
        ...error,
      });
      onFailure?.(error);
    } else {
      event.complete('success');

      /** Retry payment process and reopen apple/google pay dialog if paymentIntent status is 'requires_action' */
      if (stripeIntent.status === 'requires_action') {
        const { error: confirmActionError } = await stripe.confirmCardPayment(clientSecret);

        tagEvent({
          event: `${event.walletName.toUpperCase()} Payment`,
          action: `Payment via ${event.walletName.toUpperCase()}`,
        });

        if (confirmActionError) {
          Bugsnag.notify({
            name: confirmActionError.code ?? '',
            message: confirmActionError.message ?? '',
            ...confirmActionError,
          });
          onFailure?.(confirmActionError);
        } else {
          await onSuccess?.({
            provider: PaymentProvider.Stripe,
            token: stripeIntent.id,
          });
        }
      } else {
        await onSuccess?.({
          provider: PaymentProvider.Stripe,
          token: stripeIntent.id,
        });
      }
    }
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const paymentRequestInstance = stripe.paymentRequest({
      country: getAppStoreLocale(locale).toUpperCase(),
      currency: currencyData.currency.toLowerCase(),
      requestPayerName: true,
      requestPayerEmail: true,
      requestShipping: false,
      disableWallets: ['browserCard', 'link'],
      total: {
        label: 'eEducation Albert',
        amount: useSetupIntent ? 0 : currencyData.amount * 100,
      },
    });

    const handler = async (event: PaymentRequestPaymentMethodEvent) => {
      await paymentProcessFuncRef.current?.(event);
    };

    (async () => {
      paymentRequestInstance.on('paymentmethod', handler);

      const canMakePayment = await paymentRequestInstance.canMakePayment();

      if (!canMakePayment) {
        return;
      }

      setPaymentRequest(paymentRequestInstance);
    })();
  }, [stripe, useSetupIntent]);

  return paymentRequest;
};
