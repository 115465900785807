import type { Membership, MixpanelAppName } from '.';
import type { SubjectDTO } from '../../website/types';

export enum App {
  Junior = 'junior',
  Teen = 'teen',
  HolyOwly = 'holy-owly',
  Jaramba = 'jaramba',
  Code = 'code',
  Geo = 'geo',
}

export interface AppData {
  id: string;
  alias: App;
  title: string;
  ageRange: string;
  availableInPlans: Membership[];
  appStoreUrl: string;
  googlePlayUrl: string;
  mixpanelName: MixpanelAppName;
  icon: {
    url: string;
  };
  bannerImageMobile: {
    url: string;
  } | null;
  bannerImageDesktop: {
    url: string;
  } | null;
  subjects: SubjectDTO[];
}
