import CN from 'clsx';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router';

import styles from './styles.module.scss';

interface Props {
  route?: string;
  className?: string;
  handleClose?: () => void;
}

const BackButton = ({ route, handleClose, className }: Props) => {
  const { t } = useTranslation('parent');
  const navigate = useNavigate();

  const commonAttributes = {
    className: CN(styles.backButton, className),
  };

  if (route) {
    return (
      <Link to={route} {...commonAttributes}>
        <span>{t('back')}</span>
      </Link>
    );
  }

  if (handleClose) {
    return (
      <button {...commonAttributes} onClick={handleClose}>
        {t('back')}
      </button>
    );
  }

  return (
    <button {...commonAttributes} onClick={() => navigate(-1)}>
      {t('back')}
    </button>
  );
};

export default memo(BackButton);
