import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import type { StripeElementChangeEvent } from '@stripe/stripe-js';
import CN from 'clsx';
import dayjs from 'dayjs';
import { useTranslation } from 'next-i18next';
import type { ChangeEvent } from 'react';
import { useCallback, useMemo, useState } from 'react';

import { CHARGE_AMOUNT_PER_COUNTRY } from '../../../constants/subscriptions';
import { useTagManager } from '../../../hooks';
import { Bugsnag } from '../../../services';
import type { PaymentEvents, Membership, Locales } from '../../../types';
import type { PaymentResponse } from '../../../types/payment';
import { PaymentProvider } from '../../../types/payment';
import Button from '../../Button';
import Input from '../../Input';
import { useAppleAndGooglePayHelper } from './index.helpers';
import styles from './styles.module.scss';

interface Props {
  error?: string;
  eventProperties?: PaymentEvents;
  buttonText?: string;
  membership?: Membership;
  useSetupIntent?: boolean;
  getExistingCardId?: () => Promise<string>;
  getClientSecret: () => Promise<string>;
  onSuccess?: (props: PaymentResponse) => Promise<void>;
  onFailure?: (err: any) => void;
}

const StripePayment = ({
  error,
  eventProperties,
  buttonText,
  membership,
  useSetupIntent,
  getExistingCardId,
  getClientSecret,
  onSuccess,
  onFailure,
}: Props) => {
  const [cardholderName, setCardholderName] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [cardDetailsValidity, setCardDetailsValidity] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });

  const stripe = useStripe();
  const elements = useElements();
  const tagManager = useTagManager();

  const { t, i18n } = useTranslation('website');
  const locale = i18n.language as Locales;

  const cardNumberElement = elements?.getElement('cardNumber');
  const cardCvcElement = elements?.getElement('cardCvc');
  const cardExpiryElement = elements?.getElement('cardExpiry');

  const currencyData = useMemo(() => {
    return CHARGE_AMOUNT_PER_COUNTRY[locale];
  }, [locale, membership]);

  const isValidCardDetails = useMemo(() => {
    return Boolean(cardholderName?.length && Object.values(cardDetailsValidity).every((it) => it));
  }, [cardholderName, cardDetailsValidity]);

  const paymentRequestAppleGooglePay = useAppleAndGooglePayHelper({
    stripe,
    currencyData,
    useSetupIntent,
    setLoading,
    getClientSecret,
    onFailure,
    onSuccess,
  });

  const handleCardUpdate = useCallback(
    (event: StripeElementChangeEvent) => {
      setCardDetailsValidity((prevState) => ({
        ...prevState,
        [event.elementType]: event.complete && !event.error,
      }));
    },
    [cardNumberElement, cardCvcElement, cardExpiryElement],
  );

  const confirmCardSetup = async (clientSecret: string) => {
    const { setupIntent, error } = await stripe!.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardNumberElement!,
        billing_details: {
          name: cardholderName,
        },
      },
      return_url: window.location.href,
    });

    if (setupIntent) {
      return setupIntent;
    }

    setHasError(true);

    if (error) {
      Bugsnag.notify(JSON.stringify(error));
    }

    throw new Error(error?.message);
  };

  const confirmCardPayment = async (clientSecret: string) => {
    const existingCardId = await getExistingCardId?.();

    const { paymentIntent, error } = await stripe!.confirmCardPayment(clientSecret, {
      payment_method: existingCardId ?? {
        card: cardNumberElement!,
        billing_details: {
          name: cardholderName,
        },
      },
      return_url: window.location.href,
    });

    if (paymentIntent) {
      return paymentIntent;
    }

    setHasError(true);

    if (error) {
      Bugsnag.notify(JSON.stringify(error));
    }

    throw new Error(error?.message);
  };

  const initiatePayment = async () => {
    setLoading(true);

    if (eventProperties?.onPurchase) {
      tagManager.tagEvent(eventProperties.onPurchase);
    }

    setHasError(false);

    try {
      const clientSecret = await getClientSecret();
      // Check if the client secret is a setup intent or payment intent.
      // If it starts with "seti_", it's a setup intent, otherwise assume it's a payment intent.
      const paymentIntent = clientSecret.startsWith('seti_')
        ? await confirmCardSetup(clientSecret)
        : await confirmCardPayment(clientSecret);

      await onSuccess?.({
        provider: PaymentProvider.Stripe,
        token: paymentIntent.id,
      });
    } catch (err) {
      onFailure?.(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setCardholderName(e.target.value);
  };

  const handleFocusCardElement = useCallback(() => {
    if (eventProperties?.stripe) {
      tagManager.tagEvent(eventProperties.stripe);
    }
  }, [eventProperties?.stripe]);

  const renderChargeInformation = () => {
    const text = hasError
      ? t('shared.subscription.general.reserveAmountFailed', { ...currencyData })
      : t('shared.subscription.general.reserveAmount', { ...currencyData });

    if (getExistingCardId) {
      return (
        <div className={CN(styles.chargeText, { [styles.error]: hasError })}>
          <p>{text}</p>
        </div>
      );
    }

    return (
      <div className={CN(styles.chargeInformation, { [styles.chargeInformationError]: hasError })}>
        <p>{text}</p>
      </div>
    );
  };

  if (getExistingCardId) {
    return (
      <div className={styles.proceedWithExistingCardContainer}>
        <Button
          id="checkout_flow_third_step_accept_payment_btn"
          className={styles.button}
          disabled={!getExistingCardId && !isValidCardDetails}
          loading={loading}
          onClick={initiatePayment}
        >
          {buttonText ?? t('shared.subscription.general.activate')}
        </Button>

        {renderChargeInformation()}

        {!!error?.length && <span className={styles.errorText}>{error}</span>}
      </div>
    );
  }

  return (
    <div className={styles.root}>
      {paymentRequestAppleGooglePay && (
        <PaymentRequestButtonElement
          options={{ paymentRequest: paymentRequestAppleGooglePay, classes: { base: styles.appleGooglePayButton } }}
        />
      )}

      <label>
        <p className={styles.labelText}>{t('shared.subscription.card.number')}</p>
        <CardNumberElement
          options={{
            disabled: false,
            placeholder: '•••• •••• •••• 5923',
            showIcon: true,
            classes: { focus: styles.stripeElementFocus, invalid: styles.stripeElementError },
          }}
          className={styles.stripeElement}
          onFocus={handleFocusCardElement}
          onChange={handleCardUpdate}
        />
      </label>
      <div className={styles.cardContainer}>
        <Input
          label={t('shared.subscription.card.name')}
          className={styles.nameOnCard}
          placeholder="Albert Albertina"
          onChange={handleChangeName}
        />
        <label>
          <p className={styles.labelText}>{t('shared.subscription.card.expiry')}</p>
          <CardExpiryElement
            options={{
              placeholder: `04/${dayjs().add(2, 'year').format('YY')}`,
              classes: { focus: styles.stripeElementFocus },
            }}
            className={styles.stripeElement}
            onChange={handleCardUpdate}
          />
        </label>
        <label>
          <p className={styles.labelText}>{t('shared.subscription.card.cvc')}</p>
          <CardCvcElement
            options={{ classes: { focus: styles.stripeElementFocus }, placeholder: '458' }}
            className={styles.stripeElement}
            onChange={handleCardUpdate}
          />
        </label>
      </div>

      {/* There's no initial charge when using setup intent */}
      {useSetupIntent ? null : renderChargeInformation()}

      <Button
        id="checkout_flow_third_step_accept_payment_btn"
        className={styles.button}
        disabled={!isValidCardDetails}
        loading={loading}
        onClick={initiatePayment}
      >
        {buttonText ?? t('shared.subscription.general.activate')}
      </Button>

      {!!error?.length && <span className={styles.errorText}>{error}</span>}

      <p className={styles.description}>{t('shared.subscription.card.description')}</p>
      <div className={styles.stripeLogo} />
    </div>
  );
};

export default StripePayment;
