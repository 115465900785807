import TagManager from 'react-gtm-module';
import { v4, v5 } from 'uuid';

import { countries } from '../constants/countries';
import { Bugsnag, Cookie } from '../services';
import type { DataLayerEvent, GTMConfig } from '../types';
import { Locale } from '../types';
import { getLocaleFromUrl, isClient, isDevelopment } from '../utils';

let lastSentEvent: string;
const key = v4();
const IS_ALLOWED_IN_DEV = false;

const useTagManager = () => {
  const auth = Cookie.get('auth');

  const userData = {
    ...(auth?.name && auth?.userId && { email_address: auth?.name }),
    address: {
      country: countries[isClient ? getLocaleFromUrl() : Locale.SE].title,
    },
  };

  const init = ({ gtmId, auth, preview }: GTMConfig) => {
    if (isDevelopment() && !IS_ALLOWED_IN_DEV) return;

    TagManager.initialize({
      gtmId,
      auth,
      preview,
    });
  };

  const tagEvents = (events: DataLayerEvent[]) => {
    try {
      for (const event of events) {
        tagEvent(event);
      }
    } catch (e: any) {
      Bugsnag.notify(e);
    }
  };

  const tagEvent = (event: DataLayerEvent) => {
    if (isDevelopment() && !IS_ALLOWED_IN_DEV) return;

    const eventId = v5(JSON.stringify(event), key);

    if (lastSentEvent === eventId) {
      return;
    }

    lastSentEvent = eventId;

    const { action, category, label, ...rest } = event;

    try {
      TagManager.dataLayer({
        dataLayer: {
          gaEventAction: action,
          gaEventCategory: category,
          gaEventLabel: label,
          user_data: userData,
          ...rest,
        },
      });
    } catch (e: any) {
      Bugsnag.notify(e);
    }
  };

  const tagPage = async (page: string) => {
    if (isDevelopment() && !IS_ALLOWED_IN_DEV) return;

    const uuid = v4();
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageView',
        uuid,
        page,
        user_data: userData,
      },
    });
  };

  return {
    init,
    tagEvent,
    tagEvents,
    tagPage,
  };
};

export default useTagManager;
