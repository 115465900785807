import { isServer } from './common';

export const isAndroid = (): boolean => /Android/i.test(navigator.userAgent);
export const isIOS = (): boolean => /iPad|iPhone|iPod/.test(navigator.userAgent);

export const getDeviceType = () => {
  if (isServer) return;
  const isIphone = /iPhone/i.test(navigator.userAgent);
  const isIpad = /iPad/i.test(navigator.userAgent);
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isWindows = /Windows/i.test(navigator.userAgent);

  if (isIphone) {
    return 'mobile';
  }

  if (isIpad) {
    return 'tablet';
  }

  if (isAndroid) {
    return window.innerWidth > 768 ? 'tablet' : 'mobile';
  }

  if (isWindows) {
    return window.innerWidth > 768 ? 'tablet' : 'desktop';
  }

  return window.innerWidth > 1024 ? 'desktop' : window.innerWidth > 768 ? 'tablet' : 'mobile';
};

export const openSharingMenu = ({ url, title, text }: { url: string; title?: string; text?: string }) => {
  if (isServer) return;

  if (navigator.share) {
    navigator
      .share({
        url,
        title,
        text,
      })
      .catch(() => {});
  }
};
