import type { ProductType } from '../types';

export const MAX_CHILDREN_LENGTH = 4;

export const JUNIOR_AGE = 9;

export const MOBILE_APPS: Record<'JUNIOR' | 'TEEN', ProductType> = {
  JUNIOR: 'junior_app',
  TEEN: 'senior_app',
};

export const REFERRAL_REWARD_AMOUNT = '50 kr';

export const TEEN_PP_URL = 'https://albertteen.com/se/parent/activity-report';
