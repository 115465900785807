import { useTranslation } from 'next-i18next';
import { memo } from 'react';

import type { AppData } from '../../types';
import { App, Membership, Subject } from '../../types';
import { sortApps, sortSubjects } from '../../utils';
import AdvantageItem from './AdvantageItem';
import styles from './styles.module.scss';

interface Props {
  membership: Membership;
  subjects: Subject[];
  appsData: AppData[];
}

const AdvantagesList = ({ membership, subjects, appsData }: Props) => {
  const { t } = useTranslation('website');
  const sortedSubjects = sortSubjects(subjects);
  const subjectsList: string[] = sortedSubjects.map((subject) => {
    if (subject.includes(Subject.math) || subject.includes(Subject.jaramba) || subject.includes(Subject.holyOwly))
      return '';
    return t(`shared.apps.tags.${subject}` as any);
  });
  const hasMathSubject = subjects.some((subject) => subject.includes(Subject.math));

  if (hasMathSubject) {
    subjectsList.unshift(t('shared.apps.tags.math'));
  }

  const appsSubjects: { title: string; text?: string | string[] }[] = [
    {
      title: appsData?.find((app) => app.alias === App.Junior)?.title ?? '',
      text: appsData
        ?.find((app) => app.alias === App.Junior)
        ?.subjects.map(
          (subject, i, subjectsArr) => `${subjectsArr.length - 1 !== i ? `${subject.name}, ` : `${subject.name}`}`,
        ),
    },
    {
      title: appsData?.find((app) => app.alias === App.Teen)?.title ?? '',
      text:
        appsData?.find((app) => app.alias === App.Teen)?.subjects?.[0] &&
        t('shared.subscription.plan.advantages.teenDescription'),
    },
    {
      title: appsData?.find((app) => app.alias === App.Code)?.title ?? '',
      text: appsData?.find((app) => app.alias === App.Code)?.subjects[0].name,
    },
    {
      title: appsData?.find((app) => app.alias === App.Geo)?.title ?? '',
      text: appsData?.find((app) => app.alias === App.Geo)?.subjects[0]?.name,
    },
    {
      title:
        appsData?.find((app) => app.alias === App.Jaramba && app.availableInPlans.includes(membership))?.title ?? '',
      text: t('shared.subscription.plan.advantages.jarambaDescription'),
    },
    {
      title:
        appsData?.find((app) => app.alias === App.HolyOwly && app.availableInPlans.includes(membership))?.title ?? '',
      text: t('shared.subscription.plan.advantages.holyOwlyDescription'),
    },
  ];

  const advantages: Record<Membership, { title: string; text?: string | string[] }[]> = {
    [Membership.Bas]: [
      {
        title: t('shared.subscription.plan.tag.oneChild'),
      },
      {
        title: appsData?.find((app) => app.alias === App.Junior)?.title ?? '',
        text: appsData
          ?.find((app) => app.alias === App.Junior)
          ?.subjects.find((subject) => subject.alias.includes(Subject.math))?.name,
      },
      {
        title: appsData?.find((app) => app.alias === App.Teen)?.title ?? '',
        text:
          appsData?.find((app) => app.alias === App.Teen)?.subjects?.[0] &&
          t('shared.subscription.plan.advantages.teenDescription'),
      },
    ],
    [Membership.Plus]: [
      {
        title: t('shared.subscription.plan.advantages.users'),
      },
      {
        title: t('shared.subscription.plan.advantages.mathReport'),
      },
      ...appsSubjects,
    ],
    [Membership.Standard]: [
      {
        title: t('shared.subscription.plan.tag.oneChild'),
      },
      {
        title: t('shared.subscription.plan.advantages.mathReport'),
      },
      ...appsSubjects,
    ],
  };

  return (
    <div className={styles.advantages}>
      <h4 className={styles.advantagesTitle}>{t('shared.subscription.plan.whatsIncluded')}:</h4>
      <ul className={styles.advantagesList}>
        {advantages[membership].map((item, i) => {
          if (item.title) return <AdvantageItem key={`included-advantage-${i}`} title={item.title} text={item?.text} />;
          return null;
        })}
      </ul>

      <div className={styles.advantagesApps}>
        <h4 className={styles.advantagesTitle}>{t('shared.subscription.plan.appsIncluded')}:</h4>
        <div className={styles.advantagesAppIcons}>
          {sortApps(appsData, 'default').map(
            (app) =>
              app.availableInPlans.includes(membership) &&
              app.subjects.length > 0 &&
              app.icon.url && <img key={app.id} src={app.icon.url} className={styles.appIcon} draggable={false} />,
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(AdvantagesList);
