import type { ToastPosition, Theme, ToastOptions } from 'react-toastify';
import { toast } from 'react-toastify';

const DEFAULT_TOAST_OPTIONS: ToastOptions = {
  position: 'top-right' as ToastPosition,
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: 'colored' as Theme,
};

export default {
  success: (message: string, options?: ToastOptions) =>
    toast.success(message, {
      ...DEFAULT_TOAST_OPTIONS,
      className: 'toastSuccessMessage',
      ...options,
    }),
  warning: (message: string, options?: ToastOptions) =>
    toast.warning(message, {
      ...DEFAULT_TOAST_OPTIONS,
      className: 'toastWarningMessage',
      ...options,
    }),
  error: (message: string, options?: ToastOptions) =>
    toast.error(message, {
      ...DEFAULT_TOAST_OPTIONS,
      className: 'toastErrorMessage',
      ...options,
    }),
};
