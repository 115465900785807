import routes from 'constants/routes';
import { memo } from 'react';
import { Link } from 'react-router';
import { useSubscription } from 'store/hooks';

import styles from './styles.module.scss';

const ReferralBalance = () => {
  const subscription = useSubscription()!;

  const getAccountBalance = () => {
    // Backend sends balance in negative numbers
    const balance = subscription.upcomingInvoice?.startingBalance ?? 0;
    const toPositive = (balance: number) => balance * -1;

    return balance < 0 ? toPositive(balance) : 0;
  };

  const hasBalanceToPayForSubscription =
    getAccountBalance() >= (subscription.planInfo.discountedPrice || subscription.planInfo.price);

  return (
    <div className={styles.balanceInfo}>
      <p>
        <strong>
          Din kvarvarande rabatt:{' '}
          <span>
            {getAccountBalance()} {subscription.upcomingInvoice?.currency}
          </span>
        </strong>{' '}
        {hasBalanceToPayForSubscription && (
          <>
            (rabatt för nästkommande debitering är{' '}
            <span className={styles.balanceNumber}>
              <strong>
                {subscription.planInfo.discountedPrice || subscription.planInfo.price}{' '}
                {subscription.upcomingInvoice?.currency}
              </strong>
            </span>
            )
          </>
        )}
      </p>
      <Link to={routes.REFER_FRIEND} className={styles.referalPageLink}>
        Bjud in vän
      </Link>
    </div>
  );
};

export default memo(ReferralBalance);
