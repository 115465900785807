import type { ResponsiveImageType } from 'react-datocms/image';

import type { MixpanelSubjectName } from './events';

export enum Subject {
  abc = 'abc',
  geo = 'geo',
  code = 'code',
  math = 'math',
  english = 'english',
  biology = 'biology',
  physics = 'physics',
  jaramba = 'jaramba',
  holyOwly = 'holy-owly',
}

export interface SubjectStructureType {
  id: string;
  alias: MixpanelSubjectName;
  subjectName: string;
  subtitle: string;
  premiumFeature: boolean;
  content: {
    id: string;
    title: string;
    image: {
      responsiveImage: ResponsiveImageType;
    };
    list: {
      id: string;
      text: string;
    }[];
  }[];
  label?: string;
}
