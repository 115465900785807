import { fetchCampaignInfo, fetchFullDiscountInfo, getHasReceipts, sendEvent } from 'api';
import { apiService, Toast } from 'services';
import { GiftcardNotApplied, reactivationCampaignSuccessfulRedirectEvent } from 'services/analyticsEvents';
import { getState } from 'store';
import type { Receipt } from 'types';
import { EmailLinkOfferParam } from 'types';
import { convertPeriodToBillingInterval } from 'utils';

import type { AbTestsData, AbTestsMixpanelKey } from '@albert/shared/constants';
import { AB_TESTS } from '@albert/shared/constants';
import { Bugsnag, DiscountService } from '@albert/shared/services';
import { CampaignName, Locale } from '@albert/shared/types';
import type { CampaignPlanInfo, Locales } from '@albert/shared/types';
import { getGiftcardPlan } from '@albert/shared/utils';

import type { AppThunk } from '../rootReducer';
import { actions } from './reducers';

interface Params {
  isGuestUser?: boolean;
  userAbTests?: AbTestsData;
  locale?: Locales;
}

export const setCampaignInfo =
  ({ isGuestUser, userAbTests, locale }: Params = {}): AppThunk =>
  async (dispatch) => {
    let hasReceipts = false;
    const giftcard = sessionStorage.getItem(EmailLinkOfferParam.Giftcard);
    let giftcardAmountOff = 0;

    if (!isGuestUser) {
      try {
        hasReceipts = await getHasReceipts();
      } catch (error) {
        // Only users that hasn't finish the registration flow will end up without a receipt.
        // Therefore we fallback to "true" as the default value in case this endpoint throws.
        hasReceipts = true;
      }
    }

    if (locale === Locale.SE && giftcard) {
      const discountService = new DiscountService(apiService);
      const discountRes = await discountService.get({
        locale: locale as Locales,
        promoCode: giftcard,
      });

      if (discountRes?.valid && discountRes.amountOff) {
        giftcardAmountOff = discountRes.amountOff;
      } else {
        sendEvent(
          GiftcardNotApplied({
            giftcard: giftcard as string,
            user_status: 'reactivated',
          }),
        );
        sessionStorage.removeItem(EmailLinkOfferParam.Giftcard);
        Toast.error('Något gick fel');
      }
    }

    const getCampaignName = () => {
      if (giftcardAmountOff) {
        return CampaignName.NoTrialGiftcard;
      }

      if (sessionStorage.getItem(EmailLinkOfferParam.CampaignName)) {
        return sessionStorage.getItem(EmailLinkOfferParam.CampaignName) as string;
      }

      if (sessionStorage.getItem(EmailLinkOfferParam.Code)) {
        return CampaignName.NoOffer;
      }

      if (
        Object.values(AB_TESTS).find(
          (abTest) => userAbTests?.[abTest[getState().app.locale]?.mixpanelId as AbTestsMixpanelKey] === 1,
        ) &&
        Object.values(AB_TESTS).find((abTest) => abTest[getState().app.locale]?.campaignName)
      ) {
        return Object.values(AB_TESTS).find(
          (abTest) => userAbTests?.[abTest[getState().app.locale]?.mixpanelId as AbTestsMixpanelKey] === 1,
        )?.[getState().app.locale]?.campaignName as string;
      }

      if (hasReceipts) {
        return CampaignName.NoTrialOffer;
      }

      return CampaignName.Default;
    };

    const campaignName = getCampaignName();
    let campaignInfo;

    try {
      if (
        sessionStorage.getItem(EmailLinkOfferParam.Redirect) &&
        sessionStorage.getItem(EmailLinkOfferParam.CampaignName)
      ) {
        sendEvent(reactivationCampaignSuccessfulRedirectEvent({ campaign_name: campaignName }));
      }

      campaignInfo = await fetchCampaignInfo({
        campaignName,
        promoCode:
          ((giftcardAmountOff
            ? sessionStorage.getItem(EmailLinkOfferParam.Giftcard)
            : sessionStorage.getItem(EmailLinkOfferParam.Code)) as string) || undefined,
      });
    } catch {
      if (sessionStorage.getItem(EmailLinkOfferParam.Redirect)) {
        sessionStorage.removeItem(EmailLinkOfferParam.Redirect);
        sessionStorage.removeItem(EmailLinkOfferParam.CampaignName);
      }

      Bugsnag.notify(`Trying to fetchSubscriptionPlans. No such campaign was found, campaignName — ${campaignName}`);
      campaignInfo = await fetchCampaignInfo();
    }

    if (campaignInfo) {
      campaignInfo.plans = campaignInfo.plans.filter((plan: CampaignPlanInfo) => plan.price.interval);

      if (giftcardAmountOff) {
        const giftcardPlan = getGiftcardPlan(giftcardAmountOff, campaignInfo.plans);
        campaignInfo.plans = giftcardPlan ? [giftcardPlan] : campaignInfo.plans;
      }
    }

    dispatch(actions.setCampaignInfo(campaignInfo));
  };

export const setSubscription = (): AppThunk => async (dispatch) => {
  const purchase = await apiService.get<{ receipt: Receipt }>('/purchase');
  const receipt = purchase?.receipt;
  let billingInterval;
  let discountInfo;

  if (receipt?.planInfo.period) {
    billingInterval = convertPeriodToBillingInterval(receipt?.planInfo.period);
  }

  if (receipt?.planInfo.coupon) {
    const fullDiscountInfoRes = await fetchFullDiscountInfo();

    const discountService = new DiscountService(apiService);
    const discountInfoRes = await discountService.get({
      locale: getState().app.locale,
      promoCode: fullDiscountInfoRes?.coupon.id ?? '',
    });

    discountInfo = discountInfoRes;
  }

  dispatch(actions.setSubscription({ receipt: receipt ? { ...receipt, billingInterval, discountInfo } : null }));
};
