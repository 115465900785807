import type { BillingInterval, CampaignInfo, CampaignPlanInfo, DiscountData, Membership } from '../types';
import { ORDERED_PLANS } from '../types/plans';

export const getPlansByBillingInterval = (plans: CampaignPlanInfo[], billingInterval: BillingInterval) => {
  const sortedPlans = plans.slice().sort((a, b) => ORDERED_PLANS.indexOf(a.name) - ORDERED_PLANS.indexOf(b.name));
  return sortedPlans?.filter((plan) => plan.price.interval === billingInterval);
};

export const getPlanInfo = (
  plans: CampaignPlanInfo[],
  selectedMembership: Membership,
  selectedBillingInterval: BillingInterval,
): CampaignPlanInfo | null => {
  if (!plans.length && selectedMembership && selectedBillingInterval) {
    return null;
  }

  const planInfo =
    getPlansByBillingInterval(plans, selectedBillingInterval).find((plan) => plan.name === selectedMembership) ?? null;

  return planInfo;
};

export const getFormattedPrice = (price: number): number => price / 100;

export const getRoundedPriceToHundredths = (price: number): string => {
  if (price === 0) return '0';
  return price.toFixed(2);
};

export const getDiscountedPlanPrice = (price: number, discount: DiscountData): number => {
  let discountPrice = 0;

  if (discount.amountOff > 0) {
    discountPrice = price - getFormattedPrice(discount.amountOff);
  } else if (discount.percentageOff > 0) {
    discountPrice = price * ((100 - discount.percentageOff) / 100);
  } else {
    return price;
  }

  discountPrice = Number(getRoundedPriceToHundredths(discountPrice));

  return discountPrice;
};

export const getSavingsPercent = (plans: CampaignPlanInfo[]) => {
  return Math.round(Math.max(...plans.map((item) => item?.yearlySavings?.percentage ?? 0)) / 5) * 5;
};

export const getYearlyPriceAsMonthly = (yearlyPrice: number) => {
  return String(Math.ceil(yearlyPrice / 12));
};

export const getMonthlyPricePerYear = (monthlyPrice: number) => {
  return String(Math.ceil(monthlyPrice * 12));
};

export const getAppliedPromoCode = ({
  hasDiscount,
  giftcardCode,
  queryParamCode,
  planPromoCode,
}: {
  hasDiscount: boolean;
  giftcardCode?: string;
  queryParamCode?: string;
  planPromoCode?: string;
}) => {
  if (!hasDiscount) {
    return;
  }

  if (giftcardCode) {
    return giftcardCode;
  }

  if (queryParamCode) {
    return queryParamCode;
  }

  if (planPromoCode) {
    return planPromoCode;
  }

  return;
};

export const hasDiscountsInCampaign = (campaignInfo: CampaignInfo) => {
  if (
    campaignInfo.plans.some((plan) => plan.discount?.amountOff || plan.discount?.percentageOff) ||
    campaignInfo.promoCode
  ) {
    return true;
  }

  return false;
};
