import BackButton from 'components/common/BackButton';
import routes from 'constants/routes';
import { shuffle } from 'lodash';
import type { Dispatch, SetStateAction } from 'react';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { CancelReasonType, SubreasonsListType } from 'types';
import { CancelReasonCode } from 'types';

import { Button, RadioButton, Textarea } from '@albert/shared/components';

import styles from './styles.module.scss';

interface Props {
  cancelReasons: CancelReasonType[] | null;
  cancelReason: number;
  cancelSubreason: string;
  otherReason: string;
  setCancelSubreason: Dispatch<SetStateAction<string>>;
  setCancelSubreasonPosition: Dispatch<SetStateAction<number>>;
  setCancelReason: Dispatch<SetStateAction<number>>;
  setOtherReason: Dispatch<SetStateAction<string>>;
  onNextButtonClick: () => void;
}

const CancelReasons = ({
  cancelReasons,
  cancelReason,
  cancelSubreason,
  otherReason,
  setCancelReason,
  setCancelSubreason,
  setCancelSubreasonPosition,
  setOtherReason,
  onNextButtonClick,
}: Props) => {
  const { t } = useTranslation('parent');

  if (!cancelReasons) return null;

  const shuffledSubreasons = useMemo(
    () =>
      shuffle(
        cancelReasons.find((reasons) => reasons.reasonCode === cancelReason)?.subreasonsList as SubreasonsListType[],
      ),
    [cancelReason, cancelReasons],
  );

  const handleResetCancelReasons = useCallback(
    (e?: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      e?.preventDefault();
      setCancelReason(0);
      setCancelSubreason('');
    },
    [cancelReason],
  );

  const setSubreason = (reason: string, position: number) => {
    setCancelSubreason(reason);
    setCancelSubreasonPosition(position + 1);
  };

  const renderReasons = useCallback(() => {
    return cancelReasons.map((reason) => (
      <RadioButton
        key={reason.id}
        isDark
        name="reason-default"
        value={reason.reasonCode}
        label={reason.reasonText}
        onChange={() => setCancelReason(reason.reasonCode)}
      />
    ));
  }, [cancelReasons]);

  const renderSubreasons = useCallback(
    (reason: number) => {
      const reasonText = cancelReasons.find((reasonItem) => reasonItem.reasonCode === reason)?.reasonText;

      if (reason === CancelReasonCode.OtherReason) {
        return (
          <>
            <RadioButton
              isDark
              defaultChecked
              key={`reason_${CancelReasonCode.OtherReason}`}
              name="reason"
              value={reason}
              label={reasonText}
              onClick={handleResetCancelReasons}
            />
            <div className={styles.cancelReason}>
              <h3>{t('cancelationReasons.paragraphSubreason')}</h3>
            </div>
            <Textarea
              value={otherReason}
              placeholder={t('cancelationReasons.writeAComment')}
              onChange={(e) => setOtherReason(e.target.value)}
            />
          </>
        );
      } else {
        return (
          <>
            <RadioButton
              isDark
              defaultChecked
              key={`reason_${reason}`}
              name="reason"
              value={reason}
              label={reasonText}
              onClick={handleResetCancelReasons}
            />
            <div className={styles.cancelReason}>
              <h3>{t('cancelationReasons.paragraphSubreason')}</h3>
            </div>

            {shuffledSubreasons.map((subreason, index) => (
              <RadioButton
                key={subreason.subreasonCode}
                isDark
                name="reason-default"
                defaultChecked={!!cancelSubreason && subreason.subreasonCode === cancelSubreason}
                value={subreason.subreasonCode}
                label={subreason.subreasonText}
                onChange={() => setSubreason(subreason.subreasonCode, index)}
              />
            ))}

            <Textarea
              value={otherReason}
              placeholder={t('cancelationReasons.writeAComment')}
              onChange={(e) => setOtherReason(e.target.value)}
            />
          </>
        );
      }
    },
    [cancelReason, otherReason],
  );

  return (
    <div className={styles.cancelReasons}>
      <BackButton
        route={!cancelReason ? routes.SUBSCRIPTION.ROOT : undefined}
        className={styles.backButton}
        handleClose={handleResetCancelReasons}
      />

      <h1 className={styles.highlightedTitle}>{t('cancelationReasons.title')}</h1>
      <p className={styles.subtitle}>{t('cancelationReasons.paragraph')}</p>

      <div className={styles.reasonContainer}>{cancelReason ? renderSubreasons(cancelReason) : renderReasons()}</div>

      <Button
        theme="withArrow"
        disabled={!cancelReason || (!cancelSubreason && !otherReason)}
        onClick={onNextButtonClick}
      >
        {t('subscriptionPage.general.continue')}
      </Button>
    </div>
  );
};

export default memo(CancelReasons);
