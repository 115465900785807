import { apiService } from 'services';
import type { ReactivationProps } from 'types';

import { PaymentProvider } from '@albert/shared/types';

// NOTE(kim): This returns "false" if the user has never had a subscription.
// This can happen if the user didn't finish the checkout process on the website.
export const getHasReceipts = async (): Promise<boolean> => {
  try {
    const res = await apiService.get<{ hasReceipts: boolean }>('/purchase/has-receipts');
    return res.hasReceipts;
  } catch (e) {
    // NOTE(victoriia): it throws 401 during the reactivation flow.
    // In this case we shold return "true" because returning customers had a subscription in past.
    return true;
  }
};

export const getPaymentClientSecret = async (params?: Omit<ReactivationProps, 'provider'>): Promise<string> => {
  const data = await apiService.post<{ token: string }>('/purchase/token', {
    ...params,
    provider: PaymentProvider.Stripe,
  });

  return data.token;
};
