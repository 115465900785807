import { useEffect } from 'react';

import { isClient } from '../utils';

type Enumerate<N extends number, Acc extends number[] = []> = Acc['length'] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc['length']]>;
type DaysRange<F extends number, T extends number> = Exclude<Enumerate<T>, Enumerate<F>>;
type AllowedNumberOfDays = DaysRange<1, 24>;

/** This hook adds timeout to auto-refresh site after (by default: 2 days)
 * to keep it up to date with the latest releases */

const useTimedPageRefresh = (numberOfDays?: AllowedNumberOfDays) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isClient) {
        window.location.reload();
      }
    }, 1000 * 60 * 60 * 24 * (numberOfDays ?? 2));

    return () => {
      clearTimeout(timeout);
    };
  }, []);
};

export default useTimedPageRefresh;
