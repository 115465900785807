import CN from 'clsx';
import routes from 'constants/routes';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import { Icon } from '@albert/shared/components';
import { EXTERNAL_LINK_ATTRIBUTES } from '@albert/shared/constants';
import { socialMediaLinks } from '@albert/shared/constants';

import type { HeaderCommonProps } from '../';
import styles from '../styles.module.scss';

const MobileMenu = ({ isOpenMenu, links, onSignOutClick }: HeaderCommonProps) => {
  const { t } = useTranslation(['parent', 'website']);

  return (
    <nav className={CN(styles.mobileMenu, { [styles.mobileMenuOpen]: isOpenMenu })}>
      <ul>
        {links.map((link, i) => (
          <li key={i} className={styles.menuItem}>
            <a
              href={link.href}
              className={styles.menuText}
              {...EXTERNAL_LINK_ATTRIBUTES}
              {...(!isOpenMenu && { tabIndex: -1 })}
            >
              {link.label}
            </a>
          </li>
        ))}
        <li className={styles.menuItem}>
          <Link
            to={routes.LOGIN}
            className={CN(styles.menuText, styles.withSvg)}
            {...(!isOpenMenu && { tabIndex: -1 })}
            onClick={onSignOutClick}
          >
            <Icon name="user" size={15} />
            {t('login.logOut')}
          </Link>
        </li>
      </ul>

      <ul className={styles.mobileMedia}>
        <li>
          <a
            title="go to facebook"
            href={socialMediaLinks.FACEBOOK}
            {...(!isOpenMenu && { tabIndex: -1 })}
            {...EXTERNAL_LINK_ATTRIBUTES}
          >
            Facebook
          </a>
        </li>
        <li>
          <a
            title="go to instagram"
            href={socialMediaLinks.INSTAGRAM}
            {...(!isOpenMenu && { tabIndex: -1 })}
            {...EXTERNAL_LINK_ATTRIBUTES}
          >
            Instagram
          </a>
        </li>
        <li>
          <a
            title="go to linkedin"
            href={socialMediaLinks.LINKEDIN}
            {...(!isOpenMenu && { tabIndex: -1 })}
            {...EXTERNAL_LINK_ATTRIBUTES}
          >
            Linkedin
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default MobileMenu;
