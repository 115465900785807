import { sendEvent, fetchHistoricalInvoices } from 'api';
import CN from 'clsx';
import dayjs from 'dayjs';
import { useState, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { showHistoricalPaymentsClickEvent } from 'services/analyticsEvents';
import { useLocale } from 'store/hooks';
import type { HistoricalInvoice } from 'types';

import { DATE_FORMAT } from '@albert/shared/constants';

import styles from './styles.module.scss';

const HistoricalPayments = () => {
  const { t } = useTranslation('parent');
  const locale = useLocale();

  const [showForm, setShowForm] = useState<boolean>(false);
  const [historicalInvoices, setHistoricalInvoices] = useState<HistoricalInvoice[] | null>(null);

  useEffect(() => {
    (async () => {
      const invoices = await fetchHistoricalInvoices();
      setHistoricalInvoices(
        invoices ? invoices.filter((invoice) => invoice.amountPaid - invoice.amountRefunded > 0) : null,
      );
    })();
  }, []);

  const openHistoricalInvoices = () => {
    setShowForm(true);

    sendEvent(showHistoricalPaymentsClickEvent());
  };

  const showHistoricalInvoices =
    historicalInvoices && historicalInvoices.some((invoice) => invoice.interval === 'year');

  if (!showHistoricalInvoices) return null;

  return (
    <>
      <div className={styles.card}>
        <h2 className={CN(styles.cardTitle, styles.border)}>{t('historicalPayments.title')}</h2>
        <div className={styles.smallerWidth}>
          {showForm ? (
            <div className={styles.table}>
              <div className={styles.tableHead}>
                <h3>{t('historicalPayments.date')}</h3>
                <h3>{t('historicalPayments.sum')}</h3>
                <h3>{t('historicalPayments.currency')}</h3>
              </div>
              <div className={styles.tableBody}>
                {historicalInvoices.map((invoice, i) => (
                  <div key={i} className={styles.tableRow}>
                    <p>{dayjs(invoice.paidAt).format(DATE_FORMAT[locale])}</p>
                    <p>{(invoice.amountPaid - invoice.amountRefunded) / 100}</p>
                    <p>{invoice.currency.toUpperCase()}</p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className={styles.openButton} onClick={() => openHistoricalInvoices()}>
              {t('historicalPayments.button')}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(HistoricalPayments);
