import CN from 'clsx';
import {
  StripeProvider,
  InAppPurchaseProvider,
  IntegrationProvider,
  NoSubscriptionCard,
  ManagePassword,
} from 'components';
import { useQuery } from 'hooks';
import { useState, useCallback, lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useSubscription } from 'store/hooks';

import { PaymentProvider } from '@albert/shared/types';

import styles from './styles.module.scss';

const CurrentPaymentMethod = lazy(
  () => import('components/account-subscription/subscription/information/CurrentPaymentMethod'),
);
const HistoricalPayments = lazy(
  () => import('components/account-subscription/subscription/information/HistoricalPayments'),
);
const PromoCodeEnterTab = lazy(
  () => import('components/account-subscription/subscription/information/PromoCodeEnterTab'),
);

interface QueryParams {
  action?: 'email';
}

const Subscription = () => {
  const { t } = useTranslation(['parent', 'website']);
  const { action } = useQuery<QueryParams>();
  const [tabIndex, setTabIndex] = useState(Number(action === 'email'));
  const subscription = useSubscription()!;
  const provider = subscription?.provider;
  const hasDiscount = subscription?.planInfo?.discountedPrice < subscription?.planInfo.price;
  const hasSubscriptionWithoutDiscount =
    provider === PaymentProvider.Stripe && subscription?.nextPaymentDate && !subscription.isCanceled && !hasDiscount;

  const renderSubscriptionState = () => {
    switch (provider) {
      case PaymentProvider.Stripe:
        return <StripeProvider />;
      case PaymentProvider.Apple:
      case PaymentProvider.Google:
        return <InAppPurchaseProvider />;
      case PaymentProvider.Integration:
        return <IntegrationProvider />;
      case PaymentProvider.Albert:
        return null;
      default:
        return <NoSubscriptionCard />;
    }
  };

  const handleSwitchTab = useCallback(
    (tabIndex: number) => {
      setTabIndex(tabIndex);
    },
    [tabIndex],
  );

  return (
    <div className={styles.container}>
      <h1 className={styles.pageTitle}>{t('subscriptionPage.title')}</h1>
      <div className={styles.tabs}>
        <button className={CN(styles.tab, { [styles.active]: tabIndex === 0 })} onClick={() => handleSwitchTab(0)}>
          {t('subscriptionPage.tabs.subscription')}
        </button>
        <button className={CN(styles.tab, { [styles.active]: tabIndex === 1 })} onClick={() => handleSwitchTab(1)}>
          {t('subscriptionPage.tabs.account')}
        </button>
        {hasSubscriptionWithoutDiscount && (
          <button className={CN(styles.tab, { [styles.active]: tabIndex === 2 })} onClick={() => handleSwitchTab(2)}>
            {t('website:shared.subscription.promocode.general.enterCode')}
          </button>
        )}
      </div>

      <div className={styles.wrapper}>
        {tabIndex === 0 && (
          <>
            {renderSubscriptionState()}

            {(!subscription?.provider || subscription?.provider === PaymentProvider.Stripe) && (
              <Suspense fallback={null}>
                <CurrentPaymentMethod />
              </Suspense>
            )}

            <Suspense fallback={null}>
              <HistoricalPayments />
            </Suspense>
          </>
        )}
        {tabIndex === 1 && <ManagePassword />}
        {tabIndex === 2 && hasSubscriptionWithoutDiscount && (
          <Suspense fallback={null}>
            <PromoCodeEnterTab onPromoCodeApplied={() => setTabIndex(0)} />
          </Suspense>
        )}
      </div>
    </div>
  );
};

export default Subscription;
