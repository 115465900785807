import CN from 'clsx';
import type { Control, UseFormRegister } from 'react-hook-form';
import { useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { ChildFormType } from 'types';

import { Input, Icon, Slider } from '@albert/shared/components';

import styles from './styles.module.scss';

interface Props {
  control: Control<ChildFormType>;
  children: JSX.Element;
  register: UseFormRegister<ChildFormType>;
  isOnboarding?: boolean;
  deleteButton?: JSX.Element;
  profilePicture?: string;
}

const ChildForm = ({ control, children, register, isOnboarding = false, deleteButton, profilePicture }: Props) => {
  const { t } = useTranslation(['parent', 'website']);
  const { errors } = useFormState({ control });
  const age = useWatch({ control, name: 'age' });

  return (
    <div className={CN(styles.card, { [styles.withBorder]: !isOnboarding })}>
      {!isOnboarding && (
        <div className={styles.topContainer}>
          <div className={styles.avatar}>
            {profilePicture ? <img src={profilePicture} alt="User avatar" /> : <Icon name="no_avatar" />}
          </div>
          {deleteButton}
        </div>
      )}
      <div className={styles.childNameInput}>
        <Input
          error={errors.name?.message}
          label={t('editChild.name')}
          {...register('name', {
            required: {
              value: true,
              message: t('errorMessages.nameRequired'),
            },
          })}
        />
      </div>
      <div className={styles.childAgeSlider}>
        <Slider
          subLabel={age}
          error={errors.age?.message}
          label={t('childData.childAgeLabel')}
          min={0}
          max={16}
          {...register('age', {
            required: true,
            min: {
              value: 3,
              message: t('errorMessages.minAge'),
            },
          })}
        />
      </div>

      {children}
    </div>
  );
};

export default ChildForm;
