import { DatoCMS } from '@albert/shared/services';
import type { Locales, SubjectStructureType } from '@albert/shared/types';

export const getSubjects = async (locale: Locales): Promise<SubjectStructureType[]> => {
  const subjects = await DatoCMS.requestContent({
    query: DatoCMS.withImageFragment(`
      query GetSubjects ($locale: SiteLocale) {
        allPpSubjectSliders(locale: $locale) {
          id
          alias
          subjectName
          subtitle
          premiumFeature
          label
          content {
            id
            title
            list {
              id
              text
            }
            image {
              responsiveImage(imgixParams: { fit: crop, w: 1720, h: 1280 }) {
                ...responsiveImageFragment
              }
            }
          }
        }
      }`),
    variables: { locale: DatoCMS.toISOLocale[locale] },
    isCachedEndpoint: true,
  });

  return subjects.allPpSubjectSliders;
};
