interface Params {
  currency: string;
  amountOff?: number;
  percentageOff?: number;
}

const getSubscriptionDiscountText = ({ currency, amountOff, percentageOff }: Params): string | null => {
  if (amountOff ?? 0 > 0) {
    return `${amountOff} ${currency.toUpperCase()}`;
  }

  if (percentageOff ?? 0 > 0) {
    return `${percentageOff}%`;
  }

  return null;
};

export default getSubscriptionDiscountText;
