import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocale, useSubscription } from 'store/hooks';

import { Label } from '@albert/shared/components';
import { BILLING_TEXT, DATE_FORMAT } from '@albert/shared/constants';
import { getRoundedPriceToHundredths } from '@albert/shared/utils';

import styles from './styles.module.scss';

const NextPaymentInfo = () => {
  const { t } = useTranslation(['parent', 'website']);
  const subscription = useSubscription()!;
  const locale = useLocale();

  const nextPaymentAmount = getRoundedPriceToHundredths(subscription.upcomingInvoice?.amountDue ?? 0);
  const nextPaymentAttempt = dayjs(subscription.upcomingInvoice?.nextPaymentAttempt).format(DATE_FORMAT[locale]);

  if (subscription.endingAt) {
    return (
      <div className={styles.fullWidth}>
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{
            __html: t('subscriptionPage.subscription.general.endingAt', {
              endingAt: `<b>${dayjs(subscription?.endingAt).format(DATE_FORMAT[locale])}</b>`,
            }),
          }}
        />
        {/* NOTE: users, who churned during the trial can not use their memebership till the end of the billing period */}
        {!subscription.inTrial && <div className={styles.hint}>{t('subscriptionPage.subscription.inactive.hint')}</div>}
      </div>
    );
  }

  if (!isEmpty(subscription.planInfo.discounts)) {
    const getDiscountsLabel = () => {
      const roundedDiscountPercentage = Math.round(subscription.upcomingInvoice?.totalDiscountPercent ?? 0);
      const discountInfo = `${roundedDiscountPercentage}% ${t('subscriptionPage.general.discount')}`;
      return <Label theme="carnation" className={styles.label} title={discountInfo} />;
    };

    if (subscription.upcomingInvoice?.totalDiscountPercent !== 0) {
      return (
        <div className={styles.discounts}>
          <div className={styles.labels}>{getDiscountsLabel()}</div>
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{
              __html: `${t('subscriptionPage.subscription.general.nextPaymentInfo', {
                date: `<b>${nextPaymentAttempt}</b>`,
                currency: `<b>${subscription.planInfo.currency.toUpperCase()}</b>`,
                price: `<b>${nextPaymentAmount}</b>`,
              })} ${t('subscriptionPage.subscription.general.originalPrice', {
                currency: subscription.planInfo.currency.toUpperCase(),
                price: getRoundedPriceToHundredths(subscription.planInfo.price),
                billing: t(`website:shared.subscription.billing.${BILLING_TEXT[subscription.billingInterval!].short}`),
              })}`,
            }}
          />
        </div>
      );
    } else {
      return (
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{
            __html: `${t('subscriptionPage.subscription.general.nextPaymentInfo', {
              date: `<b>${nextPaymentAttempt}</b>`,
              currency: `<b>${subscription.planInfo.currency.toUpperCase()}</b>`,
              price: `<b>${nextPaymentAmount}</b>`,
            })}`,
          }}
        />
      );
    }
  }

  return (
    <div
      className={styles.text}
      dangerouslySetInnerHTML={{
        __html: t('subscriptionPage.subscription.general.nextPaymentInfo', {
          date: `<b>${nextPaymentAttempt}</b>`,
          currency: `<b>${subscription.planInfo.currency.toUpperCase()}</b>`,
          price: `<b>${nextPaymentAmount}</b>`,
        }),
      }}
    />
  );
};

export default memo(NextPaymentInfo);
