import Bugsnag from '@bugsnag/js';
import { ToggleUsers, ChildsProgress, ErrorFallback } from 'components';
import { useHasFeature } from 'hooks';
import { withErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useSubscription } from 'store/hooks';
import { Feature } from 'types/product';

import styles from './styles.module.scss';

const ParentReports = () => {
  const { t } = useTranslation('parent');
  const subscription = useSubscription();
  const parentReportEnabled = useHasFeature(Feature.ParentReport);

  return (
    <div className={styles.content}>
      <div className={styles.homeHeader}>
        <h1 className={styles.title}>{t('parentReport.title')}</h1>
        <ToggleUsers />
      </div>
      <div className={styles.progressWrapper}>
        <ChildsProgress provider={subscription?.provider} parentReportEnabled={parentReportEnabled} />
      </div>
    </div>
  );
};

const ParentReportsWithErrorFallback = withErrorBoundary(ParentReports, {
  FallbackComponent: ErrorFallback,
  onError(error, info) {
    Bugsnag.notify(`ErrorBoundary caught an error on ParentReports page. Error — ${error} Info — ${info}`);
  },
});

export default ParentReportsWithErrorFallback;
