import { useState, useEffect } from 'react';

const useDidMount = () => {
  const [isMounted, setIsMounted] = useState<boolean>(false);

  useEffect(() => {
    if (typeof setIsMounted === 'function') {
      setIsMounted(true);
    }
  }, []);

  return isMounted;
};

export default useDidMount;
