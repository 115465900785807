import CN from 'clsx';
import { useFirebaseUser } from 'hooks';
import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ConfigService } from 'services';
import type { RootState } from 'store';
import { setNotifications } from 'store/app/thunks';
import { useAppDispatch } from 'store/hooks';

import { Icon } from '@albert/shared/components';
import { EXTERNAL_LINK_ATTRIBUTES } from '@albert/shared/constants';
import { useClickOutside } from '@albert/shared/hooks';

import type { HeaderCommonProps } from './';
import AccountDropdown from './AccountDropdown';
import styles from './styles.module.scss';

const NotificationsDropdown = lazy(() => import('./NotificationsDropdown'));

const NavigationDesktop = ({ links, onSignOutClick }: HeaderCommonProps) => {
  const dispatch = useAppDispatch();
  const user = useFirebaseUser();
  const notifications = useSelector(({ app }: RootState) => app.notifications);
  const dropdownMenuRef = useRef<HTMLDivElement>(null);
  const websiteUrl = ConfigService.get('websiteUrl');
  const [isOpenAccountDropdown, setOpenAccountDropdown] = useState<boolean>(false);
  const [isOpenNotificationsDropdown, setOpenNotificationsDropdown] = useState<boolean>(false);

  const hasNotifications = !!((notifications?.length ?? 0) > 0);
  const hasNewNotifications = hasNotifications && notifications?.some((notification) => notification.seenAt === null);
  const isSeenNotifications = sessionStorage.getItem('notificationsSeen') === 'true';

  const refetchNotifications = () => {
    dispatch(setNotifications());
    sessionStorage.removeItem('notificationsSeen');
  };

  useEffect(() => {
    if (!isOpenNotificationsDropdown && isSeenNotifications) {
      refetchNotifications();
    }
  }, [isOpenNotificationsDropdown]);

  const handleToggleAccountDropdown = () => {
    setOpenAccountDropdown((prev) => !prev);
    setOpenNotificationsDropdown(false);
  };

  const handleCloseAccountDropdown = () => {
    setOpenAccountDropdown(false);
  };

  const handleToggleNotificationsDropdown = () => {
    setOpenNotificationsDropdown((prev) => !prev);
    setOpenAccountDropdown(false);
  };

  const handleCloseNotificationsDropdown = () => {
    setOpenNotificationsDropdown(false);
  };

  useClickOutside(dropdownMenuRef, () => (handleCloseAccountDropdown(), handleCloseNotificationsDropdown()));

  return (
    <>
      <nav className={styles.left}>
        <div className={styles.logoContainer}>
          <a href={websiteUrl} {...EXTERNAL_LINK_ATTRIBUTES}>
            <Icon name="albert_logo" fill="var(--slate-grey)" width={80} height={34} />
          </a>
        </div>

        <ul className={styles.desktopLinks}>
          {links.map((link, i) => (
            <li key={i} className={styles.desktopLink}>
              <a href={link.href} className={styles.text} {...EXTERNAL_LINK_ATTRIBUTES}>
                {link.label}
              </a>
            </li>
          ))}
        </ul>
      </nav>

      <div ref={dropdownMenuRef} className={styles.rightSide}>
        {hasNotifications && (
          <div className={styles.dropdownButtonContainer}>
            <button
              className={CN(styles.notificationsButton, {
                [styles.open]: isOpenNotificationsDropdown,
                [styles.redBadge]: hasNewNotifications && !isSeenNotifications,
              })}
              onClick={handleToggleNotificationsDropdown}
            >
              <Icon name="bell" size={20} />
            </button>

            <Suspense fallback={null}>
              <NotificationsDropdown isOpenMenu={isOpenNotificationsDropdown} />
            </Suspense>
          </div>
        )}

        <div className={styles.dropdownButtonContainer}>
          <button
            className={CN(styles.accountDropdown, styles.text, styles.withSvg)}
            onClick={handleToggleAccountDropdown}
          >
            <div className={styles.userIcon}>
              <span>{user?.email?.[0]}</span>
            </div>
            <div className={styles.cropText}>{user?.email}</div>
            <Icon className={CN({ [styles.isOpen]: isOpenAccountDropdown })} name="arrow_down" size={12} />
          </button>

          <Suspense fallback={null}>
            <AccountDropdown
              isOpenMenu={isOpenAccountDropdown}
              onSignOutClick={onSignOutClick}
              onToggleMenuClick={handleToggleAccountDropdown}
            />
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default NavigationDesktop;
