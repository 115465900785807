import type { SharedApiService } from '.';
import type { CampaignFetchProps, CampaignInfo, CampaignPlanInfo } from '../types';
import { getDiscountedPlanPrice, getFormattedPrice, isClient } from '../utils';

export default class CampaignService {
  private api: SharedApiService;

  constructor(api: SharedApiService) {
    this.api = api;
  }

  async fetchCampaignInfo({ locale, query, campaignName, promoCode }: CampaignFetchProps): Promise<CampaignInfo> {
    const campaign =
      query?.campaignName || (isClient && sessionStorage.getItem('campaignName')) || campaignName || 'default';

    const queryParams = promoCode ? `?promoCode=${encodeURIComponent(promoCode)}` : '';
    const headers = locale ? { locale } : undefined;

    const campaignInfo = await this.api.get<CampaignInfo>(`/campaigns/${campaign}${queryParams}`, { headers });

    const campaignInfoModified: CampaignInfo = {
      ...campaignInfo,
      plans: campaignInfo.plans.map((plan: CampaignPlanInfo) => {
        const planPriceFormattedAmount = getFormattedPrice(plan.price.amount ?? 0);
        return {
          ...plan,
          price: {
            ...plan.price,
            amount: planPriceFormattedAmount,
          },
          ...(!plan.discount && {
            promoCode: null,
          }),
          ...(plan.discount && {
            discount: {
              ...plan.discount,
              amountOff: getFormattedPrice(plan.discount?.amountOff ?? 0),
              discountPrice: getDiscountedPlanPrice(planPriceFormattedAmount, plan.discount),
            },
          }),
        };
      }) as CampaignPlanInfo[],
    };
    return campaignInfoModified;
  }
}
