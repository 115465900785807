import CN from 'clsx';

import styles from './styles.module.scss';

type WeeklyActivityItemType = {
  title: string;
  isHaveProgress?: boolean;
  isToday?: boolean;
};

const WeeklyActivityItem = ({ title, isHaveProgress = false, isToday = false }: WeeklyActivityItemType) => {
  const modifyItem = CN(styles.circle, { [styles.isHaveProgress]: isHaveProgress }, { [styles.isToday]: isToday });

  return (
    <div className={styles.item}>
      <div className={modifyItem} />
      <div className={styles.letter}>{title}</div>
    </div>
  );
};

export default WeeklyActivityItem;
