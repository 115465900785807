import type { Block } from 'albert-common';
import { Apps } from 'albert-common';
import dayjs from 'dayjs';
import { orderBy } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocale } from 'store/hooks';
import type { JuniorContentStats, TransformedProgress } from 'types';
import { getJuniorLocale } from 'utils';

import { DATE_FORMAT } from '@albert/shared/constants';
import { Bugsnag } from '@albert/shared/services';
import type { Locales } from '@albert/shared/types';
import { Locale } from '@albert/shared/types';

import { albertCommon as albertCommonInstance } from '../services';

interface Progress {
  blockId: number;
  exercise: string;
  successRate: number;
}

export const getTranslatedBlockTitle = (blockId: number | string, locale: Locales) => {
  try {
    const translations = albertCommonInstance().translation.get({
      app: Apps.junior,
      locale: getJuniorLocale(locale),
      translationKey: 'statictext_texts.blocks',
    });
    const swedish = albertCommonInstance().translation.get({
      app: Apps.junior,
      locale: getJuniorLocale(Locale.SE),
      translationKey: 'statictext_texts.blocks',
    });

    const blockTitle = translations.filter((block: any) => Number(block.block_id) === Number(blockId))[0]?.title;
    const swedishBlockTitle = swedish.filter((block: any) => Number(block.block_id) === Number(blockId))[0]?.title;

    return blockTitle ?? swedishBlockTitle;
  } catch (e: any) {
    Bugsnag.notify(e);
  }
};

const getProgress = (contentStats: JuniorContentStats, locale: Locales) =>
  Object.entries(contentStats).reduce<Record<string, Progress>>((acc, [exercise, data]) => {
    if (exercise.length > 7) {
      if (data.attempts) {
        Object.values(data.attempts).forEach((item) => {
          const { createdAt, successRate } = item;

          const savedSuccesRate = acc[`${dayjs(createdAt).format(DATE_FORMAT[locale])}_${exercise}`]?.successRate || 0;

          try {
            if (successRate > savedSuccesRate) {
              acc[`${dayjs(createdAt).format(DATE_FORMAT[locale])}_${exercise}`] = {
                successRate,
                blockId: albertCommonInstance().content.getBlock(
                  albertCommonInstance().content.getExercise(exercise)?.blockId,
                )?.id,
                exercise,
              };
            }
          } catch {
            // return the previous acc;
          }
        });
      }
    }

    return acc;
  }, {});

const useJuniorProgress = () => {
  const albertCommon = albertCommonInstance();

  return (contentStats?: JuniorContentStats) => {
    const { t } = useTranslation('parent');
    const locale = useLocale();

    const getRes = (contentStats?: JuniorContentStats) => {
      if (!contentStats) return {};
      const progress = getProgress(contentStats, locale);

      const neededDates = orderBy(
        Object.keys(progress)
          .map((item) => {
            const date = item.split('_')[0];
            return dayjs(date).format(DATE_FORMAT[locale]);
          })
          .filter((value, index, self) => {
            return value && self.indexOf(value) === index;
          }),
        [],
        ['desc'],
      );

      return neededDates.reduce<TransformedProgress>((acc, date) => {
        const exercisesByDate = Object.entries(progress).filter(([key]) => key.split('_')[0] === date);

        // eslint-disable-next-line
        exercisesByDate.forEach(([_, { successRate, blockId, exercise }]) => {
          if (!acc[date]) {
            acc[date] = {};
          }

          // To exclude comics and boss fight
          if (exercise.length > 7 && albertCommon.content.getExercise(exercise)) {
            const block: Block = albertCommon.content.getBlock(blockId);
            const translatedBlockName = getTranslatedBlockTitle(blockId, locale);

            const blockName: string = block.isNew
              ? `${translatedBlockName} (${t('parentReport.progress.isNewExercise')})`
              : translatedBlockName;

            if (!acc[date][blockName]) {
              acc[date][blockName] = {};

              block.content.forEach((exerciseTitle) => {
                // To exclude comics and boss fight
                if (exerciseTitle.length > 7) {
                  let latestSuccess = 0;

                  const attempts = contentStats[exerciseTitle]?.attempts;

                  if (attempts) {
                    const filteredAttempts = Object.values(attempts).filter(
                      (item) => dayjs(item.createdAt).format(DATE_FORMAT[locale]) < date,
                    );

                    if (filteredAttempts.length) {
                      latestSuccess = filteredAttempts[filteredAttempts.length - 1].successRate;
                    }
                  } else if (contentStats[exerciseTitle]?.successRate && contentStats[exerciseTitle]?.solvedOnce) {
                    latestSuccess = contentStats[exerciseTitle].successRate;
                  }

                  acc[date][blockName][exerciseTitle] = latestSuccess;
                }
              });
            }
            acc[date][blockName][exercise] = successRate;
          }
        });

        return acc;
      }, {});
    };

    return useMemo(() => getRes(contentStats), [contentStats]);
  };
};

export default useJuniorProgress;
