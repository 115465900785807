import { Membership } from '@albert/shared/types';

export const parseQueryStringToObject = <T>(queryString?: string) => {
  const params = new URLSearchParams(queryString);
  const object = {};

  params.forEach((value, key) => {
    Reflect.set(object, key, value);
  });

  return object as T;
};

export const getMembershipFromProductId = (productId: string): Membership | undefined => {
  const parsedMembership = productId.split('_')?.[1]?.toLowerCase();

  switch (parsedMembership) {
    case Membership.Bas:
      return Membership.Bas;
    case Membership.Standard:
      return Membership.Standard;
    case Membership.Plus:
      return Membership.Plus;
    default:
      return undefined;
  }
};

export const getSanitizedRedirectPath = (redirectPath: string) => {
  return redirectPath.split('?')[0];
};
