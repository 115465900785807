import CN from 'clsx';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  isExpiredCard: boolean;
  onSetCardAsDefault: () => void;
  onDeleteCard: () => void;
}

const OptionsDropdown = ({ isOpen, isExpiredCard, onSetCardAsDefault, onDeleteCard }: Props) => {
  const { t } = useTranslation('parent');

  return (
    <ul className={CN(styles.dropdownMenu, { [styles.isOpen]: isOpen })}>
      {!isExpiredCard && (
        <li className={styles.menuItem}>
          <button tabIndex={!isOpen ? -1 : undefined} onClick={onSetCardAsDefault}>
            {t('paymentMethodsPage.methodsList.setAsDefault')}
          </button>
        </li>
      )}
      <li className={styles.menuItem}>
        <button tabIndex={!isOpen ? -1 : undefined} className={styles.deleteCard} onClick={onDeleteCard}>
          {t('paymentMethodsPage.methodsList.delete')}
        </button>
      </li>
    </ul>
  );
};

export default memo(OptionsDropdown);
