import type { ReactNode } from 'react';

import { Icon } from '@albert/shared/components';

import styles from './styles.module.scss';

interface Props {
  text: string | ReactNode;
}

const AdvantageItem = ({ text }: Props) => {
  return (
    <li className={styles.advantageItem}>
      <span className={styles.advantageItemIcon}>
        <Icon name="checkmark" />
      </span>
      <p>{text}</p>
    </li>
  );
};

export default AdvantageItem;
