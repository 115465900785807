import { markNotificationsAsSeen, sendEvent } from 'api';
import CN from 'clsx';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { notificationsMenuOpenEvent } from 'services/analyticsEvents';
import type { RootState } from 'store';

import { Loader } from '@albert/shared/components';

import type { HeaderCommonProps } from '../';
import NotificationItem from './NotificationItem';
import styles from './styles.module.scss';

const NotificationsDropdown = ({
  isOpenMenu,
}: Omit<HeaderCommonProps, 'links' | 'onSignOutClick' | 'onToggleMenuClick'>) => {
  const { t } = useTranslation('parent');
  const notifications = useSelector(({ app }: RootState) => app.notifications);

  useEffect(() => {
    const notSeenNotificationsIds = notifications
      ?.filter((notification) => notification.seenAt === null)
      .map((notification) => notification.id);

    if (isOpenMenu) {
      sendEvent(notificationsMenuOpenEvent());
    }

    if (isOpenMenu && notSeenNotificationsIds && notSeenNotificationsIds.length > 0) {
      const timeoutId = setTimeout(() => {
        markNotificationsAsSeen(notSeenNotificationsIds).then(() => {
          sessionStorage.setItem('notificationsSeen', 'true');
        });
      }, 2000);

      return () => clearTimeout(timeoutId);
    }

    return;
  }, [isOpenMenu]);

  return (
    <div className={CN(styles.notificationsDropdown, { [styles.isOpen]: isOpenMenu })}>
      <h3 className={styles.title}>{t('notificationCenter.title')}</h3>

      {notifications === null && <Loader />}
      {notifications?.length && (
        <>
          {notifications.some((notification) => notification.seenAt === null) && (
            <h5 className={styles.subtitle}>{t('notificationCenter.unread')}</h5>
          )}
          <ul>
            {notifications.map((notification) => {
              if (notification.seenAt === null) {
                return (
                  <NotificationItem
                    key={notification.id}
                    isNew={true}
                    type={notification.type}
                    createdAt={notification.createdAt}
                    properties={notification.properties}
                  />
                );
              }
              return;
            })}
          </ul>

          {notifications.some((notification) => notification.seenAt !== null) && (
            <h5 className={styles.subtitle}>{t('notificationCenter.latest')}</h5>
          )}
          <ul>
            {notifications.map((notification) => {
              if (notification.seenAt !== null) {
                return (
                  <NotificationItem
                    key={notification.id}
                    isNew={false}
                    type={notification.type}
                    createdAt={notification.createdAt}
                    properties={notification.properties}
                  />
                );
              }
              return;
            })}
          </ul>
        </>
      )}
    </div>
  );
};

export default NotificationsDropdown;
