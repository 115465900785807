import { Elements } from '@stripe/react-stripe-js';
import type { Stripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';

import type { PaymentEvents, Membership } from '../../types';
import type { PaymentResponse } from '../../types/payment';
import { getStripeInstance } from '../../utils';
import Loader from '../Loader';
import StripePayment from './StripePayment';
import styles from './styles.module.scss';

interface Props {
  error?: string;
  eventProperties?: PaymentEvents;
  isLoading?: boolean;
  buttonText?: string;
  membership?: Membership;
  useSetupIntent?: boolean;
  getClientSecret: () => Promise<string>;
  getExistingCardId?: () => Promise<string>;
  onSuccess?: (props: PaymentResponse) => any;
  onFailure?: (err: any) => void;
}

const Payment = ({
  error,
  eventProperties,
  isLoading,
  buttonText,
  membership,
  useSetupIntent,
  getClientSecret,
  getExistingCardId,
  onSuccess,
  onFailure,
}: Props) => {
  const [stripeInstance, setStripeInstance] = useState<Stripe | null>(null);

  useEffect(() => {
    const loadStripe = async () => {
      const instance = await getStripeInstance();
      setStripeInstance(instance);
    };
    loadStripe();
  }, []);

  return (
    <div className={styles.root}>
      <Elements stripe={stripeInstance}>
        <StripePayment
          error={error}
          eventProperties={eventProperties}
          buttonText={buttonText}
          membership={membership}
          getClientSecret={getClientSecret}
          useSetupIntent={useSetupIntent}
          getExistingCardId={getExistingCardId}
          onSuccess={onSuccess}
          onFailure={onFailure}
        />
      </Elements>

      {isLoading && (
        <div className={styles.loadingView}>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default Payment;
