import Bugsnag from '@bugsnag/js';
import { apiService } from 'services';

import type { Event, PageTimeEventDto } from '@albert/shared/types';
import { getDeviceType } from '@albert/shared/utils';

export const sendEvent = async (eventData: Event): Promise<void> => {
  const deviceType = getDeviceType();
  await apiService.post<void, Event>('/events', {
    ...eventData,
    data: {
      ...eventData.data,
      ...(deviceType && { device_type: deviceType }),
    },
  });
};

export const sendPageTimeEvent = async (dto: PageTimeEventDto) => {
  await apiService.post('/events/chpv2', dto).catch((error) => {
    Bugsnag.notify(error);
  });
};
