import { memo } from 'react';

import styles from './styles.module.scss';

interface Props {
  label: string;
}

const PlanTopLabel = ({ label }: Props) => {
  return <div className={styles.topLabel}>{label}</div>;
};

export default memo(PlanTopLabel);
