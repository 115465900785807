import { COMMON_PHONE_VALIDATION_PATTERN, SE_PHONE_VALIDATION_PATTERN } from '../constants/validationPatterns';

export const checkPhoneNumberSE = (value: string) => {
  return !!value.match(SE_PHONE_VALIDATION_PATTERN);
};

export const validatePhoneNumber = (value: string) => {
  return COMMON_PHONE_VALIDATION_PATTERN.test(value);
};

/**
 * Check if email address is valid according to Braze's specification.
 * Reference: https://www.braze.com/docs/user_guide/onboarding_with_braze/email_setup/email_validation/#email-validation
 */
export const validateEmail = (email: string): boolean => {
  const index = email.indexOf('@');
  if (index === -1) {
    return false;
  }

  const local = email.slice(0, index);
  const host = email.slice(index + 1);

  // Validate local part (Braze uses different rules for Microsoft domains).
  if (/^(msn|hotmail|outlook|live)/i.test(host)) {
    if (!/^\w[\w-]*(?:\.[\w-]+)*$/i.test(local)) {
      return false;
    }
  } else {
    if (!new RegExp("^[\\p{L}\\p{N}_-](?:[\\p{L}\\p{N}_.+'&#/-]*[\\p{L}\\p{N}_-])?$", 'u').test(local)) {
      return false;
    }
  }

  // Gmail addresses has to be at least 5 characters long.
  if (/^gmail/.test(host) && local.length < 5) {
    return false;
  }

  // Verify the hostname part.
  if (!/^[a-z\d](?:[a-z\d-]{0,61}[a-z\d])?(?:\.[a-z\d](?:[a-z\d-]{0,61}[a-z\d])?)+$/i.test(host)) {
    return false;
  }

  // Verify that the TLD is at least 2 characters long.
  if (host.slice(host.lastIndexOf('.') + 1).length < 2) {
    return false;
  }

  return true;
};

export const replaceDatoCMSVariables = (originalText: string, variables: Record<string, string>) => {
  if (Object.keys(variables).length !== 0) {
    return originalText.replace(/({([^}]+)})/g, (i) => {
      const key = i.replace(/{/, '').replace(/}/, '');

      if (!variables[key]) {
        return i;
      }

      return variables[key];
    });
  } else {
    return originalText;
  }
};
