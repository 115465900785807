import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import type { RootState } from 'store';
import { actions } from 'store/app/reducers';
import { setArticles, setUpdateArticles } from 'store/app/thunks';
import { useLocale, useAppDispatch } from 'store/hooks';
import { getArticles, getUpdateArticles } from 'utils';

import { Bugsnag } from '@albert/shared/services';
import type { PPActiclesCardType, PPUpdateActiclesCardType } from '@albert/shared/types';

interface UseArticlesReturnAPI {
  articles: PPActiclesCardType[];
  updateArticles: PPUpdateActiclesCardType[];
}

const useArticles = (onlyLastUpdateArticle?: boolean): UseArticlesReturnAPI => {
  const locale = useLocale();
  const dispatch = useAppDispatch();

  const articles = useSelector((state: RootState) => state.app.articles);
  const updateArticles = useSelector((state: RootState) => state.app.updateArticles);
  const isArticlesLoaded = useSelector((state: RootState) => state.app.isArticlesLoaded);

  // Available articles go first
  // Articles for PLUS and STANDARD users only go next
  const sortedArticles = articles.slice().sort((a, b) => Number(a.premiumFeature) - Number(b.premiumFeature));

  useEffect(() => {
    if (onlyLastUpdateArticle) {
      (async () => {
        try {
          /** Get latest update article and check if we have it in store by id  */
          const latestUpdateArticle = (await getUpdateArticles(locale, true))[0];

          if (
            latestUpdateArticle?.slug &&
            updateArticles.every((article) => article.id !== latestUpdateArticle.id && article.slug)
          ) {
            /** If article isn't in store - set it in store  */
            dispatch(setUpdateArticles([latestUpdateArticle, ...updateArticles]));
          }
        } catch (err: any) {
          Bugsnag.notify(err);
        }
      })();
    }
  }, [onlyLastUpdateArticle]);

  useEffect(() => {
    if (!isArticlesLoaded) {
      (async () => {
        try {
          const [articleList, updateArticlesList] = await Promise.all([getArticles(locale), getUpdateArticles(locale)]);

          if (articleList.every((article) => article.slug)) {
            dispatch(setArticles(articleList));
          }

          if (updateArticlesList.every((article) => article.slug)) {
            dispatch(setUpdateArticles(updateArticlesList));
          }

          dispatch(actions.setIsArticlesLoaded(true));
        } catch (err: any) {
          Bugsnag.notify(err);
          dispatch(actions.setIsArticlesLoaded(false));
        }
      })();
    }
  }, [isArticlesLoaded]);

  return { articles: sortedArticles, updateArticles };
};

export default useArticles;
