export default {
  HOME: '/',
  NOT_FOUND: '/404',
  ABOUT: '/about',
  BLOG: {
    ROOT: '/blog',
    BLOG_PAGE: '/blog/page/',
    BLOG_POST: '/blog/',
  },
  CHECKOUT: {
    PLAN: '/checkout/plan',
    CUSTOMER_INFO: '/checkout/customer-info',
    PAYMENT: '/checkout/payment',
    SUBSCRIPTION_DONE: '/checkout/subscription-done',
  },
  CONTACTS: '/contacts',
  COOKIES: '/cookies',
  CSR: '/csr',
  FAQ: '/faq',
  GIFTCARD: '/giftcard',
  PRIVACY: '/privacy',
  BOOTCAMP: '/bootcamp',
  TEEN_APP: '/teen-app',
  SUBJECTS: {
    ROOT: '/subjects',
    SUBJECT: '/subject/',
  },
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  UNAVAILABLE: '/unavailable',
};
