import type { Middleware } from '@reduxjs/toolkit';

export const loggerMiddleware: Middleware = (storeAPI) => (next) => (action: any) => {
  console.group('%c Action:', 'color: black; font-weight: bold;', action.type);
  const initialState = storeAPI.getState();
  console.info('%c Prev State:', 'color: #7777bf; font-weight: bold;', initialState);
  console.info('%c Dispatching:', 'color: #46AAF1; font-weight: bold;', action);
  const result = next(action);
  console.info('%c Next State:', 'color: #23ae8e; font-weight: bold;', storeAPI.getState());
  console.groupEnd();
  return result;
};
