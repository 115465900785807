import { sendEvent } from 'api';
import routes from 'constants/routes';
import { useQuery } from 'hooks';
import isString from 'lodash/isString';
import { useState, useEffect, lazy, Suspense } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router';
import { ConfigService, FirebaseService, apiService } from 'services';
import { loginEvent } from 'services/analyticsEvents';
import { useAppDispatch } from 'store/hooks';
import { setUserData } from 'store/user/thunks';
import { EmailLinkOfferParam, FirebaseAuthError } from 'types';

import { Button, Input, Icon, Loader } from '@albert/shared/components';
import { Cookie } from '@albert/shared/services';
import { validateEmail } from '@albert/shared/utils';

import styles from './styles.module.scss';

const OfferLabel = lazy(() => import('components/common/OfferLabel'));
const DevsToolbar = lazy(() => import('components/common/DevsToolbar'));

interface LoginForm {
  username: string;
  password: string;
}

interface QueryParams {
  token?: string;
  username?: string;
  code?: string;
  campaignName?: string;
  giftcard?: string;
}

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['parent', 'website']);
  const {
    token: tokenParam,
    username,
    code: promoCodeParam,
    campaignName: campaignNameParam,
    giftcard: giftcardParam,
  } = useQuery<QueryParams>();
  const [isLoading, setIsLoading] = useState(false);
  const websiteUrl = ConfigService.get('websiteUrl');
  const token: string | undefined = tokenParam ?? Cookie.get('pp_token');
  const showOfferLabel =
    promoCodeParam ||
    campaignNameParam ||
    giftcardParam ||
    sessionStorage.getItem(EmailLinkOfferParam.Code) ||
    sessionStorage.getItem(EmailLinkOfferParam.CampaignName) ||
    sessionStorage.getItem(EmailLinkOfferParam.Giftcard);

  const [hasToken, setHasToken] = useState<boolean>(() => !!token);

  const {
    formState: { errors },
    register,
    handleSubmit,
    setError,
    setValue,
  } = useForm<LoginForm>();

  const handleLogin = async ({
    username,
    password,
    token,
  }: {
    username?: string;
    password?: string;
    token?: string;
  }) => {
    if (username && password) {
      await FirebaseService.signIn({
        username,
        password,
      });

      sendEvent(loginEvent());

      const usedApps = await apiService.get<{ junior: boolean; teen: boolean }>('/users/has-used-app');

      if (usedApps) {
        dispatch(setUserData({ usedApps }));
      }
    } else if (token) {
      await FirebaseService.signIn({
        token,
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (token && isString(token)) {
        try {
          setIsLoading(true);
          await handleLogin({ token });

          Cookie.remove('pp_token');
        } catch {
          setIsLoading(false);
          setHasToken(false);
          navigate(routes.LOGIN, { replace: true });
        }
      } else if (isString(username)) {
        //If the user was redirected from website to this page,
        //we should be able to prefill the username.
        setValue('username', username);
      }
    })();
  }, [token, username]);

  const onSubmit = async ({ username, password }: LoginForm) => {
    try {
      setIsLoading(true);
      await handleLogin({ username, password });
    } catch (err: any) {
      setIsLoading(false);

      switch (err.code) {
        case FirebaseAuthError.WrongPassword:
          return setError('username', {
            type: 'error',
            message: t('errorMessages.incorrectLoginOrPassword'),
          });
        case FirebaseAuthError.UserNotFound:
          return setError('username', { type: 'error', message: t('errorMessages.noUserWithThisEmail') });
        case FirebaseAuthError.TooManyAttempts:
          return setError('username', { type: 'error', message: t('errorMessages.tooManyAttempts') });
      }
    }
  };

  if (hasToken) {
    return <Loader isFullScreen />;
  }

  return (
    <div className={styles.container}>
      <Suspense fallback={null}>
        <DevsToolbar />
      </Suspense>
      <header className={styles.header}>
        <a href={websiteUrl}>
          <Icon name="albert_logo" fill="var(--slate-grey)" width={110} height={37} />
        </a>
      </header>
      <div className={styles.login}>
        {showOfferLabel && (
          <Suspense fallback={null}>
            <OfferLabel />
          </Suspense>
        )}

        <div className={styles.card}>
          <h1 className={styles.title}>{t('login.title')}</h1>
          <form className={styles.loginForm} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.inputWrapper}>
              <Input
                {...register('username', {
                  required: t('errorMessages.emailMissing'),
                  validate: {
                    isEmail: (value) => (validateEmail(value) ? true : t('errorMessages.emailFormat')),
                  },
                })}
                label={t('general.e-mail')}
                type="email"
                autoComplete="username"
                placeholder={t('website:shared.placeholder.email')}
              />
              <Input
                {...register('password', { required: true })}
                label={t('general.password')}
                type="password"
                placeholder="••••••••"
              />
              <div className={styles.messageWrapper}>
                {errors.username && <p className={styles.errorMessage}>{errors.username.message}</p>}
              </div>
            </div>
            <Button id="login_to_parent_portal_btn" className={styles.button} type="submit" loading={isLoading}>
              {t('login.logIn')}
            </Button>
          </form>
          <Link to={routes.FORGOT_PASSWORD}>
            <p className={styles.link}>{t('login.forgotPassword')}</p>
          </Link>
        </div>
        <p className={styles.p}>
          {t('login.noAccount')}
          <a href={`${websiteUrl}/checkout/plan`} className={styles.linkUnderline}>
            {` ${t('login.registerHere')}`}
          </a>
        </p>
      </div>
    </div>
  );
};

export default Login;
