import { DatoCMS } from '@albert/shared/services';
import type { ArticleStructureType, Locales, PPActiclesCardType, PPUpdateActiclesCardType } from '@albert/shared/types';

export const getArticles = async (locale: Locales): Promise<PPActiclesCardType[]> => {
  const articles = await DatoCMS.requestContent({
    query: DatoCMS.withImageFragment(`
      query GetArticles ($locale: SiteLocale) {
        allArticlesLists(filter: { slug: {neq: null } }, locale: $locale) {
          slug
          title
          premiumFeature
          articleId
          coverImage {
            responsiveImage(imgixParams: { fit: crop, w: 1240, h: 480 }) {
              ...responsiveImageFragment
            }
          }
        }
      }`),
    variables: { locale: DatoCMS.toISOLocale[locale] },
    isCachedEndpoint: true,
  });

  return articles.allArticlesLists;
};

export const getArticle = async (slug: string, locale: Locales): Promise<ArticleStructureType> => {
  const article = await DatoCMS.requestContent({
    query: DatoCMS.withImageFragment(`
      query GetArticle($slug: String, $locale: SiteLocale) {
        articlesList(filter: {slug: {eq: $slug}}, locale: $locale) {
          title
          coverImage {
          responsiveImage(imgixParams: { fit: crop, w: 1240, h: 480 }) {
            ...responsiveImageFragment
          }
          }
          minutesToRead
          content {
            value
          }
        }
      }`),
    variables: { locale: DatoCMS.toISOLocale[locale], slug },
    isCachedEndpoint: true,
  });

  return article.articlesList;
};

export const getUpdateArticles = async (locale: Locales, onlyLatest?: boolean): Promise<PPUpdateActiclesCardType[]> => {
  const updateArticles = await DatoCMS.requestContent({
    query: DatoCMS.withImageFragment(`
      query GetUpdateArticles ($locale: SiteLocale, $first: IntType) {
        allPpUpdateArticles(locale: $locale, first: $first, orderBy: createdAt_DESC, filter: { slug: {neq: null } }) {
          createdAt
          id
          title
          slug
          shortDescription
          coverImage {
            responsiveImage(imgixParams: { fit: crop, w: 1240, h: 480 }) {
              ...responsiveImageFragment
            }
          }
        }
      }`),
    variables: { locale: DatoCMS.toISOLocale[locale], first: onlyLatest ? 1 : 100 },
    isCachedEndpoint: true,
  });

  return updateArticles.allPpUpdateArticles;
};

export const getUpdateArticle = async (slug: string, locale: Locales): Promise<ArticleStructureType> => {
  const updateArticle = await DatoCMS.requestContent({
    query: DatoCMS.withImageFragment(`
      query GetUpdateArticle ($slug: String, $locale: SiteLocale) {
        ppUpdateArticle(filter: {slug: {eq: $slug}}, locale: $locale) {
          createdAt
          id
          title
          slug
          minutesToRead
          content {
            value
          }
          coverImage {
            responsiveImage(imgixParams: { fit: crop, w: 1240, h: 480 }) {
              ...responsiveImageFragment
            }
          }
        }
      }`),
    variables: { locale: DatoCMS.toISOLocale[locale], slug },
    isCachedEndpoint: true,
  });

  return updateArticle.ppUpdateArticle;
};
