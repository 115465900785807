import { isEmpty } from 'lodash';
import { SubscriptionPeriod } from 'types';

import type { DiscountData, Membership } from '@albert/shared/types';
import { BillingInterval, DiscountDurationType, Promocode } from '@albert/shared/types';

interface Params {
  currentBillingPeriod: BillingInterval;
  selectedMembership: Membership;
  selectedBillingPeriod: BillingInterval;
  currentCoupon?: string;
  couponInParams?: string;
  discountInfo?: DiscountData;
}

export const checkCouponWhenChangingPlan = ({
  currentBillingPeriod,
  selectedMembership,
  selectedBillingPeriod,
  currentCoupon,
  couponInParams,
  discountInfo,
}: Params) => {
  if (couponInParams) {
    return couponInParams;
  }

  if (!currentCoupon) {
    return undefined;
  }

  // If the coupon was not found in Stripe
  // or the coupon does not apply to the selected membership
  // or has a repeating duration (applicable only for monthly)
  // the Promocode.Forever30off will be applied
  if (
    (currentCoupon && isEmpty(discountInfo)) ||
    !discountInfo?.appliesTo.includes(selectedMembership) ||
    (discountInfo?.duration?.type === DiscountDurationType.Repeating &&
      selectedBillingPeriod !== BillingInterval.Monthly)
  ) {
    return Promocode.Forever30off;
  }

  // If coupon duration is FOREVER or ONCE - coupon will BE transefered to the new plan in any case
  if (
    discountInfo?.duration?.type === DiscountDurationType.Forever ||
    discountInfo?.duration?.type === DiscountDurationType.Once
  ) {
    return currentCoupon;
  }

  // If coupon duration is REPEATING & current plan & selected plan are both MONTHLY - coupon will BE transfered
  if (
    discountInfo?.duration?.type === DiscountDurationType.Repeating &&
    currentBillingPeriod === BillingInterval.Monthly &&
    selectedBillingPeriod === BillingInterval.Monthly
  ) {
    return currentCoupon;
  }

  return undefined;
};

export const convertPeriodToBillingInterval = (subscriptionPeriod: SubscriptionPeriod) => {
  /* NOTE: The reason of having this is because on PP we could have IAP users which is using
  SubscriptionPeriod enum, while on the Website we have only Stripe users,
  which is using BillingInterval enum.
*/
  switch (subscriptionPeriod) {
    case SubscriptionPeriod.OneMonth:
      return BillingInterval.Monthly;
    case SubscriptionPeriod.ThreeMonths:
      return BillingInterval.Quarterly;
    case SubscriptionPeriod.SixMonths:
      return BillingInterval.SemiAnnual;
    case SubscriptionPeriod.OneYear:
      return BillingInterval.Annual;
    default:
      return BillingInterval.Monthly;
  }
};
