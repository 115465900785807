import { APPS_ORDER, APPS_ORDER_DEFAULT, SUBJECTS_ORDER } from '../constants';
import type { AppData, Subject } from '../types';

export const prepareSubjectsList = (apps: AppData[]): Subject[] => {
  return Array.from(
    new Set(
      apps
        ?.map((app) => app.subjects)
        .flat()
        .map((subject) => subject.alias),
    ),
  );
};

export const sortApps = (apps: AppData[], orderType?: 'default' | 'alternative'): AppData[] => {
  const order = orderType === 'default' ? APPS_ORDER_DEFAULT : APPS_ORDER;

  const sortedApps = [...apps].sort((a, b) => {
    return order.indexOf(a.alias) - order.indexOf(b.alias);
  });

  return sortedApps;
};

export const sortSubjects = (subjects: Subject[]): Subject[] => {
  const sortedSubjects = [...subjects].sort((a, b) => {
    return SUBJECTS_ORDER.indexOf(a) - SUBJECTS_ORDER.indexOf(b);
  });

  return sortedSubjects;
};
