import CN from 'clsx';
import { forwardRef } from 'react';

import styles from './styles.module.scss';

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  className?: string;
  labelClass?: string;
  fieldClass?: string;
  errorClass?: string;
  error?: string;
}

const Textarea = forwardRef(
  (
    { label = '', required, className = '', labelClass = '', fieldClass = '', errorClass = '', error, ...rest }: Props,
    ref: React.Ref<HTMLTextAreaElement>,
  ) => {
    return (
      <label className={CN(styles.label, className)}>
        {label && (
          <span className={CN(styles.labelText, labelClass)}>
            {label}
            {required && <span className={styles.requiredStarSymbol}>*</span>}
          </span>
        )}
        <textarea
          ref={ref}
          className={CN(styles.textarea, fieldClass, { [styles.hasError]: error })}
          {...rest}
        ></textarea>
        {error && <div className={CN(styles.error, errorClass)}>{error}</div>}
      </label>
    );
  },
);

export default Textarea;
