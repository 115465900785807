import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import type { RootState } from 'store';
import { useLocale } from 'store/hooks';

import { Button } from '@albert/shared/components';
import { DATE_FORMAT } from '@albert/shared/constants';
import { BillingInterval, Locale, Membership, PaymentProvider } from '@albert/shared/types';

import NextPaymentInfo from '../NextPaymentInfo';
import ReferralBalance from '../ReferralBalance';
import styles from './styles.module.scss';

interface Props {
  isLoading: boolean;
  onChangeSubscriptionClick: () => void;
  onReactivateSubscriptionClick: () => void;
  onCancelSubscriptionClick: () => void;
}

const SubscriptionInfoCard = ({
  isLoading,
  onChangeSubscriptionClick,
  onReactivateSubscriptionClick,
  onCancelSubscriptionClick,
}: Props) => {
  const { t } = useTranslation(['parent', 'website']);
  const locale = useLocale();
  const subscription = useSelector((state: RootState) => state.subscription?.subscription)!;

  const trialEndDate = dayjs(subscription.trialEnd);
  const isTrialUser = subscription?.inTrial && dayjs().isBefore(trialEndDate);
  const hasStripeSubscription = PaymentProvider.Stripe === subscription?.provider;
  const hasCancelledSubscription = subscription.endingAt;
  const hasBalanceInfo = locale === Locale.SE && hasStripeSubscription;
  const hasForbiddenSubscriptionChange =
    subscription.billingInterval === BillingInterval.Quarterly ||
    subscription.billingInterval === BillingInterval.SemiAnnual;
  const freeDiscount = subscription.planInfo.discounts.filter(
    (item) => item.amount === subscription.planInfo.price && dayjs(Number(item.expiration)) > dayjs(),
  )[0];

  const getSubscriptionName = () => {
    if (subscription.planInfo.productId.includes(Membership.Bas)) {
      return t('website:shared.subscription.plan.name.bas');
    } else if (subscription.planInfo.productId.includes(Membership.Plus)) {
      return t('website:shared.subscription.plan.name.plus');
    } else {
      return t('website:shared.subscription.plan.name.standard');
    }
  };

  const getFreeUntilInfo = () => {
    if (isTrialUser) {
      return `${t('subscriptionPage.subscription.general.freeUntil')}: ${trialEndDate.format(DATE_FORMAT[locale])}`;
    }

    if (!isEmpty(freeDiscount) && !hasCancelledSubscription) {
      return `${t('subscriptionPage.subscription.general.freeUntil')}: ${dayjs(Number(freeDiscount.expiration)).format(
        DATE_FORMAT[locale],
      )}`;
    }

    return null;
  };

  return (
    <div className={styles.card}>
      <h2 className={styles.cardTitle}>{t('general.membership')}</h2>
      <div className={styles.cardSubContainer}>
        <div className={styles.row}>
          <h3 className={styles.subscriptionName}>{getSubscriptionName()}</h3>
        </div>

        {getFreeUntilInfo() && <div className={styles.row}>{getFreeUntilInfo()}</div>}

        <div className={styles.row}>
          <div className={styles.paymentInfoContainer}>
            <NextPaymentInfo />

            {hasBalanceInfo && (
              <Suspense fallback={null}>
                <ReferralBalance />
              </Suspense>
            )}
          </div>
        </div>
      </div>

      <div className={styles.buttonsContainer}>
        {hasCancelledSubscription ? (
          <Button loading={isLoading} onClick={onReactivateSubscriptionClick}>
            {t('subscriptionPage.subscription.general.continueMembership')}
          </Button>
        ) : (
          <>
            {!hasForbiddenSubscriptionChange && (
              <Button loading={isLoading} onClick={onChangeSubscriptionClick}>
                {t('subscriptionPage.subscription.change.title')}
              </Button>
            )}
            <Button
              loading={isLoading}
              theme="secondary"
              className={styles.btnSecondary}
              onClick={onCancelSubscriptionClick}
            >
              {t('subscriptionPage.subscription.general.cancel')}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default SubscriptionInfoCard;
