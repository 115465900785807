import { Icon } from '@albert/shared/components';

import styles from './styles.module.scss';

interface Props {
  title: string;
  text?: string | string[];
}

const AdvantageItem = ({ title, text }: Props) => {
  return (
    <li className={styles.advantageItem}>
      <Icon name="checkmark" size={14} className={styles.advantageItemIcon} />
      <div>
        <h5 className={styles.itemTitle}>{title}</h5>
        {text && <p className={styles.itemText}>{text}</p>}
      </div>
    </li>
  );
};

export default AdvantageItem;
