import { apiService } from 'services';
import type { GetCardsResponse, PaymentMethod, CreateAddCardIntentResponse } from 'types';

export const createAddCardIntent = async (): Promise<CreateAddCardIntentResponse> => {
  return await apiService.post<CreateAddCardIntentResponse>('/stripe/cards/create-add-card-intent', undefined);
};

export const getCards = async (): Promise<PaymentMethod[]> => {
  return await apiService.get<GetCardsResponse>('/stripe/cards');
};

export const deleteCard = async (id: string): Promise<void> => {
  return await apiService.delete(`/stripe/cards/${id}`);
};

export const setCardDefault = async (id: string): Promise<void> => {
  return await apiService.post(`/stripe/cards/${id}/set-default`, undefined);
};
