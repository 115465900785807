import { apiService } from 'services';
import type { DiscountFullInfo, HistoricalInvoicesResponse } from 'types';

import { Bugsnag } from '@albert/shared/services';
import CampaignService from '@albert/shared/services/Campaign';

export const fetchCampaignInfo = (props?: { campaignName?: string; promoCode?: string }) => {
  const campaignService = new CampaignService(apiService);
  return campaignService.fetchCampaignInfo({
    campaignName: props?.campaignName,
    promoCode: props?.promoCode,
  });
};

export const fetchHistoricalInvoices = async () => {
  try {
    const res = await apiService.get<HistoricalInvoicesResponse>('/stripe/invoice-history');
    return res.invoices;
  } catch (error: any) {
    Bugsnag.notify(`Failed to fetch historical invoices for a customer. Error — ${error}`);
    return null;
  }
};

export const fetchFullDiscountInfo = async () => {
  try {
    const res = await apiService.get<DiscountFullInfo>('/stripe/discount-info');
    return res;
  } catch (error: any) {
    Bugsnag.notify(`Failed to fetch full discount info. Error — ${error}`);
    return;
  }
};
