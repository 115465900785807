import type { StructuredText as StructuredTextType } from 'datocms-structured-text-utils';
import type { ParsedUrlQuery } from 'querystring';

import type { DiscountData, Locales, BillingInterval, Membership } from '.';

export interface PlanPrice {
  id: string;
  amount: number;
  currency: string;
  interval: BillingInterval;
}

export interface CampaignYearlySavings {
  percentage: number;
}

export interface CampaignPlanInfo {
  name: Membership;
  offerText: string;
  priceText: { text: StructuredTextType };
  price: PlanPrice;
  promoCode: string | null;
  trialDays: number;
  trialEndDate: string | null;
  hasDiscount: boolean;
  yearlySavings: CampaignYearlySavings | null;
  discount?: DiscountData & { discountPrice?: number };
  promoCodePriceText?: string;
}

export interface CampaignInfo {
  name: string;
  plans: CampaignPlanInfo[];
  promoCode: string | null;
  topBanner?: string;
}

export interface CampaignFetchProps {
  campaignName?: string;
  promoCode?: string;
  locale?: Locales;
  query?: ParsedUrlQuery;
}

export enum CampaignName {
  Referral = 'referral-3months',
  Bonnier = 'bonnier',
  NoTrialOffer = 'no-trial-offer',
  NoOffer = 'no-offer',
  Giftcard = 'giftcard',
  NoTrialGiftcard = 'no-trial-giftcard',
  Default = 'default',
}
