export enum Locale {
  SE = 'se',
  NO = 'no',
  PL = 'pl',
  DK = 'dk',
  UK = 'uk',
  IT = 'it',
  FI = 'fi',
  ES = 'es',
  RO = 'ro',
  CZ = 'cz',
}

export enum UnavailableLocale {
  FR = 'fr',
}

export const UnavailableLocalesArray: string[] = Object.values(UnavailableLocale);

export const LocalesArray = [
  Locale.SE,
  Locale.NO,
  Locale.PL,
  Locale.DK,
  Locale.UK,
  Locale.IT,
  Locale.FI,
  Locale.ES,
  Locale.RO,
  Locale.CZ,
] as const;

export const LocalesUpperArray = [
  String(Locale.SE).toUpperCase(),
  String(Locale.NO).toUpperCase(),
  String(Locale.PL).toUpperCase(),
  String(Locale.DK).toUpperCase(),
  String(Locale.UK).toUpperCase(),
  String(Locale.IT).toUpperCase(),
  String(Locale.FI).toUpperCase(),
  String(Locale.ES).toUpperCase(),
  String(Locale.RO).toUpperCase(),
  String(Locale.CZ).toUpperCase(),
] as const;

export type Locales = (typeof LocalesArray)[number];
export type LocalesUpper = (typeof LocalesUpperArray)[number];
