import type { ChildProgressType, ChildType, Parent, ParentData, PaymentMethod } from 'types';

import type { AppThunk } from '../rootReducer';
import { actions } from './reducers';

export const setUser =
  (user: Parent): AppThunk =>
  (dispatch) => {
    dispatch(actions.setUser(user));
  };

export const setUserData =
  (userData: ParentData): AppThunk =>
  (dispatch) => {
    dispatch(actions.setUserData(userData));
  };

export const setChildren =
  (children: ChildType[]): AppThunk =>
  (dispatch) => {
    dispatch(actions.setChildren(children));
  };

export const setChildrenProgress =
  (progress: ChildProgressType[]): AppThunk =>
  (dispatch) => {
    dispatch(actions.setChildrenProgress(progress));
  };

export const setPaymentMethods =
  (paymentMethods: PaymentMethod[]): AppThunk =>
  (dispatch) => {
    dispatch(actions.setPaymentMethods(paymentMethods));
  };
