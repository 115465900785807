import dayjs from 'dayjs';

export type MonthNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export const getMonthName = (month: MonthNumber) => {
  switch (month) {
    case 1:
      return 'january';
    case 2:
      return 'february';
    case 3:
      return 'march';
    case 4:
      return 'april';
    case 5:
      return 'may';
    case 6:
      return 'june';
    case 7:
      return 'july';
    case 8:
      return 'august';
    case 9:
      return 'september';
    case 10:
      return 'october';
    case 11:
      return 'november';
    case 12:
      return 'december';
  }
};

export const checkIfPaymentMethodExpired = (month: number, year: number): boolean => {
  const expiryDate = dayjs()
    .set('month', month - 1)
    .set('year', year)
    .endOf('month');
  return dayjs().isAfter(expiryDate);
};
