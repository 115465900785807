import { deleteChild, editChild, sendEvent } from 'api';
import { BackButton, DeleteButton } from 'components';
import { ChildForm } from 'components';
import routes from 'constants/routes';
import { lazy, Suspense, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { FirebaseService, Toast } from 'services';
import { progressDeletedEvent } from 'services/analyticsEvents';
import type { RootState } from 'store';
import { useLocale } from 'store/hooks';
import type { ChildFormType } from 'types';
import { getFirebaseRefs, getProductTypeByAge } from 'utils';

import { Button } from '@albert/shared/components';
import { Bugsnag } from '@albert/shared/services';

import styles from './styles.module.scss';

const DeleteChildModal = lazy(() => import('components/manage-children-profiles/DeleteChildModal'));

const EditChild = () => {
  const { t } = useTranslation(['parent', 'website']);
  const { id } = useParams<{ id: string }>();
  const childrenData = useSelector((state: RootState) => state.user.children);
  const child = childrenData?.[Number(id)];
  const navigate = useNavigate();
  const locale = useLocale();
  const [isModalOpen, setIsModalOpen] = useState<'progress' | 'child' | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const { control, register, handleSubmit, getValues } = useForm<ChildFormType>({
    defaultValues: {
      name: child?.name,
      age: child?.age,
    },
  });

  const handleToggleModal = (modalType: 'progress' | 'child') => {
    setIsModalOpen(modalType);
  };

  const handleEdit = async () => {
    const { name, age } = getValues();

    setLoading(true);

    try {
      await editChild(child!._id, {
        name,
        productType: getProductTypeByAge(age, locale),
        age: +age,
      });
    } catch (err: any) {
      Bugsnag.notify(err);
      Toast.error(t('website:shared.somethingIsWrong'));
    }

    navigate(routes.MANAGE_CHILDREN_PROFILES.ROOT, { replace: true });
  };

  const handleDelete = async () => {
    setLoading(true);

    try {
      if (isModalOpen === 'progress') {
        await Promise.all([
          FirebaseService.remove(getFirebaseRefs(child!._id).userDataRef),
          sendEvent(
            progressDeletedEvent({
              name: child?.name ?? '',
              age: child?.age ?? 0,
              product_name: child?.productType ?? '',
            }),
          ),
        ]);
      } else {
        await deleteChild(child!._id);
      }
    } catch (err: any) {
      Bugsnag.notify(err);
      Toast.error(t('website:shared.somethingIsWrong'));
    }

    navigate(routes.MANAGE_CHILDREN_PROFILES.ROOT, { replace: true });
  };

  return (
    <div className={styles.content}>
      <BackButton className={styles.backButton} route={routes.MANAGE_CHILDREN_PROFILES.ROOT} />
      <h1 className={styles.title}>{t('editChild.title')}</h1>
      <form onSubmit={handleSubmit(handleEdit)}>
        <ChildForm
          profilePicture={child?.profilePicture}
          register={register}
          control={control}
          deleteButton={<DeleteButton title={t('editChild.delete')} onClick={() => handleToggleModal('child')} />}
        >
          <div className={styles.buttonContainer}>
            <Button className={styles.button} loading={loading}>
              {t('general.save')}
            </Button>
          </div>
        </ChildForm>
      </form>
      <div className={styles.editCard}>
        <p>{t('editChild.deleteProgress')}</p>
        <DeleteButton title={t('editChild.resetProfile')} onClick={() => handleToggleModal('progress')} />
      </div>

      {isModalOpen && (
        <Suspense fallback={null}>
          <DeleteChildModal
            isLoading={loading}
            deleteType={isModalOpen}
            onToggle={() => setIsModalOpen(null)}
            onDelete={handleDelete}
          />
        </Suspense>
      )}
    </div>
  );
};

export default EditChild;
