import { sendEvent, sendPageTimeEvent } from 'api';
import routes from 'constants/routes';
import { useFirebaseAuth } from 'hooks';
import { lazy, Suspense, useEffect, useLayoutEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import { LocaleService } from 'services';
import { ConsentSettingsChange } from 'services/analyticsEvents';

import { Loader } from '@albert/shared/components';
import { CONSENT_INIT_SCRIPT } from '@albert/shared/constants';
import { useSessionId, useTagManager, useTimedPageRefresh } from '@albert/shared/hooks';
import { Bugsnag } from '@albert/shared/services';
import type { ConsentSettings } from '@albert/shared/types';

const AuthRouter = lazy(() => import('./AuthRoute'));
const PrivateRoute = lazy(() => import('./PrivateRoute'));
const Login = lazy(() => import('pages/Login'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const AppLayout = lazy(() => import('components/common/AppLayout'));
const Home = lazy(() => import('pages/Home'));
const SubscriptionRedirects = lazy(() => import('components/common/SubscriptionRedirects'));
const StartGuide = lazy(() => import('pages/StartGuide'));
const ParentReports = lazy(() => import('pages/ParentReports'));
const Trophies = lazy(() => import('pages/Trophies'));
const Worksheets = lazy(() => import('pages/Worksheets'));
const Subscription = lazy(() => import('pages/Subscription'));
const PaymentMethods = lazy(() => import('pages/Subscription/PaymentMethods'));
const ReactivateSubscription = lazy(() => import('pages/Subscription/ReactivateSubscription'));
const CancelSubscription = lazy(() => import('pages/Subscription/CancelSubscription'));
const ChangeSubscription = lazy(() => import('pages/Subscription/ChangeSubscription'));
const Articles = lazy(() => import('pages/Articles'));
const Article = lazy(() => import('pages/Articles/Article'));
const ReferFriends = lazy(() => import('pages/ReferFriends'));
const ManageChildrenProfiles = lazy(() => import('pages/ManageChildrenProfiles'));
const AccountDeleted = lazy(() => import('pages/AccountDeleted'));
const AddChild = lazy(() => import('pages/ManageChildrenProfiles/AddChild'));
const EditChild = lazy(() => import('pages/ManageChildrenProfiles/EditChild'));
const NotFound = lazy(() => import('pages/NotFound'));
const ConsentBanner = lazy(() => import('@albert/shared/components/ConsentBanner'));

const Navigation = () => {
  const { isInitialized: isFirebaseInitialized } = useFirebaseAuth();
  const { pathname, search } = useLocation();
  const tagManager = useTagManager();
  const sessionId = useSessionId();
  useTimedPageRefresh();

  const [isAppInitialized, setAppInitialized] = useState<boolean>(false);

  const handlePageVisitTrack = async (path: string) => {
    try {
      await tagManager.tagPage(path);
    } catch (err: any) {
      Bugsnag.notify(err);
    }
  };

  const handleConsentSettingChange = (consentSettings: ConsentSettings) => {
    tagManager.tagEvent({
      event: 'consent_settings',
      consentSettings,
    });

    sendEvent(ConsentSettingsChange(consentSettings));
  };

  useLayoutEffect(() => {
    if (document.getElementById('consent-init-script')) {
      return;
    }

    const script = document.createElement('script');
    script.innerHTML = CONSENT_INIT_SCRIPT;
    script.id = 'consent-init-script';
    !document.getElementById('consent-init-script') ? document.head.prepend(script) : null;

    return () => {
      !document.getElementById('consent-init-script') ? document.head.removeChild(script) : null;
    };
  }, []);

  useEffect(() => {
    tagManager.init({
      gtmId: process.env.VITE_GTM_ID!,
      auth: process.env.VITE_GTM_AUTH,
      preview: process.env.VITE_GTM_PREVIEW,
    });

    (async () => {
      await LocaleService.init();

      setAppInitialized(true);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!sessionId) {
        Bugsnag.notify('Failed to get sessionId for page view analytics');
        return;
      }

      await Promise.all([
        handlePageVisitTrack(pathname + search),
        sendPageTimeEvent({
          sessionId: sessionId,
          origin: location.origin || window.origin,
          url: pathname + search,
          referrer: document.referrer,
          userAgent: navigator.userAgent,
          screenWidth: screen.width,
          screenHeight: screen.height,
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
          pixelRatio: window.devicePixelRatio,
          eventAt: new Date(),
        }),
      ]);
    })();
  }, [pathname, search]);

  if (!isAppInitialized) {
    return null; // NOTE: keeps UI blank until the app and locale service are initialized
  }

  if (!isFirebaseInitialized) {
    return <Loader isFullScreen />;
  }

  return (
    <Suspense fallback={<Loader isFullScreen />}>
      <Routes>
        <Route
          element={
            <>
              <ConsentBanner onConsentSettingsChange={handleConsentSettingChange} />
              <AuthRouter />
            </>
          }
        >
          <Route path={routes.LOGIN} element={<Login />} />
          <Route path={routes.FORGOT_PASSWORD} element={<ForgotPassword />} />
        </Route>
        <Route path={routes.NOT_FOUND} element={<NotFound />} />
        <Route path={routes.ACCOUNT_DELETED} element={<AccountDeleted />} />
        <Route
          path={routes.HOME}
          element={
            <PrivateRoute>
              <AppLayout />
            </PrivateRoute>
          }
        >
          <Route path="*" element={<Navigate to={routes.NOT_FOUND} replace />} />
          <Route path={routes.HOME} element={<Home />} />
          <Route path={routes.PARENT_REPORTS} element={<ParentReports />} />
          <Route path={routes.WORKSHEETS} element={<Worksheets />} />
          <Route path={routes.START_GUIDE} element={<StartGuide />} />
          <Route path={routes.TROPHIES} element={<Trophies />} />
          <Route path={routes.REFER_FRIEND} element={<ReferFriends />} />
          <Route path={routes.SUBSCRIPTION.ROOT} element={<Subscription />} />
          <Route path={routes.SUBSCRIPTION.PAYMENT_METHODS} element={<PaymentMethods />} />
          <Route
            path={routes.SUBSCRIPTION.CANCEL}
            element={
              <SubscriptionRedirects>
                <CancelSubscription />
              </SubscriptionRedirects>
            }
          />
          <Route
            path={routes.SUBSCRIPTION.CHANGE}
            element={
              <SubscriptionRedirects>
                <ChangeSubscription />
              </SubscriptionRedirects>
            }
          />
          <Route
            path={routes.SUBSCRIPTION.REACTIVATE}
            element={
              <SubscriptionRedirects>
                <ReactivateSubscription />
              </SubscriptionRedirects>
            }
          />
          <Route path={routes.ARTICLES.ROOT} element={<Articles />} />
          <Route path={routes.ARTICLES.ARTICLE} element={<Article />} />
          <Route path={routes.ARTICLES.UPDATE} element={<Article />} />
          <Route path={routes.MANAGE_CHILDREN_PROFILES.ROOT} element={<ManageChildrenProfiles />} />
          <Route path={routes.MANAGE_CHILDREN_PROFILES.ADD} element={<AddChild />} />
          <Route path={routes.MANAGE_CHILDREN_PROFILES.EDIT()} element={<EditChild />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default Navigation;
