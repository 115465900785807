import CN from 'clsx';
import routes from 'constants/routes';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import type { HeaderCommonProps } from './';
import styles from './styles.module.scss';

const AccountDropdown = ({ isOpenMenu, onSignOutClick, onToggleMenuClick }: Omit<HeaderCommonProps, 'links'>) => {
  const { t } = useTranslation(['parent', 'website']);

  return (
    <nav className={CN(styles.dropdownMenu, { [styles.dropdownMenuOpen]: isOpenMenu })}>
      <ul>
        <li className={styles.menuItem}>
          <Link
            to={routes.PARENT_REPORTS}
            className={styles.menuText}
            {...(!isOpenMenu && { tabIndex: -1 })}
            onClick={onToggleMenuClick}
          >
            {t('parentReport.title')}
          </Link>
        </li>
        <li className={styles.menuItem}>
          <Link
            to={routes.LOGIN}
            className={styles.menuText}
            {...(!isOpenMenu && { tabIndex: -1 })}
            onClick={onSignOutClick}
          >
            {t('login.logOut')}
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default AccountDropdown;
