import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import type { RootState } from 'store';
import { actions } from 'store/app/reducers';
import { setChurnOfferCodes, setChurnOffers } from 'store/app/thunks';
import { useAppDispatch, useLocale, useSubscription } from 'store/hooks';
import { UserSubscriptionStatus } from 'types';
import { getChurnOffers, getChurnOfferCodes } from 'utils/churnOffers';

import { Bugsnag } from '@albert/shared/services';
import type { BillingInterval } from '@albert/shared/types';

const useChurnOffers = () => {
  const locale = useLocale();
  const dispatch = useAppDispatch();
  const subscription = useSubscription();
  const churnOffers = useSelector((state: RootState) => state.app.churnOffers);
  const isChurnOffersLoaded = useSelector((state: RootState) => state.app.isChurnOffersLoaded);
  const churnOfferCodes = useSelector((state: RootState) => state.app.churnOfferCodes);
  const isChurnOfferCodesLoaded = useSelector((state: RootState) => state.app.isChurnOfferCodesLoaded);

  const coupon = subscription?.planInfo?.coupon;
  const hasCancellationCoupon = !!(coupon && churnOfferCodes.includes(coupon));
  const isTrialUser = !!subscription?.inTrial && dayjs().isBefore(dayjs(subscription?.trialEnd));
  const hasDiscountedPrice = !!(subscription && subscription.planInfo.discountedPrice < subscription.planInfo.price);

  useEffect(() => {
    if (!isChurnOffersLoaded) {
      (async () => {
        try {
          const churnOffersList = await getChurnOffers(locale);

          dispatch(setChurnOffers(churnOffersList));
          dispatch(actions.setIsChurnOffersLoaded(true));
        } catch (err: any) {
          Bugsnag.notify(err);
          dispatch(actions.setIsChurnOffersLoaded(false));
        }
      })();
    }
  }, [isChurnOffersLoaded, locale]);

  useEffect(() => {
    if (!isChurnOfferCodesLoaded) {
      (async () => {
        try {
          const churnOfferCodes = await getChurnOfferCodes(locale);

          dispatch(setChurnOfferCodes(churnOfferCodes));
          dispatch(actions.setIsChurnOfferCodesLoaded(true));
        } catch (err: any) {
          Bugsnag.notify(err);
          dispatch(actions.setIsChurnOfferCodesLoaded(false));
        }
      })();
    }
  }, [isChurnOfferCodesLoaded, locale]);

  const getNewDiscount = () => {
    if (hasCancellationCoupon || hasDiscountedPrice) return '';

    const userStatus = isTrialUser ? UserSubscriptionStatus.Trialing : UserSubscriptionStatus.Paying;
    const billingPeriod = subscription?.billingInterval as BillingInterval;
    const newDiscount =
      churnOffers.find((offer) => offer.userStatus === userStatus && offer.billingPeriod === billingPeriod)?.offerCode
        ?.code ?? '';

    return newDiscount;
  };

  return { churnOffers, churnOfferCodes, churnOfferCoupon: getNewDiscount() };
};

export default useChurnOffers;
