import { MOBILE_APPS } from 'constants/common';
import dayjs from 'dayjs';
import { keys } from 'lodash';
import { dispatch } from 'store';
import { setSubscription } from 'store/subscription/thunks';
import { setChildren, setChildrenProgress, setUser, setUserData } from 'store/user/thunks';
import type { ChildType, Parent, ParentData } from 'types';
import { getFirebaseRefs } from 'utils';

import type { AbTestsData } from '@albert/shared/constants';
import { Bugsnag } from '@albert/shared/services';

import FirebaseService from './firebase';

type ListenerCallback<T> = (data: T, previous?: T) => void;

class Listener {
  private readonly currentData: Record<string, any> = {};
  private listeners: string[] = [];

  private handleData = <T>(ref: string, data: any, callback: ListenerCallback<T>) => {
    callback(data, this.currentData[ref]);
    this.currentData[ref] = data;
  };

  private addListener = <T>(ref: string, callback: ListenerCallback<T>) => {
    this.listeners.push(ref);

    FirebaseService.on(ref, (data) => {
      this.handleData(ref, data, callback);
    });
  };

  private addListenerWithQuery = <T>(ref: string, query: Record<string, any>, callback: ListenerCallback<T>) => {
    this.listeners.push(ref);

    FirebaseService.queryOn(ref, query, (data) => {
      this.handleData(ref, data, callback);
    });
  };

  initializeListenersForUserId = (userId: string) => {
    this.addListener(getFirebaseRefs(userId).userRef, this.handleUserChange(userId));
    this.addListener(getFirebaseRefs(userId).userDataRef, this.handleUserDataChange);
    this.addListenerWithQuery('users', { parentId: userId }, this.handleChildrenChange);
  };

  private handleUserChange =
    (userId: string): ListenerCallback<Parent> =>
    async (data, previous) => {
      dispatch(
        setUser({
          ...data,
          userId,
        }),
      );

      if (previous?.dateOfValidation && data?.dateOfValidation) {
        this.handleUpdateSubscription(previous?.dateOfValidation, data?.dateOfValidation);
      }
    };

  private handleUpdateSubscription = async (prevDateOfValidation: number, currentDateOfValidation: number) => {
    if (dayjs(currentDateOfValidation).diff(prevDateOfValidation, 'millisecond') > 500) {
      dispatch(setSubscription());
    }
  };

  private handleUserDataChange: ListenerCallback<ParentData> = (data) => {
    const abTests = keys(data.abTests).reduce(
      (ret, it) => ({ ...ret, [it]: Number(Reflect.get(data.abTests!, it)) }),
      {},
    ) as AbTestsData | undefined;

    dispatch(setUserData({ ...data, abTests }));
  };

  private getChildrenWithProfilePicture(data: ChildType[]) {
    return Promise.all(
      data.map(async (it) => {
        let profilePicture = undefined;

        if (it.imageSource === 'image') {
          profilePicture = await FirebaseService.downloadFileFromUrl({
            url: `/profile-pictures/${it.parentId}/${it._id}`,
            storage: FirebaseService.profilePictureStorage,
          }).catch(() => {
            Bugsnag.notify(new Error('Failed to download profile picture'));
            return undefined;
          });
        }

        return { ...it, profilePicture };
      }),
    );
  }

  private handleChildrenChange: ListenerCallback<ChildType[]> = async (data) => {
    const [childrenWithProfilePictures, progress] = await Promise.all([
      this.getChildrenWithProfilePicture(data),
      Promise.all(
        data.map(async (child) => {
          const isJunior = child.productType === MOBILE_APPS.JUNIOR;
          const data = await FirebaseService.read(getFirebaseRefs(child._id).userDataRef);
          return isJunior ? data?.junior : data;
        }),
      ),
    ]);

    dispatch(setChildren(childrenWithProfilePictures));
    dispatch(setChildrenProgress(progress));
  };

  removeAllListeners = () => {
    this.listeners.map(FirebaseService.off);
  };
}

const ListenerService = new Listener();

export default ListenerService;
