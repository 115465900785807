import type { CardBrand } from '@stripe/stripe-js';

import AmexLogo from '@albert/shared/assets/svg/amex_brandmark.svg';
import MastercardLogo from '@albert/shared/assets/svg/mastercard_brandmark.svg';
import VisaLogo from '@albert/shared/assets/svg/visa_brandmark.svg';

export const FETCH_CARDS_POLLING_INTERVAL = 3000;

export const CARD_BRAND_LOGOS: Partial<Record<CardBrand, string>> = {
  visa: VisaLogo,
  mastercard: MastercardLogo,
  amex: AmexLogo,
};
