import type { SharedApiService } from '../services';
import { DiscountService } from '../services';
import type { CampaignPlanInfo, DiscountData, Locales } from '../types';
import { BillingInterval, Membership } from '../types';
import { getFormattedPrice } from './plans';

export const getDiscountInfo = async (
  apiService: SharedApiService,
  locale: Locales,
  promocode: string,
): Promise<DiscountData | null> => {
  const discountService = new DiscountService(apiService);
  const discountRes = await discountService.get({
    locale: locale as Locales,
    promoCode: promocode as string,
  });

  return discountRes || null;
};

export const getGiftcardPlan = (giftcardAmountOff: number, plans: CampaignPlanInfo[]): CampaignPlanInfo | void => {
  const formattedAmountOff = getFormattedPrice(giftcardAmountOff);
  const quarterPrice = plans.find((plan) => plan.price.interval === BillingInterval.Quarterly)?.price.amount ?? 0;
  const semiAnnualPrice = plans.find((plan) => plan.price.interval === BillingInterval.SemiAnnual)?.price.amount ?? 0;
  const annualPrice = plans.find((plan) => plan.price.interval === BillingInterval.Annual)?.price.amount ?? 0;

  if (formattedAmountOff >= quarterPrice && formattedAmountOff < semiAnnualPrice) {
    return plans.find((plan) => plan.name === Membership.Plus && plan.price.interval === BillingInterval.Quarterly);
  } else if (formattedAmountOff >= semiAnnualPrice && formattedAmountOff < annualPrice) {
    return plans.find((plan) => plan.name === Membership.Plus && plan.price.interval === BillingInterval.SemiAnnual);
  } else if (formattedAmountOff >= annualPrice) {
    return plans.find((plan) => plan.name === Membership.Plus && plan.price.interval === BillingInterval.Annual);
  }

  return;
};
