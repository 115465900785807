import routes from 'constants/routes';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useCampaignInfo } from 'store/hooks';

import { Button } from '@albert/shared/components';

import styles from '../../styles.module.scss';

const NoSubscriptionCard = () => {
  const { t } = useTranslation(['parent', 'website']);
  const navigate = useNavigate();
  const campaignInfo = useCampaignInfo();
  const reactivationOfferText = campaignInfo?.plans[0]?.offerText;

  return (
    <div className={styles.card}>
      <h2 className={styles.cardTitle}>{t('general.membership')}</h2>
      <div className={styles.cardSubContainer}>
        <div className={styles.row}>
          <h3>{t('subscriptionPage.subscription.inactive.title')}</h3>
        </div>
        <div className={styles.row}>
          <div>{t('subscriptionPage.general.subscriptionPrice')}</div>
          <div>-</div>
        </div>
        <div className={styles.row}>
          <div>{t('subscriptionPage.general.youPay')}</div>
          <div>-</div>
        </div>
        <div className={styles.row}>
          <div className={styles.text}>{reactivationOfferText ?? t('website:shared.links.signUp')}</div>
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <Button onClick={() => navigate(routes.SUBSCRIPTION.REACTIVATE, { replace: true })}>
          {t('subscriptionPage.subscription.reactivate.button')}
        </Button>
      </div>
    </div>
  );
};

export default NoSubscriptionCard;
