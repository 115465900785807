import { useTranslation } from 'react-i18next';
import { ConfigService } from 'services';

import { EXTERNAL_LINK_ATTRIBUTES, WEBSITE_ROUTES } from '@albert/shared/constants';

import styles from '../../styles.module.scss';

const IntegrationProvider = () => {
  const websiteUrl = ConfigService.get('websiteUrl');
  const { t } = useTranslation('parent');

  const providerName = 'Integration Provider';

  return (
    <div className={styles.card}>
      <h2 className={styles.cardTitle}>{t('general.membership')}</h2>
      <div className={styles.cardSubContainer}>
        <div className={styles.row}>
          <h3>{t('subscriptionPage.subscription.store.title', { store: providerName })}</h3>
        </div>
        <div className={styles.description}>
          <p>{t('subscriptionPage.subscription.store.subtitle', { store: providerName })}.</p>
          <a href={`${websiteUrl}${WEBSITE_ROUTES.FAQ}`} {...EXTERNAL_LINK_ATTRIBUTES}>
            {t('subscriptionPage.subscription.store.link')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default IntegrationProvider;
