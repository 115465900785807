import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { usePaymentMethods } from 'store/hooks';
import { checkIfPaymentMethodExpired } from 'utils';

import { Button, Icon, Loader } from '@albert/shared/components';
import { useMediaMatch } from '@albert/shared/hooks';

import PaymentMethodItem from './PaymentMethodItem';
import styles from './styles.module.scss';

interface Props {
  isLoading: boolean;
  onSetCardAsDefault: (id: string) => void;
  onDeleteSelectedCard: (id: string, isExpired: boolean) => void;
  onOpenAddCard: () => void;
}

const PaymentMethodsList = ({ isLoading, onSetCardAsDefault, onDeleteSelectedCard, onOpenAddCard }: Props) => {
  const { t } = useTranslation(['parent', 'website']);
  const isMobile = useMediaMatch('(max-width: 768px)');
  const cards = usePaymentMethods();

  const cardsSorted = [...(cards ?? [])].sort((a, b) => Number(b.isDefault) - Number(a.isDefault));

  const renderAddCardButton = () => (
    <Button className={styles.addCardButton} onClick={onOpenAddCard}>
      <Icon name="plus_default" className={styles.icon} size={16} /> {t('paymentMethodsPage.addCard')}
    </Button>
  );

  if (isLoading) {
    return <Loader isFullPage />;
  }

  return (
    <div className={styles.rootCard}>
      <div className={styles.cardHeader}>
        <h2 className={styles.title}>{t('paymentMethodsPage.methodsList.title')}</h2>
        {!isMobile && renderAddCardButton()}
      </div>
      <div>
        {cardsSorted?.length > 0 &&
          cardsSorted.map((paymentMethod) => (
            <PaymentMethodItem
              key={paymentMethod.id}
              paymentMethod={paymentMethod}
              onSetCardAsDefault={() => onSetCardAsDefault(paymentMethod.id)}
              onDeleteCard={() =>
                onDeleteSelectedCard(
                  paymentMethod.id,
                  checkIfPaymentMethodExpired(paymentMethod?.expMonth ?? 0, paymentMethod?.expYear ?? 0),
                )
              }
            />
          ))}

        {isMobile && renderAddCardButton()}
      </div>
    </div>
  );
};

export default memo(PaymentMethodsList);
