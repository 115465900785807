import { useTranslation } from 'next-i18next';
import { forwardRef } from 'react';

import styles from './styles.module.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  subLabel?: string | number;
}
const Slider = forwardRef(({ subLabel, error, label = '', ...rest }: Props, ref: React.Ref<HTMLInputElement>) => {
  const { t } = useTranslation('website');

  return (
    <div className={styles.slider}>
      <label htmlFor="slider" className={styles.currentValue}>
        <span>{label ?? t('shared.general.age')}</span>
        <span>
          {!subLabel ? '-' : subLabel} {t('shared.general.year')}
        </span>
      </label>
      <input id="slider" ref={ref} type="range" {...rest} />
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
});

export default Slider;
