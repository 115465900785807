import type { User } from '@firebase/auth';
import { useEffect, useState } from 'react';

import { FirebaseService } from '../services';

const useFirebaseUser = () => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    return FirebaseService.onAuthStateChanged(setUser);
  }, []);

  return user;
};

export default useFirebaseUser;
