import type { Billing as LocalesBilling } from '../locales/website/types';
import { BillingInterval } from '../types/payment';

type BillingTextParts = {
  short: keyof LocalesBilling;
  billed: keyof LocalesBilling;
  full: keyof LocalesBilling;
  pay: keyof LocalesBilling;
};

type BillingIntervalText = {
  [key in BillingInterval]: BillingTextParts;
};

const BILLING_TEXT: BillingIntervalText = {
  [BillingInterval.Monthly]: {
    short: 'month',
    billed: 'billedMonthly',
    full: 'monthFull',
    pay: 'payMonthly',
  },
  [BillingInterval.Annual]: {
    short: 'year',
    billed: 'billedYearly',
    full: 'yearFull',
    pay: 'payYearly',
  },
  [BillingInterval.Quarterly]: {
    short: 'quarterly',
    billed: 'billedQuarterly',
    full: 'quarterlyFull',
    pay: 'payQuarterly',
  },
  [BillingInterval.SemiAnnual]: {
    short: 'semiAnnual',
    billed: 'billedSemiannualy',
    full: 'semiAnnualFull',
    pay: 'paySemiannualy',
  },
};

export default BILLING_TEXT;
