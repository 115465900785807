import { invert } from 'lodash';

import type { Locales } from '../types';

interface Props {
  query: string;
  variables?: Record<string, any>;
  preview?: boolean;
  isCachedEndpoint?: boolean;
}

// It would have been nice to init this class but this service is being used from
// getStaticProps and _app.tsx does not support getStaticProps yet so we use process.env variables instead.
class DatoCMS {
  static requestContent = async ({ query, variables, preview, isCachedEndpoint }: Props) => {
    const { ENVIRONMENT, TOKEN } =
      typeof process.env.VITE_DATO_CONFIG === 'string' || typeof process.env.DATO_CONFIG === 'string'
        ? JSON.parse(process.env.VITE_DATO_CONFIG ?? process.env.DATO_CONFIG)
        : JSON.parse(JSON.stringify(process.env.VITE_DATO_CONFIG ?? process.env.DATO_CONFIG));

    // Specify environment when developing:
    // https://www.datocms.com/docs/content-delivery-api/api-endpoints#specifying-an-environment
    const defaultEndpoint = `https://graphql.datocms.com/${ENVIRONMENT || ''}/${preview ? 'preview' : ''}`;
    // Preview isn't supported on cached endpoint. Environment switch is easier to do on defaultEndpoint
    const cachedEndpoint = `${process.env.VITE_API_URI ?? process.env.NEXT_PUBLIC_API_URL}/v1/datocms/query`;

    const endpoint = isCachedEndpoint ? cachedEndpoint : defaultEndpoint;

    const authHeader: HeadersInit = isCachedEndpoint
      ? {
          secretkey: process.env.VITE_API_INTERNAL_TOKEN || process.env.NEXT_API_INTERNAL_TOKEN || '',
        }
      : { authorization: `Bearer ${TOKEN}` };

    const res = await fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify({
        query,
        variables,
      }),
      headers: {
        'Content-Type': 'application/json',
        ...(preview ? { 'X-Include-Drafts': 'true' } : {}),
        ...authHeader,
      },
    });

    const { data } = await res.json();
    return data;
  };

  static readonly toISOLocale: Record<Locales, string> = {
    se: 'sv_SE',
    no: 'nn_NO',
    dk: 'da_DK',
    pl: 'pl_PL',
    uk: 'en_GB',
    es: 'es_ES',
    it: 'it_IT',
    fi: 'fi_FI',
    ro: 'ro_RO',
    cz: 'cs_CZ',
  };

  static readonly toISOLocaleSorted = Object.keys(DatoCMS.toISOLocale)
    .sort()
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: DatoCMS.toISOLocale[key as Locales],
      }),
      {},
    );

  static readonly fromISOLocale = invert(DatoCMS.toISOLocaleSorted);

  private static readonly responsiveImageFragment = `
  fragment responsiveImageFragment on ResponsiveImage {
    srcSet
    webpSrcSet
    sizes
    src
    width
    height
    aspectRatio
    alt
    title
    base64
  }`;

  static withImageFragment = (query: string) => query + DatoCMS.responsiveImageFragment;
}

export default DatoCMS;
