import { useSubscription } from 'store/hooks';
import { Feature } from 'types/product';

import { Membership } from '@albert/shared/types';

const basFeatures: Feature[] = [];
const standardFeatures: Feature[] = [...basFeatures, Feature.AllApps, Feature.ParentReport, Feature.ExtraArticles];
const plusFeatures: Feature[] = [...standardFeatures, Feature.ManyChildren];

export const useHasFeature = (feature: Feature) => {
  const subscription = useSubscription();
  const plan = subscription?.planInfo?.productId;

  if (!plan) return false;

  if (plan.includes(Membership.Bas)) return basFeatures.includes(feature);
  else if (plan.includes(Membership.Standard)) return standardFeatures.includes(feature);
  else return plusFeatures.includes(feature);
};

export default useHasFeature;
