import type { CardBrand } from '@stripe/stripe-js';
import CN from 'clsx';
import { CARD_BRAND_LOGOS } from 'constants/payment';
import { useState, memo, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { PaymentMethod } from 'types';
import { checkIfPaymentMethodExpired } from 'utils';

import { Button, Icon } from '@albert/shared/components';
import { useClickOutside } from '@albert/shared/hooks';

import OptionsDropdown from './OptionsDropdown';
import styles from './styles.module.scss';

interface Props {
  paymentMethod: PaymentMethod;
  onSetCardAsDefault: () => void;
  onDeleteCard: () => void;
}

const PaymentMethodItem = ({ paymentMethod, onSetCardAsDefault, onDeleteCard }: Props) => {
  const { t } = useTranslation('parent');
  const dropdownMenuRef = useRef<HTMLDivElement>(null);
  const [openOptionsDropdown, setOpenOptionsDropdown] = useState<string | null>(null);

  const handleToggleOptionsDropdown = (id?: string) => {
    setOpenOptionsDropdown((prev) => (prev === id ? null : id ?? null));
  };

  useClickOutside(dropdownMenuRef, handleToggleOptionsDropdown);

  const isExpiredCard = useMemo(
    () => checkIfPaymentMethodExpired(paymentMethod.expMonth ?? 0, paymentMethod.expYear ?? 0),
    [paymentMethod],
  );

  return (
    <div
      className={CN(styles.paymentMethodCard, {
        [styles.expired]: isExpiredCard,
      })}
    >
      {CARD_BRAND_LOGOS?.[paymentMethod?.brand as CardBrand] && (
        <img
          src={CARD_BRAND_LOGOS[paymentMethod?.brand as CardBrand]}
          className={styles.cardBrandLogo}
          draggable={false}
          alt={paymentMethod?.brand}
        />
      )}
      <div className={styles.leftSide}>
        <h3 className={styles.cardNumber}>
          <span>{paymentMethod?.brand}</span> ****{paymentMethod?.last4}
        </h3>
        <p
          className={CN(styles.expirationDate, {
            [styles.expired]: isExpiredCard,
          })}
        >
          Exp. {`${(paymentMethod?.expMonth ?? 0) < 10 ? '0' : ''}${paymentMethod?.expMonth}/${paymentMethod?.expYear}`}
          {isExpiredCard && ' ⚠️'}
        </p>
      </div>

      <div ref={dropdownMenuRef} className={styles.rightSide}>
        {paymentMethod.isDefault ? (
          <span className={styles.defaultPaymentMethodText}>{t('paymentMethodsPage.methodsList.default')}</span>
        ) : (
          <Button className={styles.threeDotsButton} onClick={() => handleToggleOptionsDropdown(paymentMethod.id)}>
            <Icon name="three_dots" size={24} />
          </Button>
        )}

        <OptionsDropdown
          isOpen={openOptionsDropdown === paymentMethod.id}
          isExpiredCard={isExpiredCard}
          onSetCardAsDefault={onSetCardAsDefault}
          onDeleteCard={onDeleteCard}
        />
      </div>
    </div>
  );
};

export default memo(PaymentMethodItem);
