import type { Membership } from '.';
import type { Locale } from './locales';

export enum Promocode {
  SixMonths50off = '6months50off',
  Once30Off = 'once30off',
  Forever30off = 'forever_30_percent_off',
}

export interface GetDiscountRequest {
  promoCode: string;
  locale: Locale;
  priceId?: string;
}

export enum DiscountDurationType {
  Forever = 'forever',
  Once = 'once',
  Repeating = 'repeating',
}

export type DiscountDuration = {
  type: DiscountDurationType | null;
  months: number | null; // only if type === 'repeating' otherwise null
};

export interface DiscountData {
  amountOff: number;
  percentageOff: number;
  trialDays: number | null;
  duration: DiscountDuration | null;
  valid: boolean;
  appliesTo: Membership[];
}

export interface PromoCodeData {
  value: string;
  defaultValue: string;
  isRequired: boolean;
  isValid: boolean;
  isSubmitted: boolean;
  hasError: boolean;
  source?: PromoCodeSource;
  appliesTo?: Membership[];
}

export enum PromoCodeSource {
  Input = 'input',
  Campaign = 'campaign',
  Plan = 'plan',
  Params = 'params',
}
