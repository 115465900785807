import CN from 'clsx';
import routes from 'constants/routes';
import { useFirebaseUser } from 'hooks';
import { Suspense, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router';
import { ConfigService } from 'services';
import type { RootState } from 'store';
import { setNotifications } from 'store/app/thunks';
import { useAppDispatch } from 'store/hooks';

import { Icon } from '@albert/shared/components';
import { EXTERNAL_LINK_ATTRIBUTES } from '@albert/shared/constants';
import { useClickOutside } from '@albert/shared/hooks';

import type { HeaderCommonProps } from './';
import MobileMenu from './MobileMenu';
import NotificationsDropdown from './NotificationsDropdown';
import styles from './styles.module.scss';

const NavigationMobile = (props: HeaderCommonProps) => {
  const dispatch = useAppDispatch();
  const dropdownMenuRef = useRef<HTMLDivElement>(null);
  const user = useFirebaseUser();
  const websiteUrl = ConfigService.get('websiteUrl');
  const { isOpenMenu, onToggleMenuClick } = props;
  const notifications = useSelector(({ app }: RootState) => app.notifications);
  const hasNotifications = !!((notifications?.length ?? 0) > 0);
  const hasNewNotifications = hasNotifications && notifications?.some((notification) => notification.seenAt === null);
  const isSeenNotifications = sessionStorage.getItem('notificationsSeen') === 'true';

  const [isOpenNotificationsDropdown, setOpenNotificationsDropdown] = useState<boolean>(false);

  const onAccountButtonClick = () => {
    setOpenNotificationsDropdown(false);

    if (isOpenMenu) {
      onToggleMenuClick();
    }
  };

  const refetchNotifications = () => {
    dispatch(setNotifications());
    sessionStorage.removeItem('notificationsSeen');
  };

  useEffect(() => {
    if (!isOpenNotificationsDropdown && isSeenNotifications) {
      refetchNotifications();
    }
  }, [isOpenNotificationsDropdown]);

  const handleToggleNotificationsDropdown = () => {
    setOpenNotificationsDropdown((prev) => !prev);
  };

  const handleCloseNotificationsDropdown = () => {
    setOpenNotificationsDropdown(false);
  };

  useClickOutside(dropdownMenuRef, handleCloseNotificationsDropdown);

  return (
    <>
      <div className={styles.left}>
        <div className={styles.logoContainer}>
          <a href={websiteUrl} {...EXTERNAL_LINK_ATTRIBUTES}>
            <Icon name="albert_logo" fill={isOpenMenu ? 'var(--white)' : 'var(--slate-grey)'} width={80} height={34} />
          </a>
        </div>
      </div>

      <div>
        <ul className={styles.mobileLeftContent}>
          {hasNotifications && (
            <li>
              <div ref={dropdownMenuRef}>
                <button
                  className={CN(styles.notificationsButton, {
                    [styles.open]: isOpenNotificationsDropdown,
                    [styles.redBadge]: hasNewNotifications,
                    [styles.hidden]: isOpenMenu,
                  })}
                  onClick={handleToggleNotificationsDropdown}
                >
                  <Icon name="bell" size={20} />
                </button>

                <Suspense fallback={null}>
                  <NotificationsDropdown isOpenMenu={isOpenNotificationsDropdown} />
                </Suspense>
              </div>
            </li>
          )}
          <li>
            <NavLink to={routes.HOME} end={true} onClick={onAccountButtonClick}>
              <div className={styles.userIcon}>
                <span>{user?.email?.[0]}</span>
              </div>
            </NavLink>
          </li>
          <li>
            <button className={CN(styles.menuButton, { [styles.open]: isOpenMenu })} onClick={onToggleMenuClick}>
              <div />
            </button>
          </li>
        </ul>

        <MobileMenu {...props} />
      </div>
    </>
  );
};

export default NavigationMobile;
