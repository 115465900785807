import routes from 'constants/routes';
import { useTranslation } from 'react-i18next';
import {
  articlesPageSelectEvent,
  homePageSelectEvent,
  parentReportPageSelectEvent,
  manageProfilesPageSelectEvent,
  progressPageSelectEvent,
  startguidePageSelectEvent,
  subscriptionPageSelectEvent,
  worksheetPageSelectEvent,
  referFriendsPageSelectEvent,
} from 'services/analyticsEvents';
import { useLocale, useSubscription } from 'store/hooks';

import type { Event } from '@albert/shared/services';
import type { IconType } from '@albert/shared/types';
import { Locale, PaymentProvider } from '@albert/shared/types';

interface SideMenuLinks {
  title: string;
  iconName: IconType;
  route: string;
  show: boolean;
  event: () => Event;
}

const useNavigationLinks = () => {
  const { t } = useTranslation('parent');
  const locale = useLocale();
  const subscription = useSubscription();
  const hasStripeSubscription = PaymentProvider.Stripe === subscription?.provider;

  const SIDEMENU_LINKS: Record<string, SideMenuLinks> = {
    home: {
      show: true,
      title: t('homePage.link'),
      iconName: 'sidemenu_home',
      route: routes.HOME,
      event: homePageSelectEvent,
    },
    reports: {
      show: true,
      title: t('parentReport.title'),
      iconName: 'sidemenu_bar_chart',
      route: routes.PARENT_REPORTS,
      event: parentReportPageSelectEvent,
    },
    worksheets: {
      show: locale === Locale.SE,
      title: 'Övningsblad',
      iconName: 'sidemenu_puzzle',
      route: routes.WORKSHEETS,
      event: worksheetPageSelectEvent,
    },
    'diplomas-and-trophies': {
      show: true,
      title: t('progressPage.title'),
      iconName: 'sidemenu_trophy',
      route: routes.TROPHIES,
      event: progressPageSelectEvent,
    },
    startguide: {
      show: true,
      title: t('onboardingStartguide.link'),
      iconName: 'sidemenu_star',
      route: routes.START_GUIDE,
      event: startguidePageSelectEvent,
    },
    articles: {
      show: true,
      title: t('articles.title'),
      iconName: 'sidemenu_files',
      route: routes.ARTICLES.ROOT,
      event: articlesPageSelectEvent,
    },
    'manage-profiles': {
      show: true,
      title: t('manageProfiles.link'),
      iconName: 'sidemenu_users',
      route: routes.MANAGE_CHILDREN_PROFILES.ROOT,
      event: manageProfilesPageSelectEvent,
    },
    subscription: {
      show: true,
      title: t('subscriptionPage.title'),
      iconName: 'sidemenu_settings',
      route: routes.SUBSCRIPTION.ROOT,
      event: subscriptionPageSelectEvent,
    },
    'refer-friends': {
      show: locale === Locale.SE && hasStripeSubscription,
      title: 'Bjud in en vän, få 50 kr rabatt',
      iconName: 'sidemenu_letter_colored',
      route: routes.REFER_FRIEND,
      event: referFriendsPageSelectEvent,
    },
  };

  return SIDEMENU_LINKS;
};

export default useNavigationLinks;
