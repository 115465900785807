import { Locale } from '../types';
import { isProduction } from '../utils';

export default {
  WEBSITE: {
    [Locale.SE]: `https://${isProduction() ? 'hejalbert.se' : 'albert-website.hejalbert.se'}`,
    [Locale.NO]: `https://${isProduction() ? 'heialbert.no' : 'albert-website.heialbert.no'}`,
    [Locale.DK]: `https://${isProduction() ? 'hejalbert.dk' : 'albert-website.hejalbert.dk'}`,
    [Locale.UK]: `https://${isProduction() ? 'hialbert.co.uk' : 'albert-website.hialbert.co.uk'}`,
    [Locale.PL]: `https://${isProduction() ? 'hejalbert.pl' : 'albert-website.hejalbert.pl'}`,
    [Locale.IT]: `https://${isProduction() ? 'ciaoalbert.it' : 'albert-website.ciaoalbert.it'}`,
    [Locale.FI]: `https://${isProduction() ? 'heialbert.fi' : 'albert-website.heialbert.fi'}`,
    [Locale.ES]: `https://${isProduction() ? 'holaalbert.es' : 'albert-website.holaalbert.es'}`,
    [Locale.RO]: `https://${isProduction() ? 'salutalbert.ro' : 'albert-website.salutalbert.ro'}`,
    [Locale.CZ]: `https://${isProduction() ? 'ahojalbert.cz' : 'albert-website.ahojalbert.cz'}`,
  },
  CAREERS: 'https://the-albertgroup.com/careers',
  INVESTORS: 'https://investors.hejalbert.se',
  TEEN: {
    se: 'https://albertteen.com/se',
    no: '',
    dk: '',
    uk: 'https://albertteen.com/uk',
    pl: '',
    it: '',
    fi: '',
    es: '',
    ro: '',
    cz: '',
  },
  CAREERS_EMAIL: 'work@the-albertgroup.com',
};
