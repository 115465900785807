import type { BillingInterval, Membership } from '@albert/shared/types';

export enum AuthenticationStatus {
  //When Firebase.onAuthChange() has not been called once.
  Initializing = 'initializing',
  //When Firebase.onAuthChange() was called but loading user data
  Loading = 'loading',
  //Firebase onAuthChange() was called but without user data
  Loaded = 'loaded',
}

export interface SearchParams {
  redirect: string;
  target: string;
  campaignName: string;
  code: string;
  billing: BillingInterval;
  plan: Membership;
  giftcard: string;
}

export type FirebaseRef = string;

export enum FirebaseAuthError {
  UserNotFound = 'auth/user-not-found',
  WrongPassword = 'auth/wrong-password',
  TooManyAttempts = 'auth/too-many-requests',
}
