import { getState } from 'store';
import { signOut } from 'utils';

import { SharedApiService } from '@albert/shared/services';

import FirebaseService from './firebase';

export const apiService = new SharedApiService({
  defaultHeaders: {
    'albert-client': process.env.VITE_ALBERT_CLIENT!,
  },
  dynamicHeaders: async () => {
    const token = await FirebaseService.currentUser?.getIdToken();
    return {
      locale: getState()?.app?.locale.toUpperCase(),
      ...(token && { Authorization: token }),
    };
  },
  onAuthError: async () => {
    await signOut();
  },
});
