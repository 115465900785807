import CN from 'clsx';
import { useTranslation } from 'next-i18next';

import styles from './styles.module.scss';

interface Props {
  isFullScreen?: boolean;
  isFullPage?: boolean;
  className?: string;
}

const Loader = ({ isFullScreen, isFullPage, className }: Props) => {
  const { t } = useTranslation('website');

  if (isFullScreen) {
    return (
      <div className={CN(styles.fullScreenContainer, className)}>
        <div className={styles.spinner}>
          <div className={styles.ball} />
          <p className={styles.loaderText}>{t('shared.loading')}</p>
        </div>
      </div>
    );
  }

  if (isFullPage) {
    return (
      <div className={CN(styles.fullPageContainer, className)}>
        <div className={styles.spinner}>
          <div className={styles.ball} />
          <h2 className={styles.loaderText}>{t('shared.loading')}</h2>
        </div>
      </div>
    );
  }

  return (
    <div className={CN(styles.spinner, className)}>
      <div className={styles.ball} />
    </div>
  );
};

export default Loader;
