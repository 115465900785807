import { sendEvent } from 'api';
import CN from 'clsx';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigService } from 'services';
import { downloadAppLinkClickEvent } from 'services/analyticsEvents';

import { Button, Icon, Modal } from '@albert/shared/components';
import { useMediaMatch } from '@albert/shared/hooks';
import type { AppData } from '@albert/shared/types';
import { Membership, Subject } from '@albert/shared/types';
import { isAndroid, isIOS } from '@albert/shared/utils';

import styles from './styles.module.scss';

const QRCodeSVG = lazy(() => import('qrcode.react').then((module) => ({ default: module.QRCodeSVG })));

interface Props {
  open: boolean;
  appInfo: AppData;
  plan?: string;
  onClose: () => void;
}

const AppModal = ({ open, appInfo, plan, onClose }: Props) => {
  const { t } = useTranslation(['parent', 'website']);
  const isMobile = useMediaMatch('(max-width: 768px)');
  const {
    alias,
    title,
    subjects,
    ageRange,
    appStoreUrl,
    googlePlayUrl,
    mixpanelName,
    bannerImageMobile,
    bannerImageDesktop,
  } = appInfo;

  const encodedAppStoreUrl = encodeURIComponent(appStoreUrl);
  const encodedGooglePlayUrl = encodeURIComponent(googlePlayUrl);
  const qrCodePath = `/api/app-qr?app=${alias}&appStore=${encodedAppStoreUrl}&googlePlay=${encodedGooglePlayUrl}&utm_source=pp_startguide&utm_medium=print&utm_campaign=pp_startguide_qr_scan&utm_content=${alias}_qr`;

  const handleDownloadAppClick = () => {
    sendEvent(
      downloadAppLinkClickEvent({
        product_type: 'parent_portal',
        product_name: mixpanelName,
      }),
    );

    if (isAndroid()) window.open(appStoreUrl, '_blank', 'noopener,noreferrer');
    if (isIOS()) window.open(googlePlayUrl, '_blank', 'noopener,noreferrer');
    if (!isAndroid() && !isIOS()) window.open(appStoreUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <Modal
      isOpen={open}
      contentClass={CN(styles.customModal, { [styles.isOpen]: open })}
      overlayClass={styles.customOverlay}
      customCloseButton={
        <div className={styles.closeButton} onClick={onClose}>
          <Icon size={16} name="close_cross_icon" fill="#5a5a73" />
        </div>
      }
      onClose={onClose}
    >
      {isMobile && <img src={bannerImageMobile?.url} draggable={false} className={styles.appBannerImageMobile} />}

      <div className={styles.modalContent}>
        <p className={styles.ageRangeLabel}>
          {ageRange} {t('onboardingStartguide.years')}
        </p>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.subjects}>
          {subjects.map((subject, index) => (
            <span
              key={subject.id}
              className={CN(styles.subject, {
                [styles.disabled]: plan?.includes(Membership.Bas) && !subject.alias.includes(Subject.math),
              })}
            >
              {subject.name}
              {subjects.length - 1 !== index && ', '}
            </span>
          ))}
        </div>

        {!isMobile && <img src={bannerImageDesktop?.url as string} className={styles.appBannerImageDesktop} />}

        {isMobile && (
          <>
            <Button
              className={styles.downloadAppButton}
              disabled={!appStoreUrl || !googlePlayUrl}
              onClick={handleDownloadAppClick}
            >
              {t('onboardingStartguide.downloadApp')}
            </Button>

            <p className={styles.or}>{t('onboardingStartguide.or')}</p>
          </>
        )}

        <h4 className={styles.qrsTitle}>{t('onboardingStartguide.scanQr')}</h4>

        <div className={styles.qr}>
          <Suspense fallback={null}>
            <QRCodeSVG
              value={`${ConfigService.get('websiteUrl')}${qrCodePath}`}
              onClick={() => handleDownloadAppClick()}
            />
          </Suspense>
        </div>
      </div>
    </Modal>
  );
};

export default AppModal;
