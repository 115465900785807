import type { Dispatch } from '@reduxjs/toolkit';
import { useFirebaseUser } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from 'store';

import { AuthenticationStatus } from '../types';

export const useAppDispatch = () => useDispatch<Dispatch<any>>();
export const useAppSelector = useSelector.withTypes<RootState>();

export const useLocale = () => useAppSelector(({ app }: RootState) => app.locale);
export const useUser = () => useAppSelector(({ user }: RootState) => user.user);
export const useUserData = () => useAppSelector(({ user }: RootState) => user.userData);
export const usePaymentMethods = () => useAppSelector(({ user }: RootState) => user.paymentMethods);
export const useSubscription = () => useAppSelector(({ subscription }: RootState) => subscription.subscription);
export const useCampaignInfo = () => useAppSelector(({ subscription }: RootState) => subscription.campaignInfo);
export const useCancelReasons = () => useAppSelector(({ app }: RootState) => app.cancelReasons);

export const useAuth = () => {
  const app = useAppSelector(({ app }: RootState) => app);
  const user = useAppSelector(({ user }: RootState) => user);
  const firebaseUser = useFirebaseUser();
  const subscription = useAppSelector(({ subscription }: RootState) => subscription);

  const getIsInitialized = () => {
    if (app.authInitialized === AuthenticationStatus.Initializing) {
      return false;
    }

    if (app.authInitialized === AuthenticationStatus.Loading) {
      return !!(user.user && user.userData && user.children && subscription.subscription !== undefined && firebaseUser);
    }

    return app.authInitialized === AuthenticationStatus.Loaded;
  };

  return {
    isInitialized: getIsInitialized(),
    uid: user?.user?.userId,
  };
};
