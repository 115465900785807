import { getSubjects } from 'api';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import type { RootState } from 'store';
import { actions } from 'store/app/reducers';
import { setSubjects } from 'store/app/thunks';
import { useLocale, useAppDispatch } from 'store/hooks';

import { Bugsnag } from '@albert/shared/services';

const useSubjects = () => {
  const locale = useLocale();
  const dispatch = useAppDispatch();

  const subjects = useSelector((state: RootState) => state.app.subjects);
  const isSubjectsLoaded = useSelector((state: RootState) => state.app.isSubjectsLoaded);

  useEffect(() => {
    if (!isSubjectsLoaded) {
      (async () => {
        try {
          const subjectsList = await getSubjects(locale);

          dispatch(setSubjects(subjectsList.filter((subject) => subject.subjectName !== '')));
          dispatch(actions.setIsSubjectsLoaded(true));
        } catch (err: any) {
          Bugsnag.notify(err);
          dispatch(actions.setIsSubjectsLoaded(false));
        }
      })();
    }
  }, [isSubjectsLoaded]);

  return { subjects };
};

export default useSubjects;
