import { useTranslation } from 'react-i18next';
import { useSubscription } from 'store/hooks';

import { Button, Modal } from '@albert/shared/components';
import { PaymentProvider } from '@albert/shared/types';

import styles from './styles.module.scss';

interface Props {
  onToggle: () => void;
  onDelete: () => void;
}

const DeleteAccountModal = ({ onToggle, onDelete }: Props) => {
  const { t } = useTranslation('parent');
  const subscription = useSubscription();

  return (
    <Modal isOpen onClose={onToggle}>
      <div className={styles.wrapper}>
        <div className={styles.image} />
        <h1 className={styles.title}>{t('editChild.areYouSure')}</h1>
        <div className={styles.text}>
          {t('subscriptionPage.deleteAccount.hint')}
          {subscription?.provider &&
            [PaymentProvider.Apple, PaymentProvider.Google].includes(subscription?.provider) && (
              <b>{t('subscriptionPage.deleteAccount.iapHint')}</b>
            )}
        </div>
        <div className={styles.buttons}>
          <Button onClick={onToggle} className={styles.cancel}>
            {t('editChild.cancel')}
          </Button>
          <Button onClick={onDelete} className={styles.delete}>
            {t('editChild.delete')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteAccountModal;
