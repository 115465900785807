import { DatoCMS } from '../services';
import type { AppData, Locale } from '../types';

export const getAppsData = async (preview: boolean, locale: Locale): Promise<AppData[]> => {
  const appsData: { allApps: AppData[] } = await DatoCMS.requestContent({
    preview,
    query: `
        query GetAppsData($locale: SiteLocale) {
            allApps(locale: $locale) {
              id
              alias
              title
              ageRange
              availableInPlans
              appStoreUrl
              googlePlayUrl
              mixpanelName
              icon {
                url
              }
              bannerImageMobile {
                url
              }
              bannerImageDesktop {
                url
              }
              subjects {
                id
                label
                name
                platform
                slug
                alias
                age
              }
          }
        }`,
    variables: { locale: DatoCMS.toISOLocale[locale] },
    isCachedEndpoint: true,
  });

  return appsData.allApps;
};
