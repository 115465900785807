import { createElement } from 'react';

import * as lib from '../../assets/icons';
import type { IconType } from '../../types';

type SvgProps = {
  name: IconType;
  className?: string;
  height?: number;
  onClick?: (e: React.MouseEvent<SVGSVGElement | HTMLDivElement>) => void;
  size?: number | string;
  style?: string;
  width?: number;
  fill?: string;
};

const Icon = ({ name, size, width, height, ...rest }: SvgProps) => {
  if (!name) {
    return null;
  }

  const icon = lib[name];

  const svgWidth = size ?? width;
  const svgHeight = size ?? height;

  if (!icon) {
    console.error(name, "Can't find icon!");
    return null;
  }

  return createElement(icon, {
    ...rest,
    style: { minHeight: svgHeight, minWidth: svgWidth },
    width: svgWidth,
    height: svgHeight,
  });
};

export default Icon;
