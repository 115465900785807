import { useTranslation } from 'next-i18next';

import paymentImageDesktopPng from '@albert/shared/assets/images/payment_desktop.png';
import paymentImageDesktopWebp from '@albert/shared/assets/images/payment_desktop.webp';
import paymentImageMobilePng from '@albert/shared/assets/images/payment_mobile.png';
import paymentImageMobileWebp from '@albert/shared/assets/images/payment_mobile.webp';
import { Image } from '@albert/shared/components';
import {
  albertLinks,
  socialMediaLinks,
  LOCALES_WITH_TEEN_APP,
  CURRENT_YEAR,
  EXTERNAL_LINK_ATTRIBUTES,
  WEBSITE_ROUTES as ROUTES,
  WEBSITE_ROUTES,
} from '@albert/shared/constants';

import Icon from '../../components/Icon';
import { useTagManager } from '../../hooks';
import type { Locales } from '../../types';
import { Locale } from '../../types';
import styles from './styles.module.scss';

interface Props {
  Link: any;
  websitePrefix?: string;
}

const Footer = ({ Link, websitePrefix = '' }: Props) => {
  const tagManager = useTagManager();
  const { t, i18n } = useTranslation('website');
  const locale = i18n.language as Locales;

  const sendEvent = (event: string) => () => {
    tagManager.tagEvent({
      event: `footer_${event}`,
      category: 'navigate',
      label: `footer_to_${event}`,
      action: `footer_${event}_button_clicked`,
    });
  };

  const getImage = (src: string | Record<string, unknown>) => {
    if (typeof src === 'string') return src;

    return src.src;
  };

  const MARKETS_WITH_BLOG: Partial<Locales>[] = [Locale.SE, Locale.NO, Locale.PL, Locale.DK, Locale.UK];

  return (
    <footer id="footer" className={styles.footer}>
      <div className={styles.wrapper}>
        <div className={styles.info}>
          <div className={styles.links}>
            <ul>
              <li>
                <h3>{t('shared.links.albert')}</h3>
              </li>
              <li>
                <Link
                  title="go to subjects page"
                  href={`${websitePrefix}${ROUTES.SUBJECTS.ROOT}`}
                  onClick={sendEvent('subjects')}
                >
                  {t('shared.links.subjects')}
                </Link>
              </li>
              {LOCALES_WITH_TEEN_APP.includes(locale) && (
                <li>
                  <a
                    title="go to teen website"
                    href={albertLinks.TEEN?.[locale]}
                    onClick={sendEvent('teenWeb')}
                    {...EXTERNAL_LINK_ATTRIBUTES}
                  >
                    {t('shared.links.teenWeb')}
                  </a>
                </li>
              )}

              {locale === Locale.SE && (
                <>
                  <li>
                    <Link
                      title="go to giftcard page"
                      href={`${websitePrefix}${ROUTES.GIFTCARD}`}
                      onClick={sendEvent('giftcard')}
                    >
                      {t('shared.links.giftcard')}
                    </Link>
                  </li>
                  <li>
                    <Link title="go to csr page" href={`${websitePrefix}${ROUTES.CSR}`} onClick={sendEvent('csr')}>
                      {t('shared.links.csr')}
                    </Link>
                  </li>
                </>
              )}
            </ul>
            <ul>
              <li>
                <h3>{t('shared.links.learnMoreAboutUs')}</h3>
              </li>
              <li>
                <Link title="go to about page" href={`${websitePrefix}${ROUTES.ABOUT}`} onClick={sendEvent('about_us')}>
                  {t('shared.links.aboutUs')}
                </Link>
              </li>
              {locale === Locale.SE && (
                <>
                  <li>
                    <Link
                      title="go to investors page"
                      href={albertLinks.INVESTORS}
                      onClick={sendEvent('investors')}
                      {...EXTERNAL_LINK_ATTRIBUTES}
                    >
                      Investor relations
                    </Link>
                  </li>
                </>
              )}
              {(locale === Locale.SE || locale === Locale.UK) && (
                <>
                  <li>
                    <Link
                      title="go to careers page"
                      href={albertLinks.CAREERS}
                      onClick={sendEvent('careers')}
                      {...EXTERNAL_LINK_ATTRIBUTES}
                    >
                      {t('shared.links.careers')}
                    </Link>
                  </li>
                </>
              )}
            </ul>
            <ul>
              <li>
                <h3>{t('shared.links.contact')}</h3>
              </li>
              <li>
                <Link
                  title="go to faq page"
                  href={`${websitePrefix}${WEBSITE_ROUTES.FAQ}`}
                  onClick={sendEvent('shared.faq')}
                >
                  {t('shared.links.faq')}
                </Link>
              </li>
              <li>
                <Link
                  title="go to contacts page"
                  href={`${websitePrefix}${WEBSITE_ROUTES.CONTACTS}`}
                  onClick={sendEvent('contact')}
                >
                  {t('shared.links.contact')}
                </Link>
              </li>
            </ul>
            <ul>
              <li>
                <h3>{t('shared.cookieConsent.link')}</h3>
              </li>
              <li>
                <Link
                  title="go to terms and conditions page"
                  href={`${websitePrefix}${ROUTES.TERMS_AND_CONDITIONS}`}
                  onClick={sendEvent('terms')}
                >
                  {t('shared.links.termsAndConditions')}
                </Link>
              </li>
              <li>
                <Link
                  title="go to cookie page"
                  href={`${websitePrefix}${ROUTES.COOKIES}`}
                  onClick={sendEvent('cookie')}
                >
                  {t('shared.links.cookies')}
                </Link>
              </li>
              <li>
                <Link
                  title="go to privacy page"
                  href={`${websitePrefix}${ROUTES.PRIVACY}`}
                  onClick={sendEvent('privacy')}
                >
                  {t('shared.links.privacy')}
                </Link>
              </li>
              {MARKETS_WITH_BLOG.includes(locale) && (
                <li>
                  <Link
                    title="go to blog page"
                    href={`${websitePrefix}${ROUTES.BLOG.ROOT}`}
                    onClick={sendEvent('blog')}
                  >
                    {t('shared.links.blog')}
                  </Link>
                </li>
              )}
            </ul>
            <ul className={styles.desktop}>
              <li>
                <h3>{t('shared.links.payment')}</h3>
              </li>
              <li>
                <Image
                  loading="lazy"
                  /** @ts-ignore */
                  src={getImage(paymentImageDesktopPng)}
                  /** @ts-ignore */
                  srcSet={getImage(paymentImageDesktopWebp)}
                  imageType="image/webp"
                  altText="Payment methods"
                  className={styles.payment}
                />
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.bottomRow}>
          <div className={styles.policy}>{`ⓒ ${CURRENT_YEAR} eEducation Albert - All Rights Reserved.`}</div>
          <ul className={styles.megia}>
            <li>
              <Link
                title="go to youtube"
                href={socialMediaLinks.YOUTUBE}
                onClick={sendEvent('youtube')}
                {...EXTERNAL_LINK_ATTRIBUTES}
              >
                <Icon width={40} name="social_youtube" />
              </Link>
            </li>
            <li>
              <Link
                title="go to facebook"
                href={socialMediaLinks.FACEBOOK}
                onClick={sendEvent('facebook')}
                {...EXTERNAL_LINK_ATTRIBUTES}
              >
                <Icon width={40} name="social_fb" />
              </Link>
            </li>
            <li>
              <Link
                title="go to instagram"
                href={socialMediaLinks.INSTAGRAM}
                onClick={sendEvent('instagram')}
                {...EXTERNAL_LINK_ATTRIBUTES}
              >
                <Icon width={40} name="social_instagram" />
              </Link>
            </li>
            <li>
              <Link
                title="go to linkedin"
                href={socialMediaLinks.LINKEDIN}
                onClick={sendEvent('linkedin')}
                {...EXTERNAL_LINK_ATTRIBUTES}
              >
                <Icon width={40} name="social_linkedin" />
              </Link>
            </li>
          </ul>
          <ul className={styles.mobile}>
            <li>
              <h3>{t('shared.links.payment')}</h3>
            </li>
            <div>
              <li>
                <Image
                  loading="lazy"
                  /** @ts-ignore */
                  src={getImage(paymentImageMobilePng)}
                  /** @ts-ignore */
                  srcSet={getImage(paymentImageMobileWebp)}
                  imageType="image/webp"
                  altText="Payment methods"
                  className={styles.payment}
                  classNamePicture={styles.paymentPicture}
                />
              </li>
            </div>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
