import { sendEvent } from 'api';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { useChurnOffers, useFirebaseUser } from 'hooks';
import { lazy, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { apiService, Toast } from 'services';
import {
  cancelSubscriptionButtonClickEvent,
  cancelSubscriptionEvent,
  cancelSubscriptionOffersFetchFailedEvent,
  cancelWarningModalCloseEvent,
  cancellationOfferPopupCloseEvent,
  cancelledReactivatedEvent,
} from 'services/analyticsEvents';
import type { RootState } from 'store';
import { useLocale } from 'store/hooks';
import type { SubscriptionUpdateProps } from 'types';
import { getMembershipFromProductId, sendOfferAppliedEvent } from 'utils';

import { Bugsnag } from '@albert/shared/services';
import type { Locale, MembershipsUppercase } from '@albert/shared/types';
import { PaymentProvider } from '@albert/shared/types';
import { upperCaseFirstLetter } from '@albert/shared/utils';

import SubscriptionInfoCard from './SubscriptionInfoCard';

const ChurnOfferModal = lazy(() => import('./ChurnOfferModal'));
const CancelWarningModal = lazy(() => import('./CancelWarningModal'));

const StripeProvider = () => {
  const { t } = useTranslation(['parent', 'website']);
  const navigate = useNavigate();
  const locale = useLocale();
  const user = useFirebaseUser();
  const subscription = useSelector((state: RootState) => state.subscription.subscription)!;
  const { churnOfferCoupon, churnOffers, churnOfferCodes } = useChurnOffers();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isChurnOfferModalOpen, setChurnOfferModalOpen] = useState<boolean>(false);
  const [isCancelWarningModalOpen, setCancelWarningModalOpen] = useState<boolean>(false);

  const membership = getMembershipFromProductId(subscription.planInfo.productId ?? '');
  const trialEndDate = subscription.trialEnd;
  const leftTrialDays = trialEndDate ? dayjs(trialEndDate).diff(dayjs(), 'days') : 0;
  const isTrialUser = subscription.inTrial && dayjs().isBefore(dayjs(trialEndDate));
  const planPrice = subscription.planInfo.price ?? 0;
  const billingInterval = subscription.billingInterval!;
  const coupon = subscription.planInfo?.coupon;
  const discountDuration = subscription.discountInfo?.duration;
  const hasCancellationCoupon = coupon && churnOfferCodes.includes(coupon);
  const discountedPrice = subscription.planInfo.discountedPrice ?? 0;

  const hasDiscountedPrice = discountedPrice < planPrice;
  const daysAfterRegistration = dayjs().diff(dayjs(user?.metadata.creationTime), 'days');

  const navigateToCancellation = () => {
    navigate(routes.SUBSCRIPTION.CANCEL, { state: { isInCancellationFlow: true } });
  };

  const cancelSubscription = async () => {
    sendEvent(
      cancelSubscriptionButtonClickEvent({
        campaign_name: subscription.planInfo.campaign ?? '',
        subscription_period: billingInterval,
        plan_name: membership?.toUpperCase() as MembershipsUppercase,
        locale: locale.toUpperCase() as Locale,
        in_trial: isTrialUser,
        has_discount: hasDiscountedPrice,
      }),
    );

    // NOTE: if user is on trial - showing warning modal
    if (isTrialUser) {
      setCancelWarningModalOpen(true);
      return;
    }

    // NOTE: if user is paying and has no discount - showing churn offers
    if (!hasCancellationCoupon && !hasDiscountedPrice && churnOffers.length > 0) {
      setChurnOfferModalOpen(true);
      return;
    }

    // NOTE: in edge case if churnOffers were not fetched for some reason
    if (!churnOffers || churnOffers.length === 0) {
      try {
        setLoading(true);
        await apiService.put<void, SubscriptionUpdateProps>('/subscriptions', {
          provider: PaymentProvider.Stripe,
          active: false,
        });

        sendEvent(cancelSubscriptionOffersFetchFailedEvent());

        sendEvent(
          cancelSubscriptionEvent({
            locale,
            cancel_reason: 0,
            cancel_remark: '',
            cancel_sub_reason_position: 0,
            cancel_sub_reason: '',
            campaign_name: subscription.planInfo.campaign ?? '',
            full_price: planPrice,
            in_trial: !!isTrialUser,
            has_discount: !!hasDiscountedPrice,
            subscription_period: subscription!.billingInterval!,
            plan_name: membership?.toUpperCase() as MembershipsUppercase,
            trial_end_date: subscription?.trialEnd ?? '',
            next_payment_date: subscription?.nextPaymentDate ?? '',
          }),
        );
      } catch (e: any) {
        Bugsnag.notify(`Failed to cancel subscription: ${e}`);
      } finally {
        setLoading(false);
        return;
      }
    }

    // NOTE: in any other case - redirecting user to cancellation page
    navigateToCancellation();
  };

  const sendCancellationOfferPopupCloseEvent = (status: 'discount_cancelled' | 'discount_accepted') => {
    sendEvent(
      cancellationOfferPopupCloseEvent({
        discount_popup_status: status,
        discount_type: churnOfferCoupon,
        subscription_period: billingInterval,
        plan_name: membership?.toUpperCase() as MembershipsUppercase,
        trial_end_date: subscription.trialEnd ?? '',
        next_payment_date: subscription.nextPaymentDate ?? '',
        full_price: planPrice,
      }),
    );
  };

  const closeChurnOfferModal = () => {
    setChurnOfferModalOpen(false);
    sendCancellationOfferPopupCloseEvent('discount_cancelled');
    navigateToCancellation();
  };

  const acceptChurnOffer = async () => {
    setChurnOfferModalOpen(false);
    sendCancellationOfferPopupCloseEvent('discount_accepted');

    if (subscription) {
      try {
        await apiService.put<void, SubscriptionUpdateProps>('/subscriptions', {
          coupon: churnOfferCoupon,
          provider: PaymentProvider.Stripe,
        });

        sendOfferAppliedEvent(churnOfferCoupon);
      } catch (err: any) {
        Bugsnag.notify(err);
        Toast.error(t('website:shared.somethingIsWrong'));
      }
    }
  };

  const closeCancelWarningModal = () => {
    setCancelWarningModalOpen(false);
    sendEvent(
      cancelWarningModalCloseEvent({
        days_after_registration: daysAfterRegistration,
        is_cansellation_continued: false,
      }),
    );
  };

  const confirmCancellation = () => {
    setCancelWarningModalOpen(false);
    sendEvent(
      cancelWarningModalCloseEvent({
        days_after_registration: daysAfterRegistration,
        is_cansellation_continued: true,
      }),
    );
    navigateToCancellation();
  };

  const reactivateSubscription = async () => {
    setLoading(true);

    const success = await apiService
      .put<any, SubscriptionUpdateProps>('/subscriptions', {
        provider: PaymentProvider.Stripe,
        active: true,
      })
      .catch(() => false);

    if (success) {
      sendEvent(
        cancelledReactivatedEvent({
          campaign_name: subscription.planInfo.campaign ?? '',
          web_plan_name: upperCaseFirstLetter(membership ?? ''),
          subscription_period: billingInterval,
          trial_days: leftTrialDays,
          web_trial_end: trialEndDate,
          web_plan_price: planPrice * 100,
          web_code_duration:
            (discountDuration?.type === 'repeating' ? discountDuration.months : discountDuration?.type) ?? '',
          web_discounted_plan_price: hasDiscountedPrice ? discountedPrice * 100 : null,
        }),
      );
    }

    setLoading(false);
  };

  const changeSubscription = () => {
    navigate(routes.SUBSCRIPTION.CHANGE);
  };

  return (
    <>
      <SubscriptionInfoCard
        isLoading={isLoading}
        onChangeSubscriptionClick={changeSubscription}
        onReactivateSubscriptionClick={reactivateSubscription}
        onCancelSubscriptionClick={cancelSubscription}
      />

      {isCancelWarningModalOpen && (
        <Suspense fallback={null}>
          <CancelWarningModal
            isLoading={isLoading}
            onConfirmCancel={confirmCancellation}
            onClose={closeCancelWarningModal}
          />
        </Suspense>
      )}

      {isChurnOfferModalOpen && (
        <Suspense fallback={null}>
          <ChurnOfferModal
            churnOffers={churnOffers}
            isTrialUser={isTrialUser}
            billingInterval={billingInterval}
            onAccept={acceptChurnOffer}
            onClose={closeChurnOfferModal}
          />
        </Suspense>
      )}
    </>
  );
};

export default StripeProvider;
