import dayjs from 'dayjs';
import { includes, isEmpty, orderBy } from 'lodash';
import Pagination from 'rc-pagination';
import { Fragment, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { albertCommon as albertCommonInstance } from 'services';
import { useLocale } from 'store/hooks';
import type { TransformedProgress } from 'types';

import { Icon } from '@albert/shared/components';
import { DATE_FORMAT } from '@albert/shared/constants';

import { SleepImage, StarImage, Exercise } from '../..';
import styles from './styles.module.scss';

interface Props {
  childName?: string;
  parentReportEnabled?: boolean;
  isJunior?: boolean;
  isNoChildren?: boolean;
  progress: TransformedProgress;
}

const PAGE_SIZE = 3;

const Progress = ({ progress, childName, parentReportEnabled, isJunior, isNoChildren }: Props) => {
  const { t } = useTranslation('parent');
  const locale = useLocale();
  const [page, setPage] = useState(1);
  const albertCommon = albertCommonInstance();

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  // If user has BAS subscription
  if (!parentReportEnabled) {
    return (
      <div className={styles.progressCard}>
        <h2 className={styles.cardTitle}>{t('parentReport.progress.title')}</h2>
        <div className={styles.progress}>
          <div className={styles.head}>
            <Icon name="calendar" size={18} />
            {t('parentReport.progress.today')}
          </div>
          <div className={styles.noProgress}>
            <StarImage />
            <h3>{t('parentReport.progress.basABTest')}</h3>
          </div>
        </div>
      </div>
    );
  }

  // If user has no Children
  if (isNoChildren) {
    return (
      <div className={styles.progressCard}>
        <h2 className={styles.cardTitle}>{t('parentReport.progress.title')}</h2>
        <div className={styles.progress}>
          <div className={styles.head}>
            <Icon name="calendar" size={18} />
            {t('parentReport.progress.today')}
          </div>
          <div className={styles.noProgress}>
            <StarImage />
            <h3>{t('parentReport.history.noChildren')}</h3>
          </div>
        </div>
      </div>
    );
  }

  // If user has a Teen App
  if (!isJunior) {
    return (
      <div className={styles.progressCard}>
        <h2 className={styles.cardTitle}>{t('parentReport.progress.title')}</h2>
        <div className={styles.progress}>
          <div className={styles.head}>
            <Icon name="calendar" size={18} />
            {t('parentReport.progress.today')}
          </div>
          <div className={styles.noProgress}>
            <SleepImage />
            <h3>{t('parentReport.history.teen')}</h3>
          </div>
        </div>
      </div>
    );
  }

  // If user has PLUS subscription but no activity in App
  if (isEmpty(progress)) {
    return (
      <div className={styles.progressCard}>
        <h2 className={styles.cardTitle}>{t('parentReport.progress.title')}</h2>
        <div className={styles.progress}>
          <div className={styles.head}>
            <Icon name="calendar" size={18} />
            {t('parentReport.progress.today')}
          </div>
          <div className={styles.noProgress}>
            <SleepImage />
            <h3>
              {t('parentReport.noData', {
                name: childName,
              })}
            </h3>
          </div>
        </div>
      </div>
    );
  }

  // Default render — if user has PLUS subscription and has activity in App
  const getNeededDates = (progress: TransformedProgress) => {
    const allDates = Object.keys(progress).map((item) => dayjs(item).format(DATE_FORMAT[locale]));

    allDates.push(dayjs().format(DATE_FORMAT[locale]));
    allDates.push(dayjs().add(-1, 'day').format(DATE_FORMAT[locale]));

    return orderBy(
      allDates.filter((value, index, self) => {
        return value && self.indexOf(value) === index;
      }),
      [],
      ['desc'],
    );
  };

  const renderProgress = (date: string) => {
    if (!isEmpty(progress[date])) {
      return Object.entries(progress[date]).map(([blockName, data], index: number) => {
        let exercise;
        try {
          exercise = albertCommon.content.getExercise(Object.keys(data)[0]);
        } catch {
          return null;
        }

        const level = exercise.levelId;
        const originalExerciseName = albertCommon.content.getBlock(exercise.blockId).translation.title;

        const finishedScore = Math.floor(
          Object.values(data).reduce((acc, i) => (i !== 0 ? acc + i : acc), 0) / Object.values(data).length,
        );

        return (
          <Exercise
            key={index}
            isFinished={!includes(Object.values(data), 0)}
            isGood={finishedScore > 49}
            progress={`${finishedScore}%`}
            level={`${t('parentReport.progress.level')} ${level}`}
            exerciseName={blockName}
            originalExerciseName={originalExerciseName}
            chapters={Object.values(data)}
          />
        );
      });
    } else {
      return (
        <div className={styles.noExercises}>
          {date === dayjs().format(DATE_FORMAT[locale])
            ? t('parentReport.progress.noActivityToday', {
                name: childName,
              })
            : t('parentReport.progress.noActivityYesterday', {
                name: childName,
              })}
        </div>
      );
    }
  };

  return (
    <div className={styles.progressCard}>
      <h2 className={styles.cardTitle}>{t('parentReport.progress.title')}</h2>
      <div className={styles.progress}>
        {getNeededDates(progress)
          .slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)
          .map((date, index) => (
            <Fragment key={index}>
              <div className={styles.head}>
                <Icon name="files_copy" size={15} />
                {date === dayjs().format(DATE_FORMAT[locale])
                  ? t('parentReport.progress.today')
                  : date === dayjs().add(-1, 'day').format(DATE_FORMAT[locale])
                  ? t('parentReport.progress.yesterday')
                  : date}
              </div>
              <div className={styles.exercises}>{renderProgress(date)}</div>
            </Fragment>
          ))}
        <Pagination
          className={styles.rcPagination}
          onChange={handleChangePage}
          current={page}
          hideOnSinglePage
          showTitle={false}
          total={Math.ceil(getNeededDates(progress).length / PAGE_SIZE) * 10}
          showLessItems
        />
      </div>
    </div>
  );
};

export default memo(Progress);
