export default {
  HOME: '/',
  LOGIN: '/login',
  NOT_FOUND: '/404',
  UNDER_CONSTRUCTION: '/under-construction',
  PARENT_REPORTS: '/reports',
  START_GUIDE: '/startguide',
  WORKSHEETS: '/worksheets',
  TROPHIES: '/diplomas-and-trophies',
  REFER_FRIEND: '/refer-friends',
  FORGOT_PASSWORD: '/forgot-password',
  ACCOUNT_DELETED: '/delete-account',
  ARTICLES: {
    ROOT: '/articles',
    ARTICLE: '/articles/:slug',
    UPDATE: '/articles/:updates/:slug',
  },
  MANAGE_CHILDREN_PROFILES: {
    ROOT: '/manage-profiles',
    ADD: '/manage-profiles/add',
    EDIT: (id: string | number = ':id') => `/manage-profiles/${id}/edit`,
  },
  SUBSCRIPTION: {
    ROOT: '/subscription',
    CANCEL: '/subscription/cancel',
    CHANGE: '/subscription/change',
    REACTIVATE: '/subscription/reactivate',
    PAYMENT_METHODS: '/subscription/payment-methods',
  },
};
