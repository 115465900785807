import { initializeApp } from '@firebase/app';
import type { User } from '@firebase/auth';
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from '@firebase/auth';
import {
  getDatabase,
  get,
  ref,
  set,
  update,
  remove,
  off,
  onValue,
  orderByChild,
  query,
  equalTo,
} from '@firebase/database';
import type { FirebaseStorage } from 'firebase/storage';
import { getStorage, ref as refFromURL, getDownloadURL } from 'firebase/storage';
import { keys } from 'lodash';
import type { FirebaseRef } from 'types';
import isEmail from 'validator/lib/isEmail';

import { Bugsnag } from '@albert/shared/services';

const app = initializeApp({
  apiKey: process.env.VITE_FIREBASE_API_KEY,
  authDomain: process.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VITE_FIREBASE_DATABASE_URL,
  projectId: process.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
});
const database = getDatabase(app);
const auth = getAuth(app);
const storage = getStorage(app);

class FirebaseService {
  read = async (path: FirebaseRef) => {
    try {
      const snap = await get(ref(database, path));
      return snap.val();
    } catch (error: any) {
      Bugsnag.notify(error);
      return null;
    }
  };

  set = async <T>(path: FirebaseRef, value: Partial<T>) => {
    try {
      await set(ref(database, path), value);
      return { success: true };
    } catch (error: any) {
      Bugsnag.notify(error);
      return { error };
    }
  };

  update = async <T>(path: FirebaseRef, value: Partial<T>) => {
    try {
      await update(ref(database, path), value);
      return { success: true };
    } catch (error: any) {
      Bugsnag.notify(error);
      return { error };
    }
  };

  remove = async (path: FirebaseRef) => {
    return remove(ref(database, path));
  };

  query = async <T>(path: string, query: Record<string, any>): Promise<(T & { _id: string })[]> => {
    try {
      let ret: any = ref(database, path);

      if (Object.keys(query).length > 1) {
        await Promise.reject('Cannot have more one query');
      }

      for (const [key, value] of Object.entries(query)) {
        ret = ret.orderByChild(key).equalTo(value);
      }

      const snapshot = await ret.once('value');
      const data = snapshot.val();

      return keys(data).map((it) => ({ ...data[it], _id: it }));
    } catch {
      return [];
    }
  };

  queryOn = (path: string, queryProperties: Record<string, any>, callback: (data: any) => void): void => {
    let databaseRef = query(ref(database, path));

    if (Object.keys(queryProperties).length > 1) {
      throw Error('Cannot have more one query');
    }

    try {
      for (const [key, value] of Object.entries(queryProperties)) {
        databaseRef = query(ref(database, path), orderByChild(key), equalTo(value));
      }

      onValue(databaseRef, (snap) => {
        const value = snap.val();

        const data = keys(value).map((it) => ({ ...value[it], _id: it }));

        callback(data);
      });
    } catch (e) {
      return;
    }
  };

  on = (path: string, callback: (data: any) => void) => {
    onValue(ref(database, path), (data) => callback(data.val()));
  };

  off = (path: string) => {
    off(ref(database, path));
  };

  signIn = async (props: { username?: string; password?: string; token?: string }) => {
    const { username, password, token } = props;

    try {
      if (username && isEmail(username) && password) {
        return signInWithEmailAndPassword(auth, username, password);
      } else if (token) {
        return signInWithCustomToken(auth, token);
      }

      throw new Error('Invalid credentials.');
    } catch (err: any) {
      Bugsnag.notify(err);
      throw err;
    }
  };

  signOut = () => {
    return signOut(auth);
  };

  onAuthStateChanged = (cb: (user: User | null) => void) => {
    return onAuthStateChanged(auth, cb);
  };

  updatePassword = async (props: { email: string; oldPassword: string; newPassword: string }) => {
    if (!auth.currentUser) {
      throw new Error('Tried to update user but no user was found.');
    }

    const credential = EmailAuthProvider.credential(props.email, props.oldPassword);
    await reauthenticateWithCredential(auth.currentUser, credential);

    return updatePassword(auth.currentUser, props.newPassword);
  };

  downloadFileFromUrl = (props: { url: string; storage?: FirebaseStorage }) => {
    return getDownloadURL(refFromURL(props.storage ?? storage, props.url));
  };

  get currentUser() {
    return auth.currentUser;
  }

  private getStorageWithName(name: string) {
    return getStorage(app, `gs://${name}`);
  }

  get profilePictureStorage() {
    return this.getStorageWithName(`${process.env.VITE_FIREBASE_PROJECT_ID}-user-content`);
  }
}

export default new FirebaseService();
