import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { CampaignInfo } from '@albert/shared/types';

import type { SubscriptionData } from '../../types';

export interface SubscriptionReducer {
  campaignInfo?: CampaignInfo;
  subscription?: SubscriptionData;
}

export const initialState: SubscriptionReducer = {
  campaignInfo: undefined,
  subscription: undefined,
};

const reducer = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setCampaignInfo: (state, action) => {
      state.campaignInfo = action.payload;
    },
    setSubscription: (state, action: PayloadAction<{ receipt?: SubscriptionData }>) => {
      state.subscription = action.payload.receipt;
    },
  },
});

export const actions = reducer.actions;

export default reducer.reducer;
