import type { UtmData } from '../../website/types/index';
import type { AbTestsData } from '../constants';
import type { LocalesUpper } from './locales';
import type { PaymentProvider } from './payment';
import type { Currencies, Membership } from './subscriptions';

export enum PaymentIntentAction {
  UpdateCard = 'UpdateCard',
  Activate = 'Activate',
  Reactivate = 'Reactivate',
  Checkout = 'Checkout',
}

export interface CreateUserRequest {
  email: string;
  password: string;
  origin: string;
  country: LocalesUpper;
  abTests?: AbTestsData;
}

export interface CreateUserResponse {
  token: string;
  userId: string;
}

export interface CreateCheckoutSessionRequest {
  provider: PaymentProvider;
  campaign: string;
  plan: Membership;
  priceId: string;
  metaData: {
    action: PaymentIntentAction;
    discountText: string;
    campaign: string;
    promoCode?: string;
    adtractionToken?: string;
    referralCode?: string;
    utm?: UtmData;
    facebook?: {
      currency: Currencies;
      fbp: any;
      fbc: any;
      url: string;
      type: Membership;
      value: string;
    };
  };
}

export interface CreateCheckoutSessionResponse {
  token: string;
}
