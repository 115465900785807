import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { ChildProgressType, ChildType, Parent, ParentData, PaymentMethod } from '../../types';

export interface UserReducer {
  user?: Parent;
  userData?: ParentData;
  children?: ChildType[];
  childrenProgress?: ChildProgressType[];
  paymentMethods?: PaymentMethod[];
}

export const initialState: UserReducer = {
  user: undefined,
  userData: undefined,
  children: undefined,
  childrenProgress: undefined,
  paymentMethods: undefined,
};

const reducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<Parent>) => {
      state.user = action.payload;
    },
    setUserData: (state, action: PayloadAction<ParentData>) => {
      state.userData = { ...state.userData, ...action.payload };
    },
    setChildren: (state, action: PayloadAction<ChildType[]>) => {
      state.children = action.payload;
    },
    setChildrenProgress: (state, action: PayloadAction<ChildProgressType[]>) => {
      state.childrenProgress = action.payload;
    },
    setPaymentMethods: (state, action: PayloadAction<PaymentMethod[]>) => {
      state.paymentMethods = action.payload;
    },
  },
});

export const actions = reducer.actions;

export default reducer.reducer;
