import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ChurnOffersStructureType } from 'types';

import { ReactComponent as HappyMatchi } from '@albert/shared/assets/svg/happy_matchi.svg';
import { Button } from '@albert/shared/components';

import styles from './styles.module.scss';

interface Props {
  offer: ChurnOffersStructureType;
  onClose: () => void;
  onAccept: () => void;
}

const CancellationOffer = ({ offer, onClose, onAccept }: Props) => {
  const { t } = useTranslation('parent');

  return (
    <div className={styles.cancellationOffer}>
      <div className={styles.image}>
        <HappyMatchi />
      </div>

      <>
        <h1>{offer?.offerTitle}</h1>
        <p>{offer?.offerText}</p>
        <h3>{offer?.offerSubtitle}</h3>
      </>

      <div className={styles.buttons}>
        <Button onClick={onAccept}>{t('preventChurn.okButton')}</Button>
        <Button theme="secondary" onClick={onClose}>
          {t('preventChurn.skipButton')}
        </Button>
      </div>
    </div>
  );
};

export default memo(CancellationOffer);
