import type { EventDestination, Event as EventInteface } from '../types/events';

class Event implements EventInteface {
  public readonly timestamp: string;

  constructor(public name: string, public targets: EventDestination[], public data?: Record<string, any>) {
    this.timestamp = new Date().toISOString();
  }
}

export default Event;
