import CN from 'clsx';
import type { AriaRole } from 'react';

import { useLoadImage } from '../../hooks';
import styles from './styles.module.scss';

export interface Props {
  src: string;
  srcSet?: string;
  altText?: string;
  role?: AriaRole;
  loading?: 'eager' | 'lazy';
  imageType?: 'image/webp' | 'image/png' | 'image/jpeg' | 'image/svg+xml';
  className?: string;
  classNamePicture?: string;
  fadeIn?: boolean;
  draggable?: boolean;
}

const Image = ({
  src,
  srcSet,
  altText,
  role,
  loading,
  imageType,
  draggable,
  className,
  classNamePicture,
  fadeIn,
}: Props) => {
  const { isLoadedImage, supportsLoading, onImageLoad } = useLoadImage({
    fadeInOnLoad: fadeIn,
  });

  const fadeInIfSupportsLoading = fadeIn && supportsLoading && !isLoadedImage;

  const imageElement = (
    <img
      loading={loading || 'lazy'}
      className={CN(className, !supportsLoading ? 'lazyload' : undefined)}
      data-src={supportsLoading ? undefined : src}
      src={supportsLoading ? src : undefined}
      style={{ opacity: fadeInIfSupportsLoading ? 0 : 1 }}
      alt={altText}
      title={altText}
      role={role || 'presentation'}
      draggable={draggable}
      onLoad={onImageLoad}
    />
  );

  return (
    <>
      {srcSet ? (
        <picture className={CN(styles.rootPicture, classNamePicture)}>
          <source
            data-srcset={supportsLoading ? undefined : srcSet}
            srcSet={supportsLoading ? srcSet : undefined}
            type={imageType}
          />
          {imageElement}
        </picture>
      ) : (
        imageElement
      )}
    </>
  );
};

export default Image;
