import { sendEvent } from 'api';
import CN from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigService } from 'services';
import { logoutEvent } from 'services/analyticsEvents';
import { setAuthInitialized } from 'store/app/thunks';
import { useAppDispatch } from 'store/hooks';
import { AuthenticationStatus } from 'types';
import { signOut } from 'utils';

import { WEBSITE_ROUTES as ROUTES } from '@albert/shared/constants';
import { useMediaMatch } from '@albert/shared/hooks';

import NavigationDesktop from './NavigationDesktop';
import NavigationMobile from './NavigationMobile';
import styles from './styles.module.scss';

export interface HeaderCommonProps {
  isOpenMenu: boolean;
  links: {
    label: string;
    href: string;
  }[];
  onSignOutClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  onToggleMenuClick: () => void;
}

const Header = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['parent', 'website']);
  const isMobile = useMediaMatch('(max-width: 1024px)');
  const [isOpenMobileMenu, setMobileMenuOpen] = useState<boolean>(false);
  const websiteUrl = ConfigService.get('websiteUrl');

  const links = [
    {
      label: t('website:shared.links.subjects'),
      href: `${websiteUrl}${ROUTES.SUBJECTS.ROOT}`,
    },
    {
      label: t('website:shared.links.faq'),
      href: `${websiteUrl}${ROUTES.FAQ}`,
    },
  ];

  useEffect(() => {
    const documentBody = document.body;

    if (!isOpenMobileMenu) {
      documentBody.classList.remove(styles.bodyLockedScroll);
    } else {
      window.scrollTo(0, 0);
      documentBody.classList.add(styles.bodyLockedScroll);
    }
  }, [isOpenMobileMenu]);

  const handleToggleMenu = () => {
    setMobileMenuOpen((prev) => !prev);
  };

  const handleSignOut = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    dispatch(setAuthInitialized(AuthenticationStatus.Initializing));
    await sendEvent(logoutEvent());
    await signOut();
  };

  const headerProps = {
    links,
    isOpenMenu: isOpenMobileMenu,
    onToggleMenuClick: handleToggleMenu,
    onSignOutClick: handleSignOut,
  };

  return (
    <header className={CN(styles.header, { [styles.headerWithOpenedMenu]: isOpenMobileMenu })}>
      {isMobile ? <NavigationMobile {...headerProps} /> : <NavigationDesktop {...headerProps} />}
    </header>
  );
};

export default Header;
