import { getCards } from 'api';
import { useEffect } from 'react';
import { dispatch } from 'store';
import { usePaymentMethods } from 'store/hooks';
import { setPaymentMethods } from 'store/user/thunks';

const useSetPaymentMethods = () => {
  const cards = usePaymentMethods();

  useEffect(() => {
    if (!cards) {
      (async () => {
        const data = await getCards();
        dispatch(setPaymentMethods(data));
      })();
    }
  }, [cards]);
};

export default useSetPaymentMethods;
