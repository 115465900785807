import type { CancellationEventCommonProperties, ProductType } from 'types';

import { Event } from '@albert/shared/services';
import type {
  BillingInterval,
  Locales,
  MembershipsUppercase,
  MixpanelAppName,
  MixpanelSubjectName,
} from '@albert/shared/types';
import { EventDestination } from '@albert/shared/types';

export const loginEvent = () =>
  new Event('logga_in_select', [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse]);

export const logoutEvent = () =>
  new Event('logga_ut_select', [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse]);

export const reactivationCampaignSuccessfulRedirectEvent = (data: { campaign_name: string }) =>
  new Event(
    'reactivation_campaign_successful_redirect',
    [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse],
    data,
  );

export const parentReportPageSelectEvent = () =>
  new Event('mina_barns_select', [EventDestination.Mixpanel, EventDestination.ClickHouse]);

export const homePageSelectEvent = () =>
  new Event('home_page_select', [EventDestination.Mixpanel, EventDestination.ClickHouse]);

export const startguidePageSelectEvent = () =>
  new Event('startguide_select', [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse]);

export const articlesPageSelectEvent = () =>
  new Event('articles_page_select', [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse]);

export const worksheetPageSelectEvent = () =>
  new Event('worksheet_page_select', [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse]);

export const subscriptionPageSelectEvent = () =>
  new Event('subscription_page_select', [EventDestination.Mixpanel, EventDestination.ClickHouse]);

export const manageProfilesPageSelectEvent = () =>
  new Event('manage_profiles_page_select', [EventDestination.Mixpanel, EventDestination.ClickHouse]);

export const progressPageSelectEvent = () =>
  new Event('progress_page_select', [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse]);

export const referFriendsPageSelectEvent = () =>
  new Event('referral_page_select', [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse]);

export const articleSelectEvent = (data: {
  mina_barns_article_name: string;
  article_select_source: 'dashboard' | 'article_page';
  mina_barns_article_id: string;
}) =>
  new Event(
    'mina_barns_article_select',
    [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse],
    data,
  );

export const worksheetSelectEvent = (data: { worksheet_name: string; worksheet_level: string }) =>
  new Event('worksheet_select', [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse], data);

export const changePasswordEvent = () =>
  new Event('prenumeration_change_password', [
    EventDestination.Mixpanel,
    EventDestination.Braze,
    EventDestination.ClickHouse,
  ]);

export const cancelSubscriptionEvent = (
  data: CancellationEventCommonProperties & {
    cancel_reason: number;
    cancel_sub_reason: string;
    cancel_sub_reason_position: number;
    cancel_remark: string;
    campaign_name: string;
    locale: Locales;
    in_trial: boolean;
    has_discount: boolean;
  },
) =>
  new Event(
    'prenumeration_cancel_subscription',
    [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse],
    data,
  );

export const cancelSubscriptionButtonClickEvent = (data: {
  campaign_name: string;
  subscription_period: BillingInterval;
  plan_name: MembershipsUppercase;
  locale: Locales;
  in_trial: boolean;
  has_discount: boolean;
}) =>
  new Event(
    'cancel_subscription_button_click',
    [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse],
    data,
  );

export const cancelledReactivatedEvent = (data: {
  campaign_name: string;
  subscription_period: BillingInterval;
  web_plan_name: string;
  trial_days: number;
  web_trial_end: string;
  web_plan_price: number;
  web_code_duration: string | number;
  web_discounted_plan_price: number | null;
}) =>
  new Event(
    'customer_cancelled_reactivated',
    [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse],
    data,
  );

export const progressDeletedEvent = (data: { age: number; name: string; product_name: ProductType | '' }) =>
  new Event('delete_progress', [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse], data);

export const addChildClickEvent = () =>
  new Event('mina_barns_add_child', [EventDestination.Mixpanel, EventDestination.ClickHouse]);

export const selectChildClickEvent = () =>
  new Event('mina_barns_select_child', [EventDestination.Mixpanel, EventDestination.ClickHouse]);

export const appCardClickEvent = (data: { product_type: 'parent_portal'; product_name: MixpanelAppName }) =>
  new Event('app_card_clicked', [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse], data);

export const downloadAppLinkClickEvent = (data: { product_type: 'parent_portal'; product_name: MixpanelAppName }) =>
  new Event(
    'download_link_clicked',
    [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse],
    data,
  );

export const discountPopUpCloseEvent = (
  data: CancellationEventCommonProperties & {
    discount_popup_status: 'discount_accepted' | 'discount_cancelled';
    discount_type: string;
    cancel_reason: number;
    cancel_sub_reason: string;
    cancel_sub_reason_position: number;
  },
) =>
  new Event('discount_popup', [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse], data);

export const cancellationOfferPopupCloseEvent = (
  data: CancellationEventCommonProperties & {
    discount_popup_status: 'discount_accepted' | 'discount_cancelled';
    discount_type: string;
  },
) =>
  new Event(
    'cancellation_offer_popup',
    [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse],
    data,
  );

export const cancellationOfferAppliedEvent = (
  data: CancellationEventCommonProperties & {
    discounted_price: number;
    currency: string;
    discount_end_date: string;
  },
) =>
  new Event(
    'cancellation_offer_applied',
    [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse],
    data,
  );

export const cancelSubscriptionOffersFetchFailedEvent = () =>
  new Event('cancellation_offers_fetch_failed', [
    EventDestination.Mixpanel,
    EventDestination.Braze,
    EventDestination.ClickHouse,
  ]);

export const targetedOfferPopupEvent = (data: {
  discount_popup_status: 'discount_accepted' | 'discount_cancelled';
  discount_type: string;
  subscription_period: BillingInterval;
  plan_name: MembershipsUppercase;
  trial_end_date: string;
  next_payment_date: string;
  discounted_price: number;
  currency: string;
  full_price: number;
}) =>
  new Event(
    'targeted_offer_popup',
    [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse],
    data,
  );

export const referralPopUpCloseEvent = (data: {
  referral_popup_status: 'referral_page_open' | 'referral_popup_closed';
}) =>
  new Event('referral_popup', [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse], data);

export const upgradeSubscriptionClickEvent = (data: {
  product_type: 'parent_portal';
  subscription_period: BillingInterval;
  campaign_name: string;
  source: 'onboarding' | 'select_child' | 'home_page_badge' | 'manage_profiles_page' | 'subjects_slider';
}) =>
  new Event(
    'upgrade_subscription_clicked',
    [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse],
    data,
  );

export const showHistoricalPaymentsClickEvent = () =>
  new Event('show_historical_payments_clicked', [EventDestination.Mixpanel, EventDestination.ClickHouse]);

export const openExercisesBlockEvent = (data: { area_name: string }) =>
  new Event('progress_area_expanded', [EventDestination.Mixpanel], data);

export const parentReportPageBadgeClickEvent = (data: {
  plan_name: MembershipsUppercase;
  number_of_children: number;
}) => new Event('go_to_membership', [EventDestination.Mixpanel, EventDestination.ClickHouse], data);

export const referralCopiedEvent = (data: { referral_sender: string; referral_sender_id: string }) =>
  new Event('referral_copied', [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse], data);

export const deleteAccountEvent = (data: { account_deleted: boolean }) =>
  new Event('delete_account', [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse], data);

export const updateArticleSelectEvent = (data: {
  update_article_id: string;
  update_article_name: string;
  update_select_source: 'dashboard' | 'article_page';
}) =>
  new Event(
    'update_article_select',
    [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse],
    data,
  );

export const updatesPopupCloseEvent = (data: {
  update_article_id: string;
  update_article_name: string;
  updates_popup_type: 'updates_article_visited' | 'popup_closed';
}) =>
  new Event('updates_popup', [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse], data);

export const homePageSliderClickEvent = (data: { product_name: MixpanelAppName; subject_name: MixpanelSubjectName }) =>
  new Event(
    'home_page_slider_click_event',
    [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse],
    data,
  );

export const teenPPLinkClickEvent = (data: { source: 'dashboard' | 'trophies_page' }) =>
  new Event(
    'teenwebportal_link_clicked',
    [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse],
    data,
  );

export const ConsentSettingsChange = (data: { marketingCookies: string; analyticsCookies: string }) =>
  new Event('consent_settings', [EventDestination.ClickHouse], data);

export const cancelWarningModalCloseEvent = (data: {
  is_cansellation_continued: boolean;
  days_after_registration: number;
}) =>
  new Event(
    'trial_cancellation_warning_popup',
    [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse],
    data,
  );

export const promoCodeTabSelectedEvent = (data: {
  campaign_name: string;
  subscription_period: BillingInterval;
  plan_name: MembershipsUppercase;
}) =>
  new Event(
    'pp_promo_code_tab_selected',
    [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse],
    data,
  );

export const promoCodeAppliedEvent = (data: {
  promo_code: string;
  campaign_name: string;
  subscription_period: BillingInterval;
  plan_name: MembershipsUppercase;
}) =>
  new Event(
    'pp_promo_code_applied',
    [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse],
    data,
  );

export const promoCodeNotAppliedEvent = (data: {
  promo_code: string;
  campaign_name: string;
  subscription_period: BillingInterval;
  plan_name: MembershipsUppercase;
}) =>
  new Event(
    'pp_promo_code_not_applied',
    [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse],
    data,
  );

export const notificationsMenuOpenEvent = () =>
  new Event('notification_system_open', [
    EventDestination.Mixpanel,
    EventDestination.Braze,
    EventDestination.ClickHouse,
  ]);

export const GiftcardRedirect = (data: { giftcard: string }) =>
  new Event(
    'giftcard_successful_redirect',
    [EventDestination.ClickHouse, EventDestination.Mixpanel, EventDestination.Braze],
    data,
  );

export const GiftcardApplied = (data: {
  giftcard: string;
  subscription_period: BillingInterval;
  user_status: 'reactivated';
}) =>
  new Event('giftcard_applied', [EventDestination.ClickHouse, EventDestination.Mixpanel, EventDestination.Braze], data);

export const GiftcardNotApplied = (data: {
  giftcard: string;
  user_status: 'reactivated';
  subscription_period?: BillingInterval;
}) =>
  new Event(
    'giftcard_not_applied',
    [EventDestination.ClickHouse, EventDestination.Mixpanel, EventDestination.Braze],
    data,
  );

export const changePaymentMethodsClickEvent = (data: {
  has_default_card: boolean;
  is_default_card_expired: boolean;

  saved_cards_number: number;
}) =>
  new Event(
    'change_card_details_click',
    [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse],
    data,
  );

export const addPaymentMethodClickEvent = (data: {
  has_default_card: boolean;
  is_default_card_expired: boolean;
  saved_cards_number: number;
}) =>
  new Event('add_card_click', [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse], data);

export const setDefaultPaymentMethodClickEvent = (data: { saved_cards_number: number; old_card_expired: boolean }) =>
  new Event(
    'set_default_card_click',
    [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse],
    data,
  );

export const deletePaymentMethodClickEvent = (data: { saved_cards_number: number; is_expired: boolean }) =>
  new Event(
    'delete_card_click',
    [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse],
    data,
  );

export const reactivationPlanSelectEvent = (data: {
  plan_name: MembershipsUppercase;
  subscription_period: BillingInterval;
  campaign_name: string;
  has_saved_cards: boolean;
}) =>
  new Event(
    'reactivation_plan_select',
    [EventDestination.Mixpanel, EventDestination.Braze, EventDestination.ClickHouse],
    data,
  );
