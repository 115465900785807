import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getState } from 'store';

import type { Locales } from '@albert/shared/types';
import { getLocaleFromUrl, isProduction } from '@albert/shared/utils';

import Config from './config';

class LocaleService {
  static init = async (): Promise<Locales> => {
    const storedLocale = getState().app.locale;
    const locale = isProduction() || !storedLocale ? getLocaleFromUrl() : storedLocale;
    document.documentElement.lang = locale;

    const localeResources = await LocaleService.importLocaleResources(locale);

    await i18n.use(initReactI18next).init({
      debug: process.env.VITE_ENVIRONMENT !== 'production',
      lng: locale,
      ns: ['website', 'parent'],
      defaultNS: 'website',
      resources: {
        [locale]: localeResources,
      } as const,
      compatibilityJSON: 'v3',
      interpolation: {
        escapeValue: false,
      },
      fallbackLng: false,
      saveMissing: true,
      react: {
        defaultTransParent: 'div',
        transEmptyNodeValue: '',
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b', 'a', 'Link'],
      },
    });

    i18n.on('missingKey', (a, _, c) => console.error(`I18N: ${c} is missed in ${a} locale`));

    Config.initialize(locale);

    return locale;
  };

  static setLocale = async (locale: Locales) => {
    const localeResources = await LocaleService.importLocaleResources(locale);
    for (const [ns, nsResources] of Object.entries(localeResources)) {
      i18n.addResourceBundle(locale, ns, nsResources);
    }

    await i18n.changeLanguage(locale);

    Config.initialize(locale);
  };

  private static importLocaleResources = async (locale: Locales) => {
    return {
      parent: await import(`../../../shared/locales/parent/${locale}/index.json`),
      website: await import(`../../../shared/locales/website/${locale}/index.json`),
    };
  };
}

export default LocaleService;
