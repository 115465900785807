import { Apps } from 'albert-common';
import { SideMenu, Header } from 'components';
import dayjs from 'dayjs';
import { useScrollTop, useArticles, useFirebaseUser } from 'hooks';
import { lazy, Suspense, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import type { LinkProps } from 'react-router';
import { Outlet, useNavigate } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { albertCommon as albertCommonInstance, ConfigService } from 'services';
import type { RootState } from 'store';
import { setAppsData, setNotifications } from 'store/app/thunks';
import { useAppDispatch, useLocale, useSubscription, useUserData } from 'store/hooks';
import { AuthenticationStatus, EmailLinkOfferParam } from 'types';
import { signOut, getJuniorLocale, getSanitizedRedirectPath } from 'utils';

import { Footer } from '@albert/shared/components';
import { EXTERNAL_LINK_ATTRIBUTES } from '@albert/shared/constants';
import { Cookie } from '@albert/shared/services';
import { BillingInterval } from '@albert/shared/types';

import styles from './styles.module.scss';

const UpdatesModal = lazy(() => import('components/common/UpdatesModal'));
const OfferModal = lazy(() => import('components/common/OfferModal'));
const DevsToolbar = lazy(() => import('components/common/DevsToolbar'));

const AppLayout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const locale = useLocale();
  const subscription = useSubscription();
  const userId = useSelector(({ user }: RootState) => user.user?.userId);
  const usedApps = useSelector(({ user }: RootState) => user.userData?.usedApps);
  const containerRef = useRef<HTMLDivElement>(null);
  useScrollTop({ forwardedRef: containerRef });
  const albertCommon = albertCommonInstance();

  const app = useSelector(({ app }: RootState) => app);
  const userData = useUserData();
  const user = useFirebaseUser();
  const { updateArticles } = useArticles(true);

  const lastUpdateArticle = updateArticles?.[0];
  const lastSeenUpdateArticleId = userData?.parentPortal?.lastSeenUpdateId;
  const hasQuarterOrSemiAnnualBilling =
    subscription?.billingInterval === BillingInterval.Quarterly ||
    subscription?.billingInterval === BillingInterval.SemiAnnual;

  const hasDiscount = subscription && subscription.planInfo.discountedPrice < subscription.planInfo.price;
  const isRegisteredMoreThanADayAgo = !!dayjs().diff(dayjs(user?.metadata.creationTime), 'day');
  const hasOfferInStorage = !!(
    sessionStorage.getItem(EmailLinkOfferParam.Code) || sessionStorage.getItem(EmailLinkOfferParam.CampaignName)
  );

  const showUpdatesModal = lastUpdateArticle?.id && lastUpdateArticle.id !== lastSeenUpdateArticleId;

  const showOfferModal =
    app.authInitialized !== AuthenticationStatus.Initializing &&
    userData?.parentPortal?.wasLoggedInOnce &&
    !hasOfferInStorage &&
    isRegisteredMoreThanADayAgo &&
    subscription &&
    !hasDiscount &&
    !hasQuarterOrSemiAnnualBilling &&
    usedApps?.junior === false &&
    usedApps.teen === false;

  const checkCookieAuth = async () => {
    if (!Cookie.get('auth')?.userId) {
      await signOut();
    }
  };

  useEffect(() => {
    (async () => {
      await checkCookieAuth();
    })();

    const redirectPath = getSanitizedRedirectPath(sessionStorage.getItem(EmailLinkOfferParam.Redirect) ?? '');

    if (redirectPath) {
      navigate(redirectPath);
    }
  }, []);

  useEffect(() => {
    const websiteLogin = setInterval(checkCookieAuth, 1000);

    dispatch(setNotifications());

    if (!app.appsData) {
      dispatch(setAppsData(locale));
    }

    (async () => {
      if (userId) {
        const juniorLocale = getJuniorLocale(locale);

        await Promise.all([
          albertCommon.content.loadContent(juniorLocale),
          albertCommon.translation.loadLanguage(Apps.junior, juniorLocale),
        ]);
      }
    })();

    return () => {
      clearInterval(websiteLogin);
    };
  }, [userId, dispatch]);

  // TODO: Uncomment when we will need some alert to show
  // const [showAlert, setShowAlert] = useState(false);
  // const isAlertClosedCookies = useCookies('alert_close');

  // const onAlertClose = (itemName: string, value: string) => {
  //   document.cookie = `${itemName}=` + value;
  //   setShowAlert(false);
  // };

  return (
    <div ref={containerRef}>
      {/* {showAlert && (
        <div className={styles.alert}>
          <Icon name="info_white" size={20} />
          // TODO: Add allert text
          <div className={styles.cross} onClick={() => onAlertClose('alert_close', 'true')}>
            <Icon name="close_cross_icon" size={16} fill="var(--white)" />
          </div>
        </div>
      )} */}
      <ToastContainer />
      <Suspense fallback={null}>
        <DevsToolbar />
      </Suspense>
      <Header />
      <div className={styles.content}>
        <SideMenu />
        <div className={styles.container}>
          <Outlet />
        </div>
      </div>
      <Footer Link={Link} websitePrefix={ConfigService.get('websiteUrl')} />

      {showOfferModal && !showUpdatesModal && (
        <Suspense fallback={null}>
          <OfferModal />
        </Suspense>
      )}

      {showUpdatesModal && !showOfferModal && (
        <Suspense fallback={null}>
          <UpdatesModal lastUpdateArticle={lastUpdateArticle} />
        </Suspense>
      )}
    </div>
  );
};

const Link = ({ to, children, ...rest }: LinkProps) => (
  <a href={`${ConfigService.get('websiteUrl')}${to}`} {...rest} {...EXTERNAL_LINK_ATTRIBUTES}>
    {children}
  </a>
);

export default AppLayout;
