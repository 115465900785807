import CN from 'clsx';

import styles from './styles.module.scss';

type LabelType = {
  title: string;
  theme?: 'default' | 'blue' | 'violet' | 'red' | 'transparent' | 'carnation' | 'green';
  className?: string;
};

const Label = ({ title, theme = 'default', className = '' }: LabelType) => {
  const modifyTheme = CN(styles.label, styles[theme], { [className]: className });
  return <div className={modifyTheme}>{title}</div>;
};

export default Label;
