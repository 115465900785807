import { useTranslation } from 'next-i18next';
import { memo } from 'react';

import { Membership, Subject } from '../../types';
import { sortSubjects } from '../../utils';
import AdvantageItem from './AdvantageItem';
import styles from './styles.module.scss';

interface Props {
  membership: Membership;
  subjects: Subject[];
}

const AdvantagesList = ({ membership, subjects }: Props) => {
  const { t } = useTranslation('website');
  const sortedSubjects = sortSubjects(subjects);
  const subjectsList: string[] = sortedSubjects.map((subject) => {
    if (subject.includes(Subject.math) || subject.includes(Subject.jaramba) || subject.includes(Subject.holyOwly))
      return '';
    return t(`shared.apps.tags.${subject}` as any);
  });
  const hasMathSubject = subjects.some((subject) => subject.includes(Subject.math));

  if (hasMathSubject) {
    subjectsList.unshift(t('shared.apps.tags.math'));
  }

  const advantages: Record<Membership, string[]> = {
    [Membership.Bas]: [
      t('shared.subscription.plan.tag.oneChild'),
      subjects.some((subject) => subject.includes(Subject.math)) ? t('shared.apps.tags.math') : '',
      t('shared.subscription.plan.tag.monthlyReport'),
    ],
    [Membership.Standard]: [
      t('shared.subscription.plan.tag.oneChild'),
      ...subjectsList,
      t('shared.subscription.plan.tag.parentReport'),
    ],
    [Membership.Plus]: [
      t('shared.subscription.plan.tag.fourChildren'),
      ...subjectsList,
      subjects.includes(Subject.jaramba)
        ? `${t('shared.apps.tags.jaramba')} ${t('shared.apps.descriptions.jaramba')}`
        : '',
      subjects.includes(Subject.holyOwly)
        ? `${t('shared.apps.tags.holy-owly')} ${t('shared.apps.descriptions.holyowly')}`
        : '',
      t('shared.subscription.plan.tag.parentReport'),
    ],
  };

  return (
    <ul className={styles.advantagesList}>
      {advantages[membership].map((text, i) => {
        if (text) return <AdvantageItem key={`included-advantage-${i}`} text={text} />;
        return null;
      })}
    </ul>
  );
};

export default memo(AdvantagesList);
