import { sendEvent } from 'api';
import CN from 'clsx';
import { ToggleUserButton } from 'components';
import routes from 'constants/routes';
import { useHasFeature } from 'hooks';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { selectChildClickEvent, upgradeSubscriptionClickEvent } from 'services/analyticsEvents';
import type { RootState } from 'store';
import { setSelectedChild } from 'store/app/thunks';
import { useAppDispatch, useSubscription } from 'store/hooks';
import { Feature } from 'types/product';

import { useClickOutside } from '@albert/shared/hooks';

import styles from './styles.module.scss';

const ToggleUsers = () => {
  const selectedChild = useSelector((state: RootState) => state.app.selectedChild);
  const [isToggleChildOpen, setIsToggleChildOpen] = useState(false);
  const childrenData = useSelector((state: RootState) => state.user.children);
  const user = useSelector((state: RootState) => state.user.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const subscription = useSubscription();
  const menuRef = useRef<HTMLDivElement>(null);
  useClickOutside(menuRef, () => setIsToggleChildOpen(false));

  const numberOfChildren = childrenData?.length ?? 0;
  const manyChildrenEnabled = useHasFeature(Feature.ManyChildren);

  if (numberOfChildren === 0) return null;

  const getActiveChildId = () => {
    // If user has only one child -> return this child
    if (numberOfChildren === 1) return 0;

    // If user has PLUS subscription
    // and selected child -> return selected child
    // and no selected child -> return first child
    if (manyChildrenEnabled) return selectedChild ?? 0;

    // If user has BASIC or STANDARD subscription
    if (!manyChildrenEnabled) {
      // and user has selected child after downgrade -> return this child
      if (user?.activeChildrenIds?.length === 1) {
        const _activeChildIndex = childrenData?.findIndex((child) => child._id === user?.activeChildrenIds?.[0]);
        return _activeChildIndex === -1 ? 0 : _activeChildIndex;
      }

      // and user has old logic of choosing an active child -> return active child
      if (childrenData?.findIndex((child) => child.isActive === true) !== -1) {
        return childrenData?.findIndex((child) => child.isActive === true);
      }
    }

    return 0;
  };

  const selectedChildId = getActiveChildId();

  const handleToggleUser = (index: number) => {
    if (!subscription) {
      navigate(routes.SUBSCRIPTION.REACTIVATE, { replace: true });
      return;
    }

    if (index !== selectedChildId) {
      if (manyChildrenEnabled) {
        sendEvent(selectChildClickEvent());
        dispatch(setSelectedChild(index));
      } else {
        sendEvent(
          upgradeSubscriptionClickEvent({
            product_type: 'parent_portal',
            source: 'select_child',
            campaign_name: subscription.planInfo.campaign ?? '',
            subscription_period: subscription.billingInterval!,
          }),
        );

        navigate(routes.SUBSCRIPTION.CHANGE, { replace: true });
      }
    }

    setIsToggleChildOpen(!isToggleChildOpen);
  };

  return (
    <div ref={menuRef} className={styles.toggleContainer}>
      <div
        className={CN(
          styles.userToggleRow,
          { [styles.userToggleClosed]: !isToggleChildOpen },
          { [styles.userToggleDisabled]: childrenData?.length === 1 },
        )}
      >
        {childrenData?.map((item, index) => (
          <ToggleUserButton
            key={index}
            isActive={selectedChildId === index}
            name={item.name}
            userAvatar={item.profilePicture}
            onClick={() => handleToggleUser(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default ToggleUsers;
