import { AlbertCommon, Apps, Envs } from 'albert-common';

import { isProduction } from '@albert/shared/utils';

import { apiService } from './api';

interface SignedVersionResponse {
  signature: string;
}

/** NOTE: this should be updated when updating albert-common.
 * The version should be taken from https://github.com/mrAlbert-development/junior-platform/releases
 */
const version = '10.4.0';

export const albertCommonFn = () =>
  new AlbertCommon({
    environment: isProduction() ? Envs.production : Envs.sandbox,
    app: Apps.junior,
    getContentSignature: () =>
      apiService.get<SignedVersionResponse>(`/content/junior/${version}`).then(({ signature }) => signature),
  });

let albertCommonInstance: ReturnType<typeof albertCommonFn> | null = null;

export const albertCommon = () => {
  if (!albertCommonInstance) {
    albertCommonInstance = albertCommonFn();
  }
  return albertCommonInstance;
};
