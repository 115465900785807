import dayjs from 'dayjs';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocale } from 'store/hooks';
import type { JuniorContentStats, JuniorHistoryType } from 'types';
import type { MonthNumber } from 'utils';
import { createMonthlyReport, getMonthName } from 'utils';

import { Icon } from '@albert/shared/components';

import { OkayImage } from '../..';
import styles from './styles.module.scss';

type MonthlyReportProps = {
  contentStats?: JuniorContentStats;
  history?: JuniorHistoryType;
  childName?: string;
};

const MonthlyReport = ({ contentStats, history, childName }: MonthlyReportProps) => {
  const { t } = useTranslation(['parent', 'website']);
  const locale = useLocale();

  const previousMonth = dayjs().subtract(1, 'month');
  const previousMonthNumber = Number(dayjs(previousMonth).format('M')) as MonthNumber;

  if (!contentStats || !history) return <EmptyDataMessage previousMonthNumber={previousMonthNumber} />;

  const monthlyReport = createMonthlyReport({
    locale,
    contentStats,
    history,
    month: previousMonth.month(),
    year: previousMonth.year(),
  });

  if (monthlyReport.completedExercises === 0 || monthlyReport.completedExercises <= 0) {
    return <EmptyDataMessage previousMonthNumber={previousMonthNumber} childName={childName} />;
  }

  const lastBlockText = monthlyReport.lastBlock.isNew
    ? `${monthlyReport.lastBlock.name} (${t('parentReport.progress.isNewExercise')})`
    : monthlyReport.lastBlock.name;

  return (
    <div className={styles.report}>
      <h2>{t('parentReport.monthlyReport.title')}</h2>
      <div className={styles.head}>
        <Icon name="calendar" size={18} />
        {t(`website:shared.months.${getMonthName(previousMonthNumber)}`)}
      </div>
      <div className={styles.stats}>
        <div className={styles.item}>
          <h3>{t('parentReport.monthlyReport.completedExercises')}: </h3>
          <div>{monthlyReport.completedExercises}</div>
        </div>
        <div className={styles.item}>
          <h3>{t('parentReport.monthlyReport.success')}: </h3>
          <div>{monthlyReport.successRate}%</div>
        </div>
        {monthlyReport.lastBlock.name !== null && (
          <div className={styles.item}>
            <h3>{t('parentReport.monthlyReport.lastBlock')}: </h3>
            <div>{lastBlockText}</div>
          </div>
        )}
      </div>
    </div>
  );
};

type EmptyDataMessageProps = {
  previousMonthNumber: MonthNumber;
  childName?: string;
};

const EmptyDataMessage = ({ previousMonthNumber, childName }: EmptyDataMessageProps) => {
  const { t } = useTranslation(['parent', 'website']);

  return (
    <div className={styles.report}>
      <h2>{t('parentReport.monthlyReport.title')}</h2>
      <div className={styles.head}>
        <Icon name="calendar" size={18} />
        {t(`website:shared.months.${getMonthName(previousMonthNumber)}`)}
      </div>
      <div className={styles.emptyData}>
        <OkayImage />
        <h3>
          {t('parentReport.history.noDataThisWeek', {
            name: childName ?? '',
          })}
        </h3>
      </div>
    </div>
  );
};

export default memo(MonthlyReport);
