import styles from './styles.module.scss';

const SleepImage = () => {
  return (
    <div className={styles.sleepImage}>
      <span>Z</span>
      <span>Z</span>
      <span>Z</span>
    </div>
  );
};

export default SleepImage;
