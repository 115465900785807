import type { CardBrand } from '@stripe/stripe-js';
import { sendEvent } from 'api';
import CN from 'clsx';
import { CARD_BRAND_LOGOS } from 'constants/payment';
import routes from 'constants/routes';
import { useSetPaymentMethods } from 'hooks';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { addPaymentMethodClickEvent, changePaymentMethodsClickEvent } from 'services/analyticsEvents';
import { usePaymentMethods } from 'store/hooks';
import { checkIfPaymentMethodExpired } from 'utils';

import { Button } from '@albert/shared/components';

import styles from './styles.module.scss';

const CurrentPaymentMethod = () => {
  const { t } = useTranslation('parent');
  const navigate = useNavigate();
  useSetPaymentMethods();
  const cards = usePaymentMethods();

  const hasCard = (cards?.length ?? 0) > 0;
  const currentCard = cards?.find((card) => card.isDefault);
  const isExpiredCard = useMemo(
    () => checkIfPaymentMethodExpired(currentCard?.expMonth ?? 0, currentCard?.expYear ?? 0),
    [currentCard],
  );

  const navigateToPaymentMethods = () => {
    const eventParams = {
      has_default_card: !!currentCard?.isDefault,
      is_default_card_expired: isExpiredCard,
      saved_cards_number: cards?.length ?? 0,
    };

    if (hasCard) {
      sendEvent(changePaymentMethodsClickEvent(eventParams));
    } else {
      sendEvent(addPaymentMethodClickEvent(eventParams));
    }
    navigate(routes.SUBSCRIPTION.PAYMENT_METHODS);
  };

  return (
    <>
      <div className={styles.card}>
        <h2 className={CN(styles.cardTitle, styles.border)}>
          {t('subscriptionPage.subscription.general.paymentMethod')}
        </h2>

        <div className={styles.innerContainer}>
          {hasCard && (
            <div className={styles.cardDetails}>
              {CARD_BRAND_LOGOS?.[currentCard?.brand as CardBrand] && (
                <img
                  src={CARD_BRAND_LOGOS[currentCard?.brand as CardBrand]}
                  className={styles.cardBrandLogo}
                  draggable={false}
                  alt={currentCard?.brand}
                />
              )}
              <div>
                <h3 className={styles.cardNumber}>
                  <span>{currentCard?.brand}</span> ****{currentCard?.last4}
                </h3>
                <p
                  className={CN(styles.expirationDate, {
                    [styles.expired]: isExpiredCard,
                  })}
                >
                  Exp.{' '}
                  {`${(currentCard?.expMonth ?? 0) < 10 ? '0' : ''}${currentCard?.expMonth}/${currentCard?.expYear}`}
                  {isExpiredCard && ' ⚠️'}
                </p>
              </div>
            </div>
          )}

          <Button className={styles.manageCardsButton} onClick={navigateToPaymentMethods}>
            {cards?.length ? t('subscriptionPage.cardDetails.changeCardDetails') : t('paymentMethodsPage.addCard')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default memo(CurrentPaymentMethod);
