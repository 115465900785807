import CN from 'clsx';
import type { ReactElement } from 'react';
import { forwardRef } from 'react';

import Icon from '../Icon';
import styles from './styles.module.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string | ReactElement;
  shape?: 'square' | 'round';
  className?: string;
  labelClass?: string;
  fieldClass?: string;
  errorClass?: string;
  error?: string | null;
}

const Checkbox = forwardRef(
  (
    {
      label,
      shape = 'square',
      className = '',
      labelClass = '',
      fieldClass = '',
      errorClass = '',
      error = null,
      ...rest
    }: Props,
    ref: React.Ref<HTMLInputElement>,
  ) => (
    <label className={CN(styles.label, className)}>
      <div className={styles.wrapper}>
        <input
          readOnly={!rest.onChange}
          checked={rest.checked}
          className={styles.input}
          ref={ref}
          type="checkbox"
          {...rest}
        />
        <div
          className={CN(
            styles.checkbox,
            fieldClass,
            { [styles.isRound]: shape === 'round' },
            { [styles.withError]: !!error },
          )}
        >
          <Icon name="checkmark" size="80%" />
        </div>
        {label && <span className={CN(styles.text, labelClass)}>{label}</span>}
      </div>
      {error && <div className={CN(styles.error, errorClass)}>{error}</div>}
    </label>
  ),
);

export default Checkbox;
