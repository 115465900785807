import type { RefObject } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

interface Props {
  forwardedRef: RefObject<HTMLElement>;
}

export const useScrollTop = ({ forwardedRef }: Props) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (window.screen.width < 769) {
      window.scroll(0, 0);
    } else if (forwardedRef?.current) {
      forwardedRef?.current.scrollIntoView();
    }
  }, [pathname, forwardedRef]);

  return null;
};

export default useScrollTop;
