import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ChurnOffersStructureType } from 'types';
import { UserSubscriptionStatus } from 'types';

import bannerImage from '@albert/shared/assets/images/offer_modal_banner.webp';
import { Button, Modal } from '@albert/shared/components';
import type { BillingInterval } from '@albert/shared/types';

import styles from './styles.module.scss';

interface Props {
  churnOffers: ChurnOffersStructureType[];
  isTrialUser: boolean;
  billingInterval: BillingInterval;
  onClose: () => void;
  onAccept: () => void;
}

const ChurnOfferModal = ({ churnOffers, isTrialUser, billingInterval, onClose, onAccept }: Props) => {
  const { t } = useTranslation('parent');

  const getPopupText = (userStatus: UserSubscriptionStatus, billingPeriod: BillingInterval) => {
    const offer = churnOffers.find((offer) => offer.userStatus === userStatus && offer.billingPeriod === billingPeriod);

    return {
      title: offer?.offerTitle,
      description: offer?.offerText,
    };
  };

  const currentOfferText = useMemo(
    () => getPopupText(isTrialUser ? UserSubscriptionStatus.Trialing : UserSubscriptionStatus.Paying, billingInterval),
    [isTrialUser, billingInterval],
  );

  return (
    <Modal
      isOpen
      contentClass={styles.customContentContainer}
      closeButtonClass={styles.customCloseButton}
      onClose={onClose}
    >
      <div className={styles.content}>
        <h1 className={styles.title}>{currentOfferText.title}</h1>
        <p className={styles.description}>{currentOfferText.description}</p>

        <div className={styles.buttons}>
          <Button theme="secondary" className={styles.close} onClick={onClose}>
            {t('preventChurn.skipButton')}
          </Button>

          <Button className={styles.accept} onClick={onAccept}>
            {t('preventChurn.okButton')}
          </Button>
        </div>
      </div>
      <img src={bannerImage} className={styles.bannerImage} alt="" draggable={false} />
    </Modal>
  );
};

export default memo(ChurnOfferModal);
