import localforage from 'localforage';
import type { PersistedState } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import type { PersistConfig } from 'redux-persist/es/types';
import type { RootState } from 'store';

import { Cookie } from '@albert/shared/services';
import { isProduction } from '@albert/shared/utils';

const transforms = () => {
  if (!isProduction()) {
    return [];
  }

  return [
    encryptTransform({
      secretKey: 'test-key',
    }),
  ];
};

const persistConfig: PersistConfig<RootState> = {
  key: 'parent-portal',
  storage: localforage,
  transforms: transforms(),
  migrate: async (state: PersistedState) => {
    const _state = state as unknown as RootState;
    const storedUserId = _state?.user?.user?.userId;
    const cookie = Cookie.get('auth');
    const cookieUserId = cookie?.userId;
    const hasValidAuth = storedUserId && cookieUserId === storedUserId;

    if (hasValidAuth) {
      return state;
    }

    return undefined;
  },
};

export default persistConfig;
