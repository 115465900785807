import CN from 'clsx';
import type { ReactNode } from 'react';
import { forwardRef } from 'react';

import Icon from '../Icon';
import styles from './styles.module.scss';

interface Props extends React.InputHTMLAttributes<HTMLSelectElement> {
  options: Record<string, string>;
  label?: string;
  className?: string;
  labelClass?: string;
  fieldClass?: string;
  errorClass?: string;
  customIcon?: ReactNode;
  error?: string;
}

const Select = forwardRef(
  (
    {
      options,
      label = '',
      placeholder,
      required,
      disabled,
      className = '',
      labelClass = '',
      fieldClass = '',
      errorClass = '',
      customIcon,
      error,
      ...rest
    }: Props,
    ref: React.Ref<HTMLSelectElement>,
  ) => (
    <label className={CN(styles.label, className)}>
      {label && (
        <span className={CN(styles.labelText, labelClass)}>
          {label}
          {required && <span className={styles.requiredStarSymbol}>*</span>}
        </span>
      )}
      <div className={styles.relative}>
        <select
          ref={ref}
          disabled={disabled}
          className={CN(styles.select, fieldClass, { [styles.hasError]: error })}
          {...rest}
        >
          {placeholder && (
            <option disabled value="">
              {placeholder}
            </option>
          )}
          {Object.keys(options).map((key, index) => (
            <option key={index} value={key}>
              {options[key]}
            </option>
          ))}
        </select>
        {customIcon ? customIcon : <Icon size={10} className={CN(styles.icon)} name="triangle" />}
      </div>
      {error && <div className={CN(styles.error, errorClass)}>{error}</div>}
    </label>
  ),
);

export default Select;
