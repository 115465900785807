import type { SyntheticEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { isServer } from '../utils';

interface Props {
  fadeInOnLoad?: boolean;
}

const useLoadImage = (props?: Props) => {
  const [isLoadedImage, setIsLoadedImage] = useState<boolean>(false);
  const [supportsLoading, setSupportsLoading] = useState<boolean>(false);

  /** This library is necessary for Safari (it does lazy load, and loads only webp instead of both types) */
  const importLibrary = async () => {
    await import('lazysizes');
    /** @ts-ignore */
    await import('lazysizes/plugins/attrchange/ls.attrchange');
  };

  useEffect(() => {
    const HTMLImageElementPrototype = isServer ? Object : window.HTMLImageElement.prototype;

    setSupportsLoading('loading' in HTMLImageElementPrototype);
  }, []);

  useEffect(() => {
    if (!supportsLoading) {
      importLibrary();
    }
  }, [supportsLoading]);

  const onImageLoad = useCallback(
    (event: SyntheticEvent<HTMLImageElement, Event> | undefined) => {
      const imageElement = event?.currentTarget;

      if (!(event && props?.fadeInOnLoad) || !imageElement) {
        return;
      }

      setIsLoadedImage(true);

      return;
    },
    [props?.fadeInOnLoad],
  );

  return {
    isLoadedImage,
    supportsLoading,
    onImageLoad,
  };
};

export default useLoadImage;
