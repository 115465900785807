import { getNotifications } from 'api';

import { getAppsData } from '@albert/shared/api';
import type { Locales, PPActiclesCardType, PPUpdateActiclesCardType, SubjectStructureType } from '@albert/shared/types';

import type { AuthenticationStatus, CancelReasonType, ChurnOffersStructureType } from '../../types';
import type { AppThunk } from '../rootReducer';
import { actions } from './reducers';

export const setAuthInitialized =
  (status: AuthenticationStatus): AppThunk =>
  (dispatch) => {
    dispatch(actions.setAuthInitialized(status));
  };

export const setLocale =
  (locale: Locales): AppThunk =>
  (dispatch) => {
    dispatch(actions.setLocale(locale));
  };

export const setSelectedChild =
  (index: number): AppThunk =>
  (dispatch) => {
    dispatch(actions.setSelectedChild(index));
  };

export const setArticles =
  (articles: PPActiclesCardType[]): AppThunk =>
  (dispatch) => {
    dispatch(actions.setArticles(articles));
  };

export const setUpdateArticles =
  (updateArticles: PPUpdateActiclesCardType[]): AppThunk =>
  (dispatch) => {
    dispatch(actions.setUpdateArticles(updateArticles));
  };

export const setSubjects =
  (subjects: SubjectStructureType[]): AppThunk =>
  (dispatch) => {
    dispatch(actions.setSubjects(subjects));
  };

export const setChurnOffers =
  (churnOffers: ChurnOffersStructureType[]): AppThunk =>
  (dispatch) => {
    dispatch(actions.setChurnOffers(churnOffers));
  };

export const setChurnOfferCodes =
  (churnOfferCodes: string[]): AppThunk =>
  (dispatch) => {
    dispatch(actions.setChurnOfferCodes(churnOfferCodes));
  };

export const setCancelReasons =
  (cancelReasons: CancelReasonType[]): AppThunk =>
  (dispatch) => {
    dispatch(actions.setCancelReasons(cancelReasons));
  };

export const setNotifications = (): AppThunk => async (dispatch) => {
  const notifications = await getNotifications();
  dispatch(actions.setNotifications(notifications));
};

export const setAppsData =
  (locale: Locales): AppThunk =>
  async (dispatch) => {
    const appsData = await getAppsData(false, locale as Locales);
    dispatch(actions.setAppsData(appsData));
  };

export const setSidemenuCollapsed =
  (isCollapsed: boolean): AppThunk =>
  (dispatch) => {
    dispatch(actions.setSidemenuCollapsed(isCollapsed));
  };
