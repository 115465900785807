import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router';
import { PersistGate } from 'redux-persist/integration/react';
import smoothscroll from 'smoothscroll-polyfill';
import { initializeStore } from 'store';

import { Bugsnag } from '@albert/shared/services';
import { getLocaleFromUrl } from '@albert/shared/utils';

import { Navigation } from './navigation';
import { FirebaseService } from './services';
import './styles/styles.global.scss';

const { VITE_VERSION, VITE_BUGSNAG_KEY } = process.env;

const ErrorBoundary = Bugsnag.initialize(VITE_BUGSNAG_KEY!, {
  appVersion: VITE_VERSION,
  metadata: {
    locale: getLocaleFromUrl(),
  },
  onError: (event) => {
    const userId = FirebaseService.currentUser?.uid;
    event.setUser(userId);
  },
});

smoothscroll.polyfill();

const container = document.getElementById('root');

const { store, persistor } = initializeStore();

const root = createRoot(container as HTMLElement);
root.render(
  <StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter>
            <Navigation />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </StrictMode>,
);
