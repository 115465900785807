import CN from 'clsx';

import { Icon } from '@albert/shared/components';

import styles from './styles.module.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  name?: string;
  isActive?: boolean;
  userAvatar?: string | null;
}

const ToggleUserButton = ({ name, isActive, userAvatar, ...rest }: Props) => (
  <button className={CN(styles.userButton, { [styles.isActive]: isActive })} {...rest}>
    <div className={styles.avatarUser}>
      {userAvatar ? (
        <img src={userAvatar} alt="User avatar" className={styles.avatarImage} />
      ) : (
        <Icon name="no_avatar" />
      )}
    </div>
    <div className={styles.nameUser}>{name}</div>
  </button>
);

export default ToggleUserButton;
