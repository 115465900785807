import { sendEvent } from 'api';
import dayjs from 'dayjs';
import { apiService } from 'services';
import { cancellationOfferAppliedEvent } from 'services/analyticsEvents';
import { getState } from 'store';
import type { CancelReasonType, ChurnOffersStructureType } from 'types';

import { DiscountService } from '@albert/shared/services';
import { DatoCMS } from '@albert/shared/services';
import type { Locales, MembershipsUppercase } from '@albert/shared/types';
import { BillingInterval, DiscountDurationType } from '@albert/shared/types';

import { getMembershipFromProductId } from './processors';

export const getChurnOffers = async (locale: Locales): Promise<ChurnOffersStructureType[]> => {
  const data = await DatoCMS.requestContent({
    query: `
      query GetChurnOffers ($locale: SiteLocale) {
        churnOffersConfigNew (locale: $locale) {
          churnOffers {
            id
            alias
            userStatus
            billingPeriod
            offerCode {
              code
            }
            offerSubtitle
            offerText
            offerTitle
          }
        }
      }`,
    variables: { locale: DatoCMS.toISOLocale[locale] },
    isCachedEndpoint: true,
  });

  return data.churnOffersConfigNew.churnOffers;
};

export const getChurnOfferCodes = async (locale: Locales): Promise<string[]> => {
  const data = await DatoCMS.requestContent({
    query: `
      query GetChurnOffers ($locale: SiteLocale) {
        allChurnOfferCodes(locale: $locale) {
          code
        }
      }`,
    variables: { locale: DatoCMS.toISOLocale[locale] },
    isCachedEndpoint: true,
  });

  return data.allChurnOfferCodes.map((item: Record<string, string>) => item.code);
};

export const getCancelReasons = async (locale: Locales): Promise<CancelReasonType[]> => {
  const data = await DatoCMS.requestContent({
    query: `
      query GetCancelReasons ($locale: SiteLocale) {
        allCancellationReasons (locale: $locale) {
          id
          mixpanelName
          group
          reasonCode
          reasonText
          subreasonsList {
            subreasonCode
            subreasonText
            mixpanelName
          }
        }
      }`,
    variables: { locale: DatoCMS.toISOLocale[locale] },
    isCachedEndpoint: true,
  });

  return data.allCancellationReasons;
};

export const sendOfferAppliedEvent = async (promoCode: string) => {
  const subscription = getState().subscription.subscription;
  const locale = getState().user.user?.location as Locales;

  const discountService = new DiscountService(apiService);
  const discountInfo = await discountService.get({
    locale,
    promoCode,
  });

  const discountDuration = discountInfo?.duration?.type;
  let discountEndDate = null;

  const getAmountOfMonths = () => {
    switch (subscription?.billingInterval) {
      case BillingInterval.Monthly:
        return 1;
      case BillingInterval.Annual:
        return 12;
      case BillingInterval.Quarterly:
        return 3;
      case BillingInterval.SemiAnnual:
        return 6;
      default:
        return 1;
    }
  };

  if (discountDuration === DiscountDurationType.Once) {
    discountEndDate = String(dayjs(subscription?.nextPaymentDate).add(getAmountOfMonths(), 'month').toISOString());
  }
  if (discountDuration === DiscountDurationType.Repeating) {
    discountEndDate = String(
      dayjs(subscription?.nextPaymentDate)
        .add(discountInfo?.duration?.months ?? 0, 'month')
        .toISOString(),
    );
  }

  let discountedPrice = 0;

  if (subscription?.planInfo) {
    if (discountInfo?.amountOff) {
      discountedPrice = subscription.planInfo.price - discountInfo.amountOff / 100;
    }
    if (discountInfo?.percentageOff) {
      discountedPrice = subscription.planInfo.price - subscription.planInfo.price * (discountInfo.percentageOff / 100);
    }
  }

  if (subscription?.planInfo) {
    sendEvent(
      cancellationOfferAppliedEvent({
        subscription_period: subscription.billingInterval!,
        plan_name: getMembershipFromProductId(
          subscription.planInfo.productId ?? '',
        )?.toUpperCase() as MembershipsUppercase,
        trial_end_date: subscription.trialEnd ?? '',
        next_payment_date: dayjs(subscription.nextPaymentDate).toISOString() ?? '',
        discounted_price: discountedPrice * 100,
        currency: subscription.upcomingInvoice?.currency ?? '',
        full_price: subscription.planInfo.price * 100,
        discount_end_date: discountEndDate ?? '',
      }),
    );
  }
};
