import { FirebaseService } from 'services';
import ListenerService from 'services/listener';
import { getState } from 'store';
import type { FirebaseRef } from 'types';

import { Cookie } from '@albert/shared/services';

export const getFirebaseRefs = (
  userId: string = getState()?.user?.user?.userId as string,
): Record<string, FirebaseRef> => ({
  userRef: `users/${userId}`,
  userDataRef: `userData/${userId}`,
  abTestsRef: `userData/${userId}/abTests`,
  wasLoggedInOnceRef: `userData/${userId}/parentPortal/wasLoggedInOnce`,
  parentPortalRef: `userData/${userId}/parentPortal`,
  receiptValidationRef: `users/${userId}/v`,
  hasOpenedReferralModalRef: `userData/${userId}/parentPortal/hasOpenedReferralModal`,
  lastSeenUpdateId: `userData/${userId}/parentPortal/lastSeenUpdateId`,
});

export const signOut = async () => {
  const wasLoggedInOnce = !!getState().user.userData?.parentPortal?.wasLoggedInOnce;

  if (!wasLoggedInOnce) {
    await Promise.all([
      FirebaseService.set(getFirebaseRefs().wasLoggedInOnceRef, true),
      FirebaseService.set(getFirebaseRefs().hasOpenedReferralModalRef, true),
    ]);
  }

  await FirebaseService.signOut();
  ListenerService.removeAllListeners();
  Cookie.remove('auth', {
    path: '/',
  });
  Cookie.remove('pp_token');
  sessionStorage.clear();
};
