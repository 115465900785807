import CN from 'clsx';
import { forwardRef, useState } from 'react';

import { Icon } from '@albert/shared/components';

import styles from './styles.module.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  className?: string;
  labelClass?: string;
  fieldClass?: string;
  errorClass?: string;
  error?: string | null;
  button?: React.ReactNode;
}

const Input = forwardRef(
  (
    {
      label = '',
      className = '',
      labelClass = '',
      fieldClass = '',
      errorClass = '',
      value,
      type,
      disabled,
      required,
      button,
      error,
      ...rest
    }: Props,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const [show, setShow] = useState<boolean>(type !== 'password');

    const getInputType = () => {
      if (show) {
        if (type !== 'password') {
          return type;
        } else {
          return 'text';
        }
      } else {
        return 'password';
      }
    };

    const handleShowPassword = () => {
      if (!disabled) {
        setShow(!show);
      }
    };

    return (
      <label className={CN(styles.label, className)}>
        {label && (
          <span className={CN(styles.labelText, labelClass)}>
            {label}
            {required && <span className={styles.requiredStarSymbol}>*</span>}
          </span>
        )}
        <div className={styles.wrapper}>
          <input
            ref={ref}
            value={value}
            disabled={disabled}
            type={getInputType()}
            className={CN(
              styles.input,
              fieldClass,
              { [styles.withValue]: Boolean(value) },
              { [styles.withError]: error },
            )}
            {...rest}
          />
          {type === 'password' && (
            <Icon
              name={show ? 'password_shown' : 'password_hidden'}
              width={27}
              height={show ? 24 : 22}
              className={styles.icon}
              onClick={handleShowPassword}
            />
          )}
          {button}
        </div>
        {error && <span className={CN(styles.error, errorClass)}>{error}</span>}
      </label>
    );
  },
);

export default Input;
