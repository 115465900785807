import { createAddCardIntent, deleteCard, getCards, getHasReceipts, sendEvent, setCardDefault } from 'api';
import { BackButton, PaymentMethodsList } from 'components';
import { FETCH_CARDS_POLLING_INTERVAL } from 'constants/payment';
import routes from 'constants/routes';
import { useSetPaymentMethods } from 'hooks';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { apiService, Toast } from 'services';
import {
  addPaymentMethodClickEvent,
  deletePaymentMethodClickEvent,
  reactivationPlanSelectEvent,
  setDefaultPaymentMethodClickEvent,
} from 'services/analyticsEvents';
import { useAppDispatch, usePaymentMethods, useSubscription } from 'store/hooks';
import { setPaymentMethods } from 'store/user/thunks';
import type { PaymentMethod, RouterState } from 'types';
import { EmailLinkOfferParam } from 'types';
import { checkIfPaymentMethodExpired } from 'utils';

import { Payment } from '@albert/shared/components';
import { BILLING_TEXT } from '@albert/shared/constants';
import { Bugsnag } from '@albert/shared/services';
import type {
  CreateCheckoutSessionRequest,
  CreateCheckoutSessionResponse,
  BillingInterval,
  Membership,
  MembershipsUppercase,
} from '@albert/shared/types';
import { PaymentIntentAction, PaymentProvider } from '@albert/shared/types';
import { getAppliedPromoCode, getSubscriptionDiscountText, poll, upperCaseFirstLetter } from '@albert/shared/utils';

import styles from './styles.module.scss';

const PaymentMethods = () => {
  const { t } = useTranslation(['parent', 'website']);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  useSetPaymentMethods();
  const cards = usePaymentMethods();
  const subscription = useSubscription();

  const [showAddCard, setShowAddCard] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const hasCards = (cards?.length ?? 0) > 0;
  const selectedPlan: RouterState = location.state;
  const isReactivation = !!selectedPlan;
  const defaultCard = cards?.find((card) => card.isDefault);
  let hasReceipts = false;

  const isExpiredDefaultCard = useMemo(
    () => checkIfPaymentMethodExpired(defaultCard?.expMonth ?? 0, defaultCard?.expYear ?? 0),
    [defaultCard],
  );

  useEffect(() => {
    if (subscription?.provider && subscription?.provider !== PaymentProvider.Stripe) {
      navigate(-1);
    }
  }, [subscription?.provider]);

  useEffect(() => {
    if (isReactivation) {
      (async () => {
        try {
          hasReceipts = await getHasReceipts();
          sendEvent(
            reactivationPlanSelectEvent({
              campaign_name: selectedPlan.campaignName,
              plan_name: selectedPlan.membership.toUpperCase() as MembershipsUppercase,
              subscription_period: selectedPlan.billingInterval,
              has_saved_cards: !!cards?.length,
            }),
          );
        } catch (error: any) {
          Bugsnag.notify(`Failed to fetch receipts for a customer. Error — ${error}`);
        }
      })();
    }
  }, [isReactivation]);

  const handleShowAddCard = () => {
    setShowAddCard(true);
    sendEvent(
      addPaymentMethodClickEvent({
        has_default_card: !!defaultCard,
        is_default_card_expired: isExpiredDefaultCard,
        saved_cards_number: cards?.length ?? 0,
      }),
    );
  };

  const getCurrentCardId = async () => cards?.find((card) => card.isDefault)?.id ?? '';

  const navigateBack = () => {
    if (showAddCard) {
      setShowAddCard(false);
      return;
    }

    navigate(-1);
  };

  const setCardAsDefault = async (id: string) => {
    try {
      setLoading(true);

      sendEvent(
        setDefaultPaymentMethodClickEvent({
          saved_cards_number: cards?.length ?? 0,
          old_card_expired: isExpiredDefaultCard,
        }),
      );

      await setCardDefault(id);

      await poll(
        getCards,
        FETCH_CARDS_POLLING_INTERVAL,
        (data) => dispatch(setPaymentMethods(data)),
        (data: PaymentMethod[]) => !!data?.find((card) => card.id === id)?.isDefault,
      );
    } catch (err: any) {
      Bugsnag.notify(err);
      Toast.error(t('website:shared.somethingIsWrong'));
    } finally {
      setLoading(false);
    }
  };

  const deleteSelectedCard = async (id: string, isExpired: boolean) => {
    try {
      setLoading(true);

      sendEvent(
        deletePaymentMethodClickEvent({
          saved_cards_number: cards?.length ?? 0,
          is_expired: isExpired,
        }),
      );

      await deleteCard(id);

      await poll(
        getCards,
        FETCH_CARDS_POLLING_INTERVAL,
        (data) => dispatch(setPaymentMethods(data)),
        (data: PaymentMethod[]) => (data?.length ?? 0) < (cards?.length ?? 0),
      );
    } catch (err: any) {
      Bugsnag.notify(err);
      Toast.error(t('website:shared.somethingIsWrong'));
    } finally {
      setLoading(false);
    }
  };

  const refetchCardsAfterAdding = () => {
    return poll(
      getCards,
      FETCH_CARDS_POLLING_INTERVAL,
      (data) => dispatch(setPaymentMethods(data)),
      (data: PaymentMethod[]) =>
        (data?.length ?? 0) > (cards?.length ?? 0) &&
        data.some((card) => card.isDefault && !cards?.some((c) => c.id === card.id)),
    );
  };

  const onAddCardSuccess = async () => {
    try {
      setLoading(true);
      await refetchCardsAfterAdding();
    } catch (err: any) {
      Bugsnag.notify(err);
      Toast.error(t('website:shared.somethingIsWrong'));
    } finally {
      setLoading(false);
    }

    navigateBack();
  };

  const onPaymentError = (err: any) => {
    Bugsnag.notify(err);
    Toast.error(t('website:shared.somethingIsWrong'));
  };

  const onReactivationSuccess = async (hasAddedNewCard: boolean) => {
    if (hasAddedNewCard) {
      try {
        setLoading(true);
        await refetchCardsAfterAdding();
      } catch (err: any) {
        Bugsnag.notify(err);
        Toast.error(t('website:shared.somethingIsWrong'));
      }
    }

    navigate(routes.SUBSCRIPTION.ROOT);
  };

  const checkoutSession = async () => {
    try {
      const data = await createAddCardIntent();
      return data.token;
    } catch (err: any) {
      Bugsnag.notify(err);
      Toast.error(t('website:shared.somethingIsWrong'));
    }
    return '';
  };

  const reactivationCheckoutSession = useCallback(async () => {
    const res = await apiService.post<CreateCheckoutSessionResponse, CreateCheckoutSessionRequest>('/checkout?seti=0', {
      provider: PaymentProvider.Stripe,
      campaign: selectedPlan.campaignName,
      plan: selectedPlan.membership,
      priceId: selectedPlan.priceId,
      metaData: {
        action: hasReceipts ? PaymentIntentAction.Reactivate : PaymentIntentAction.Activate,
        campaign: selectedPlan.campaignName,
        discountText:
          getSubscriptionDiscountText({
            currency: selectedPlan.currency,
            amountOff: selectedPlan.amountOff,
            percentageOff: selectedPlan.percentageOff,
          }) ?? '',
        promoCode:
          getAppliedPromoCode({
            hasDiscount: selectedPlan.hasDiscount,
            planPromoCode: sessionStorage.getItem(EmailLinkOfferParam.Giftcard) || selectedPlan.promoCode || undefined,
          }) ?? undefined,
      },
    });

    return res.token;
  }, [selectedPlan, hasReceipts]);

  const getPricePerPeriod = () => {
    return t('website:shared.subscription.billing.pricePerBilling', {
      price: selectedPlan.price,
      currency: selectedPlan.currency.toUpperCase(),
      billing: t(
        `website:shared.subscription.billing.${BILLING_TEXT[selectedPlan.billingInterval as BillingInterval].short}`,
      ),
    });
  };

  const getPaymentSubtitle = useCallback(() => {
    return t('paymentMethodsPage.reactivationSubtitle', {
      plan: t(`website:shared.subscription.plan.name.${selectedPlan?.membership as Membership}`),
      price: getPricePerPeriod(),
      billing: upperCaseFirstLetter(
        t(
          `website:shared.subscription.billing.${BILLING_TEXT[selectedPlan.billingInterval as BillingInterval].billed}`,
        ),
      ),
    });
  }, [selectedPlan?.membership, selectedPlan?.price, selectedPlan?.billingInterval]);

  return (
    <div className={styles.page}>
      <div className={styles.backButtonWrapper}>
        <BackButton handleClose={navigateBack} />
      </div>

      {!showAddCard && hasCards && (
        <div className={styles.heading}>
          <h1 className={styles.title}>
            {!isReactivation ? t('paymentMethodsPage.title') : t('subscriptionPage.subscription.reactivate.title')}
          </h1>
          {isReactivation && <p className={styles.subtitle}>{getPaymentSubtitle()}</p>}
        </div>
      )}

      {(showAddCard || !hasCards) && (
        <div className={styles.addPaymentMethodContainer}>
          <h1 className={styles.titlePayment}>{t('paymentMethodsPage.addCard')}</h1>

          <Payment
            isLoading={isLoading}
            buttonText={!isReactivation ? t('general.save') : undefined}
            getClientSecret={isReactivation ? reactivationCheckoutSession : checkoutSession}
            onSuccess={() => (isReactivation ? onReactivationSuccess(true) : onAddCardSuccess())}
            onFailure={onPaymentError}
          />
        </div>
      )}

      {!showAddCard && hasCards && (
        <>
          <PaymentMethodsList
            isLoading={isLoading}
            onSetCardAsDefault={setCardAsDefault}
            onDeleteSelectedCard={deleteSelectedCard}
            onOpenAddCard={handleShowAddCard}
          />

          {isReactivation && !isExpiredDefaultCard && !isLoading && (
            <div className={styles.activateSubscriptionButton}>
              {/* NOTE: only button to apply subscription */}
              <Payment
                getExistingCardId={getCurrentCardId}
                getClientSecret={reactivationCheckoutSession}
                onSuccess={() => onReactivationSuccess(false)}
                onFailure={onPaymentError}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default memo(PaymentMethods);
