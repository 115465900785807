import { useSubjects } from 'hooks';
import { Fragment, lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const SubjectSlider = lazy(() => import('components/home/SubjectSlider'));

const Home = () => {
  const { subjects } = useSubjects();
  const { t } = useTranslation('parent');
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t('homePage.title')}</h1>
      <p className={styles.subtitle}>{t('homePage.subtitle')}</p>
      {subjects && (
        <div className={styles.subjects}>
          {subjects.map((subject) => (
            <Fragment key={subject.id}>
              <Suspense fallback={null}>
                <SubjectSlider subject={subject} />
              </Suspense>
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default Home;
