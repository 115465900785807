import CN from 'clsx';
import type { Ref } from 'react';
import { forwardRef } from 'react';

import styles from './styles.module.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  className?: string;
  isDark?: boolean;
  isGrey?: boolean;
  isGreen?: boolean;
}

const RadioButton = forwardRef(
  (
    { label = '', isDark = false, isGrey = false, isGreen = false, className, ...rest }: Props,
    ref: Ref<HTMLInputElement>,
  ) => {
    return (
      <label
        className={CN(styles.label, className, { [styles.labelGrey]: isGrey, [styles.labelHasGreenButton]: isGreen })}
      >
        <div
          className={CN(styles.radiobutton, { [styles.radiobuttonDark]: isDark, [styles.radiobuttonGreen]: isGreen })}
        >
          <input ref={ref} type="radio" className={styles.input} {...rest} />
          <span className={CN(styles.checkmark, { [styles.checkmarkGrey]: isGrey })}></span>
        </div>
        <span className={styles.labelText}>{label}</span>
      </label>
    );
  },
);

export default RadioButton;
