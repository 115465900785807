import { Bugsnag } from '@albert/shared/services';

import type { Currencies, Locales } from '../types';
import { Locale, LocalesArray, UnavailableLocalesArray } from '../types';

export const isProduction = () => {
  const env = process.env.VITE_ENVIRONMENT ?? process.env.NEXT_PUBLIC_ENVIRONMENT;
  return env === 'production';
};

export const isDevelopment = () => {
  const env = process.env.VITE_ENVIRONMENT ?? process.env.NEXT_PUBLIC_ENVIRONMENT ?? process.env.NODE_ENV;
  return env === 'development';
};

export const upperCaseFirstLetter = (name: string) => {
  return name.charAt(0).toUpperCase() + name.slice(1);
};

export const getCurrencyForLocale = (locale: Locales) => {
  const currencies: Record<Locales, Currencies> = {
    [Locale.DK]: 'DKK',
    [Locale.SE]: 'SEK',
    [Locale.NO]: 'NOK',
    [Locale.PL]: 'PLN',
    [Locale.UK]: 'GBP',
    [Locale.IT]: 'EUR',
    [Locale.FI]: 'EUR',
    [Locale.ES]: 'EUR',
    [Locale.RO]: 'RON',
    [Locale.CZ]: 'CZK',
  };

  return currencies[locale];
};

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getLocaleFromUrl = () => {
  let locale = document?.location?.hostname?.split('.')?.reverse()[0] as Locales;

  if (!LocalesArray.includes(locale)) {
    locale = Locale.SE;
  }

  return locale;
};

export const getAppStoreLocale = (locale: Locale) => (locale === Locale.UK ? 'gb' : locale);

export const isAvailableLocale = (locale: Locale) =>
  UnavailableLocalesArray.some((unavailableLocale) => locale === unavailableLocale);

export const poll = async (
  request: () => Promise<any>,
  interval: number,
  callback: (data: any) => void,
  stopCondition?: (data: any) => boolean,
  maxRequests: number = 10,
): Promise<void> =>
  new Promise((resolve) => {
    let isPolling = true;
    let requestCount = 0;

    const fetchData = async () => {
      if (!isPolling || requestCount >= maxRequests) {
        isPolling = false;
        resolve();
        return;
      }

      try {
        const data = await request();
        callback(data);

        if (stopCondition?.(data)) {
          isPolling = false;
          resolve();
          return;
        }
      } catch (error: any) {
        Bugsnag.notify(error);
      }

      requestCount++;

      if (isPolling) {
        setTimeout(fetchData, interval);
      }
    };

    fetchData();
  });
