import type { EnhancedStore, Middleware } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';

import { isProduction } from '@albert/shared/utils';

import { loggerMiddleware } from './middlewares';
import persistConfig from './persistConfig';
import rootReducer from './rootReducer';

let store: EnhancedStore;

export const initializeStore = () => {
  const middlewares: Middleware[] = [];

  if (!isProduction()) {
    middlewares.push(loggerMiddleware);
  }

  store = configureStore({
    reducer: persistReducer(persistConfig, rootReducer),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: {
          warnAfter: 200,
        },
      }).concat(middlewares),
    devTools: !isProduction(),
  });

  const persistor = persistStore(store);

  return { store, persistor };
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;

export const dispatch = (args: any) => store?.dispatch(args);
export const getState = (): RootState => store?.getState();
