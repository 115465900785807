import type { Locales, MapTo, ToLowercase, ToUppercase, BillingInterval } from '.';

export enum Membership {
  Bas = 'bas',
  Standard = 'standard',
  Plus = 'plus',
}

export type Memberships = `${Membership}`;
export type MembershipsUppercase = MapTo<ToUppercase, Membership>;

export type AllSubscriptions = `${Locales}_${Membership}`;

export type SubscriptionsType = Exclude<AllSubscriptions, 'de_standard'>;

export type Currencies = 'SEK' | 'DKK' | 'NOK' | 'PLN' | 'GBP' | 'EUR' | 'RON' | 'CZK';
export type CurrenciesLowercase = MapTo<ToLowercase, Currencies>;

export type Coupon = {
  plans: SubscriptionsType[];
  name: string;
  amountOff?: number;
  percentOff?: number;
  trialDays?: number;
};

export interface AdvantageText {
  included: string[];
  excluded?: string[];
}

export enum SubscriptionManagementType {
  Change = 'change',
  ChangeWithSpecialOffer = 'changeWithSpecialOffer',
  Reactivate = 'reactivate',
}

export type AllBillingsPrices = Partial<Record<BillingInterval, number | undefined>>;
