import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ConfigService } from 'services';
import type { RootState } from 'store';
import { useLocale } from 'store/hooks';

import appStore from '@albert/shared/assets/images/appStore.png';
import playStore from '@albert/shared/assets/images/playStore.png';
import { DATE_FORMAT, EXTERNAL_LINK_ATTRIBUTES, WEBSITE_ROUTES } from '@albert/shared/constants';
import { MixpanelStoreName, PaymentProvider } from '@albert/shared/types';
import { getCurrencyForLocale } from '@albert/shared/utils';

import styles from '../../styles.module.scss';

const InAppPurchaseProvider = () => {
  const websiteUrl = ConfigService.get('websiteUrl');
  const { t } = useTranslation('parent');
  const locale = useLocale();
  const subscription = useSelector((state: RootState) => state.subscription?.subscription)!;
  const { provider, planInfo, endingAt } = subscription;
  const isAppleSubscription = provider === PaymentProvider.Apple;

  return (
    <>
      <div className={styles.card}>
        <h2 className={styles.cardTitle}>{t('general.membership')}</h2>
        <div className={styles.cardSubContainer}>
          <div className={styles.row}>
            <div className={styles.icon}>
              <img role="presentation" src={isAppleSubscription ? appStore : playStore} alt="" />
              <p>
                {t('subscriptionPage.subscription.store.title', {
                  store: isAppleSubscription ? MixpanelStoreName.Apple : MixpanelStoreName.Google,
                })}
              </p>
            </div>
          </div>
          <div className={styles.row}>
            <div>{t('subscriptionPage.general.price')}</div>
            <div className={styles.bold}>{`${planInfo.localPrice} ${getCurrencyForLocale(locale)}`}</div>
          </div>
          {endingAt && (
            <div className={styles.row}>
              <div
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: t('subscriptionPage.subscription.general.endingAt', {
                    endingAt: `<b>${dayjs(endingAt).format(DATE_FORMAT[locale])}</b>`,
                  }),
                }}
              />
            </div>
          )}
          <div className={styles.description}>
            {t('subscriptionPage.subscription.store.subtitle', {
              store: isAppleSubscription ? MixpanelStoreName.Apple : MixpanelStoreName.Google,
            })}
            <a href={`${websiteUrl}${WEBSITE_ROUTES.FAQ}`} {...EXTERNAL_LINK_ATTRIBUTES}>
              {t('subscriptionPage.subscription.store.link')}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default InAppPurchaseProvider;
