import CN from 'clsx';
import type { ReactNode } from 'react';
import { forwardRef } from 'react';

import loadingSpinner from '../../assets/images/loader.gif';
import Icon from '../Icon';
import styles from './styles.module.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  className?: string;
  loading?: boolean;
  small?: boolean;
  theme?: 'default' | 'secondary' | 'withArrow';
  disabled?: boolean;
  linkButton?: boolean;
  isNewDesign?: boolean;
}

const Button = forwardRef<HTMLButtonElement, Props>(
  ({ theme, small, className, loading, children, disabled, linkButton, isNewDesign, ...rest }, ref) => {
    const buttonStyle = () => {
      switch (theme) {
        case 'secondary':
          return styles.secondary;
        case 'withArrow':
          return styles.withArrow;
        default:
          return styles.default;
      }
    };

    const buttonStyles = CN(
      styles.button,
      { [styles.isSmall]: small },
      { [styles.isLoading]: loading },
      { [styles.isDisabled]: linkButton && disabled },
      { [styles.isNewDesign]: isNewDesign },
      buttonStyle(),
      className,
    );

    const loadButtonContent = () => (
      <>
        {!loading && <span className={styles.text}>{children}</span>}
        {loading &&
          (isNewDesign ? (
            // @ts-ignore
            <img className={styles.newLoader} src={loadingSpinner.src || loadingSpinner} />
          ) : (
            <div className={styles.loader}>
              <div />
              <div />
              <div />
            </div>
          ))}
        {theme === 'withArrow' && (
          <span className={styles.arrow}>
            <Icon name="path" size={15} />
          </span>
        )}
      </>
    );

    if (linkButton) {
      return <div className={buttonStyles}>{loadButtonContent()}</div>;
    }

    return (
      <button ref={ref} className={buttonStyles} disabled={loading || disabled} {...rest}>
        {loadButtonContent()}
      </button>
    );
  },
);

export default Button;
