import { passwordChange, sendEvent } from 'api';
import CN from 'clsx';
import { DeleteButton } from 'components';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { useFirebaseUser } from 'hooks';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Toast } from 'services';
import { apiService, FirebaseService } from 'services';
import { changePasswordEvent, deleteAccountEvent } from 'services/analyticsEvents';
import { useAuth, useLocale } from 'store/hooks';
import isEmail from 'validator/lib/isEmail';

import { Button, Input } from '@albert/shared/components';
import { DATE_FORMAT } from '@albert/shared/constants';
import { Bugsnag } from '@albert/shared/services';

import DeleteAccountModal from '../DeleteAccountModal';
import styles from './styles.module.scss';

// TODO: move to constants as with only firebase error
export const authErrors = (errorCode: string) => {
  switch (errorCode) {
    case 'auth/requires-recent-login':
      return 'errorMessages.requiresRecentLogin';
    case 'auth/too-many-requests':
      return 'errorMessages.tooManyAttempts';
    default:
      return 'website:shared.somethingIsWrong';
  }
};

type SubscriptionFormType = { password: string; newPassword: string; newPasswordConfirm: string; email?: string };

const ManagePassword = () => {
  const { t } = useTranslation(['parent', 'website']);
  const locale = useLocale();
  const navigate = useNavigate();
  const user = useFirebaseUser();
  const { uid } = useAuth();

  const {
    formState: { errors },
    register,
    setValue,
    handleSubmit,
    getValues,
  } = useForm<SubscriptionFormType>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      email: user?.email ?? '',
      password: '',
      newPassword: '',
      newPasswordConfirm: '',
    },
  });

  const [isLoading, setLoading] = useState(false);
  const [isAccountDeleted, setIsAccountDeleted] = useState(false);
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(false);

  useEffect(() => {
    setValue('email', user?.email ?? '');
  }, [user?.email]);

  const onSubmit = async (form: SubscriptionFormType) => {
    setLoading(true);

    try {
      if (uid && !user?.email) {
        await passwordChange(uid, getValues('password'), getValues('newPassword'));
      } else {
        await FirebaseService.updatePassword({
          email: user?.email ?? '',
          oldPassword: form.password,
          newPassword: form.newPassword,
        });
      }

      sendEvent(changePasswordEvent());

      Toast.success(t('subscriptionPage.password.updated'));
    } catch (e) {
      const error = e as Error & { code: string };
      Bugsnag.notify(error);

      Toast.error(t(authErrors(error.code)) ?? error.message);
    } finally {
      setValue('newPassword', '');
      setValue('newPasswordConfirm', '');
      setLoading(false);
    }
  };

  const removeAccount = async () => {
    await apiService.delete('/users');
    sendEvent(deleteAccountEvent({ account_deleted: true }));

    setIsAccountDeleted(true);

    navigate(routes.ACCOUNT_DELETED);
  };

  const handleToggleDeleteAccountModal = () => {
    if (isDeleteAccountModalOpen && !isAccountDeleted) {
      sendEvent(deleteAccountEvent({ account_deleted: false }));
    }

    setIsDeleteAccountModalOpen(!isDeleteAccountModalOpen);
  };

  return (
    <>
      <div className={styles.card}>
        <h1 className={CN(styles.cardTitle, styles.border)}>{t('subscriptionPage.general.accountDetails')}</h1>
        <form className={styles.smallerWidth} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.infoLine}>
            <h4 className={styles.label}>{t('subscriptionPage.registerDate')}:</h4>
            <p className={styles.value}>{dayjs(user?.metadata.creationTime).format(DATE_FORMAT[locale])}</p>
          </div>

          <Input
            label={t('general.username')}
            disabled={true}
            type="email"
            id="username"
            autoComplete="username"
            {...register('email', {
              required: t('errorMessages.required'),
              validate: (it) => {
                const isValid = it ? isEmail(it) : false;
                return isValid ? true : t('errorMessages.emailFormat');
              },
            })}
            error={errors.email?.message}
          />
          <Input
            label={t('general.password')}
            title="Lösenord"
            type="password"
            autoComplete="on"
            {...register('password', {
              required: t('errorMessages.required'),
              minLength: {
                value: 6,
                message: t('errorMessages.minLength'),
              },
            })}
            error={errors.password?.message}
          />
          <Input
            label={t('subscriptionPage.password.new')}
            type="password"
            autoComplete="off"
            title="Nytt lösenord"
            {...register('newPassword', {
              required: t('errorMessages.required'),
              minLength: {
                value: 6,
                message: t('errorMessages.minLength'),
              },
            })}
            error={errors.newPassword?.message}
          />
          <Input
            label={t('subscriptionPage.password.newAgain')}
            type="password"
            autoComplete="off"
            title="Nytt lösenord igen"
            error={errors.newPasswordConfirm?.message}
            {...register('newPasswordConfirm', {
              required: t('errorMessages.required'),
              minLength: {
                value: 6,
                message: t('errorMessages.minLength'),
              },
              validate: {
                match: (value) =>
                  getValues('newPassword') === value ? true : t('errorMessages.matchesPreviousPassword'),
              },
            })}
          />
          <div className={styles.buttonsContainer}>
            <Button loading={isLoading} type="submit" disabled={!isEmpty(errors)}>
              {t('general.update')}
            </Button>

            <DeleteButton
              className={styles.btnSecondary}
              title={`${t('editChild.delete')} ${t('subscriptionPage.general.account')}`}
              disabled={isLoading}
              onClick={handleToggleDeleteAccountModal}
            />
          </div>
        </form>
      </div>
      {isDeleteAccountModalOpen && (
        <DeleteAccountModal onToggle={handleToggleDeleteAccountModal} onDelete={removeAccount} />
      )}
    </>
  );
};

export default ManagePassword;
