import { Locale } from '../types';

export const CURRENT_YEAR = new Date().getFullYear();

export const DATE_FORMAT = {
  [Locale.SE]: 'YYYY-MM-DD',
  [Locale.NO]: 'YYYY-MM-DD',
  [Locale.DK]: 'YYYY-MM-DD',
  [Locale.FI]: 'YYYY-MM-DD',
  [Locale.PL]: 'DD.MM.YYYY',
  [Locale.UK]: 'DD/MM/YYYY',
  [Locale.IT]: 'DD/MM/YYYY',
  [Locale.ES]: 'DD/MM/YYYY',
  [Locale.RO]: 'DD.MM.YYYY',
  [Locale.CZ]: 'DD.MM.YYYY',
};
