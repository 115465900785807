import Bugsnag from '@bugsnag/js';
import { apiService } from 'services';

import type { Notification, NotificationsIds } from '../types';

export const getNotifications = async (): Promise<Notification[]> => {
  try {
    const res = await apiService.get<{ notifications: Notification[] }>('/notifications');
    return res.notifications;
  } catch (error: any) {
    Bugsnag.notify(`Failed to fetch notifications. Error — ${error}`);
    throw error;
  }
};

export const markNotificationsAsSeen = async (data: string[]): Promise<void> => {
  await apiService.post<void, NotificationsIds>('/notifications/seen', { ids: data });
};
