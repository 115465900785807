export enum PaymentProvider {
  Stripe = 'stripe',
  Google = 'google',
  Apple = 'apple',
  Albert = 'albert',
  Integration = 'integration',
}

export enum BillingInterval {
  Monthly = 'month',
  Quarterly = '3 months',
  SemiAnnual = '6 months',
  Annual = 'year',
}

export interface PaymentResponse {
  token: string;
  provider: PaymentProvider;
}

export interface CurrencyData {
  amount: number;
  currency: string;
}

export interface PaymentRequest {
  paymentIntentId: string;
  token?: string;
  signature?: string;
}
