import type { BrowserConfig, NotifiableError } from '@bugsnag/js';
import { default as _Bugsnag } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React, { Fragment } from 'react';

class Bugsnag {
  private static get environment() {
    return process.env.VITE_ENVIRONMENT ?? process.env.NEXT_PUBLIC_ENVIRONMENT;
  }

  private static get isProduction() {
    return this.environment === 'production';
  }

  static initialize(apiKey: string, options: Omit<BrowserConfig, 'apiKey' | 'plugins'>) {
    const { environment, isProduction } = Bugsnag;

    if (!(isProduction && apiKey && environment)) {
      return Fragment;
    }

    _Bugsnag.start({
      ...options,
      apiKey,
      releaseStage: environment,
      collectUserIp: false,
      plugins: [new BugsnagPluginReact()],
    });

    return _Bugsnag.getPlugin('react')!.createErrorBoundary(React);
  }

  static notify(error: NotifiableError) {
    if (Bugsnag.isProduction) {
      _Bugsnag.notify(error);
    }
  }
}

export default Bugsnag;
