import { memo } from 'react';

import styles from './styles.module.scss';

interface Props {
  label: string;
}

const PlanTopLabel = ({ label }: Props) => {
  return (
    <div className={styles.topLabel}>
      <span>{label}</span>
    </div>
  );
};

export default memo(PlanTopLabel);
