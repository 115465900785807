import Bugsnag from '@bugsnag/js';
import type { Block } from 'albert-common';
import { sendEvent } from 'api';
import CN from 'clsx';
import { ErrorFallback, ToggleUsers } from 'components';
import { MOBILE_APPS, TEEN_PP_URL } from 'constants/common';
import dayjs from 'dayjs';
import { getTranslatedBlockTitle } from 'hooks';
import { Fragment, useMemo, useState } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { FirebaseService, albertCommon } from 'services';
import { teenPPLinkClickEvent } from 'services/analyticsEvents';
import type { RootState } from 'store';
import { useLocale } from 'store/hooks';
import type { ChildProgressType, JuniorContentStats, JuniorHistoryType, TrophyStatuses } from 'types';

import { Button, Icon } from '@albert/shared/components';
import { DATE_FORMAT } from '@albert/shared/constants';
import type { Locales } from '@albert/shared/types';
import { Locale } from '@albert/shared/types';

import styles from './styles.module.scss';

const NUMBER_OF_EXERCISES_TO_GET_DIPLOMA = [50, 100, 150];

interface Trophy {
  date: string;
  blockName: string;
  isBlockNew: boolean;
  trophy: TrophyStatuses;
}

const createTrophies = (locale: Locales, history?: JuniorHistoryType): Trophy[] => {
  if (!history) return [];

  const trophies = [];

  for (const [id, historyEntry] of Object.entries(history)) {
    if (!historyEntry.trophy_status) continue;

    let block: Block;

    try {
      block = albertCommon().content.getBlock(historyEntry.block_id);
    } catch (e) {
      continue;
    }

    const translatedBlockName = getTranslatedBlockTitle(historyEntry.block_id, locale);
    const timestamp = Number.isNaN(Number(id)) ? Number(historyEntry.timestamp) : Number(id);

    trophies.push({
      date: dayjs(timestamp).format(DATE_FORMAT[locale]),
      blockName: translatedBlockName,
      isBlockNew: block.isNew,
      trophy: historyEntry.trophy_status,
    });
  }

  return trophies;
};

const determineNumberOfSolvedExercises = (contentStats?: JuniorContentStats) => {
  if (!contentStats) return 0;

  let numberOfSolvedExercises = 0;
  for (const contentId of Object.keys(contentStats)) {
    // Exercise id has length of 9 characters all other content types have
    // id length of 7
    const isExercise = contentId.length > 7;
    const isExerciseSolved = contentStats && contentStats[contentId].state === 3;

    if (isExercise && isExerciseSolved) {
      numberOfSolvedExercises++;
    }
  }

  return numberOfSolvedExercises;
};

const createDiplomaDates = (locale: Locales, contentStats?: JuniorContentStats) => {
  if (!contentStats) return [];

  const diplomaDates: string[] = [];
  const contentStatsValues = Object.values(contentStats);
  const contentStatsValuesLength = contentStatsValues.length;

  // If user has completed 50 or more exercises, include diploma
  if (contentStatsValuesLength >= NUMBER_OF_EXERCISES_TO_GET_DIPLOMA[0]) {
    const item = contentStatsValues[NUMBER_OF_EXERCISES_TO_GET_DIPLOMA[0] - 1];
    if (item.attempts) {
      const attempts = Object.values(item.attempts);
      diplomaDates.unshift(dayjs(attempts[0].createdAt).format(DATE_FORMAT[locale]));
    }
  }

  // If user has completed 100 or more exercises, include diploma
  if (contentStatsValuesLength >= NUMBER_OF_EXERCISES_TO_GET_DIPLOMA[1]) {
    const item = contentStatsValues[NUMBER_OF_EXERCISES_TO_GET_DIPLOMA[1] - 1];
    if (item.attempts) {
      const attempts = Object.values(item.attempts);
      diplomaDates.unshift(dayjs(attempts[0].createdAt).format(DATE_FORMAT[locale]));
    }
  }

  // If user has completed 150 or more exercises, include diploma
  if (contentStatsValuesLength >= NUMBER_OF_EXERCISES_TO_GET_DIPLOMA[2]) {
    const item = contentStatsValues[NUMBER_OF_EXERCISES_TO_GET_DIPLOMA[2] - 1];
    if (item.attempts) {
      const attempts = Object.values(item.attempts);
      diplomaDates.unshift(dayjs(attempts[0].createdAt).format(DATE_FORMAT[locale]));
    }
  }

  return diplomaDates;
};

const getTrophiesPageContent = (locale: Locales, childProgress?: ChildProgressType) => {
  if (!childProgress) {
    return {
      trophies: [],
      numberOfSolvedExercises: [],
      diplomaDates: [],
    };
  }

  return {
    trophies: createTrophies(locale, childProgress.history),
    numberOfSolvedExercises: determineNumberOfSolvedExercises(childProgress.contentStats),
    diplomaDates: createDiplomaDates(locale, childProgress.contentStats),
  };
};

const Trophies = () => {
  const { t } = useTranslation(['parent', 'website']);
  const locale = useLocale();
  const location = useLocation();

  const [tab, setTab] = useState(location.search ? 1 : 0);
  const { childrenProgress, children } = useSelector((state: RootState) => state.user);
  const selectedChild = useSelector((state: RootState) => state.app.selectedChild);

  const isJunior = children?.[selectedChild]?.productType === MOBILE_APPS.JUNIOR;
  const isTeen = children?.[selectedChild]?.productType === MOBILE_APPS.TEEN;
  const isTeenSE = isTeen && locale === Locale.SE;
  const childProgress = childrenProgress?.[selectedChild];
  const { trophies, numberOfSolvedExercises, diplomaDates } = useMemo(
    () => getTrophiesPageContent(locale, childProgress),
    [selectedChild],
  );

  const showDiplomas = Number(numberOfSolvedExercises) > NUMBER_OF_EXERCISES_TO_GET_DIPLOMA[0];
  const hasDiplomasPDF = [
    Locale.SE,
    Locale.NO,
    Locale.DK,
    Locale.PL,
    Locale.UK,
    Locale.ES,
    Locale.IT,
    Locale.FI,
    Locale.RO,
    Locale.CZ,
  ];

  const downloadPDF = async (locale: Locales, numExercisesToGetDiploma: number) => {
    let url = 'gs://firebase-albert-production.appspot.com/diplomas';

    if (hasDiplomasPDF.includes(locale)) {
      url += `/${locale.toUpperCase()}_${numExercisesToGetDiploma}.pdf`;
    } else {
      url += `/UK_${numExercisesToGetDiploma}.pdf`;
    }

    const openWindow = window.open();
    const pdfFile = await FirebaseService.downloadFileFromUrl({ url });

    if (openWindow) {
      openWindow.location = pdfFile;
    }
  };

  const handleOpenTeenPPClick = () => {
    sendEvent(
      teenPPLinkClickEvent({
        source: 'trophies_page',
      }),
    );

    window.open(TEEN_PP_URL, '_blank');
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>{t('progressPage.title')}</h1>
        <ToggleUsers />
      </div>
      {isJunior ? (
        <>
          <div className={styles.tabs}>
            <h2 onClick={() => setTab(0)} className={CN(styles.tab, { [styles.active]: tab === 0 })}>
              {t('progressPage.trophyTab')}
            </h2>
            <h2 onClick={() => setTab(1)} className={CN(styles.tab, { [styles.active]: tab === 1 })}>
              {t('progressPage.diplomaTab')}
            </h2>
          </div>
          <div className={styles.content}>
            {tab ? (
              <>
                {!showDiplomas ? (
                  <div className={CN(styles.empty, styles.emptyDiploma)}>
                    <div />
                    <h3>{t('progressPage.emptyDiploma')}</h3>
                  </div>
                ) : (
                  <div className={styles.diplomas}>
                    {NUMBER_OF_EXERCISES_TO_GET_DIPLOMA.map((numExercisesToGetDiploma, index) => (
                      <Fragment key={index}>
                        {Number(numberOfSolvedExercises) >= numExercisesToGetDiploma && (
                          <div
                            className={CN(styles.card, styles.diploma, styles[`diploma${numExercisesToGetDiploma}`])}
                          >
                            <div className={styles.image} />
                            <div>
                              <h1>
                                {t('progressPage.diplomaTitle', {
                                  number: numExercisesToGetDiploma,
                                })}
                              </h1>
                              {diplomaDates && <h3>{diplomaDates[index]}</h3>}
                            </div>
                            <div
                              className={styles.downloadButton}
                              onClick={() => downloadPDF(locale, numExercisesToGetDiploma)}
                            >
                              <Icon name="download_button" size={20} />
                              {t('website:shared.general.download')}
                            </div>
                            <div
                              className={styles.downloadButtonMobile}
                              onClick={() => downloadPDF(locale, numExercisesToGetDiploma)}
                            >
                              <Icon name="download_button" size={30} />
                            </div>
                          </div>
                        )}
                      </Fragment>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <>
                {trophies.length === 0 ? (
                  <div className={CN(styles.empty, styles.emptyTrophy)}>
                    <div />
                    <h3>{t('progressPage.emptyTrophy')}</h3>
                  </div>
                ) : (
                  <div className={styles.card}>
                    <div className={styles.trophies}>
                      {trophies.map((trophy, index: number) => (
                        <div key={index} className={styles.trophy}>
                          <div className={CN(styles.image, styles[trophy.trophy])} />
                          <div>
                            {trophy.isBlockNew && (
                              <h1>{`${trophy.blockName} (${t('parentReport.progress.isNewExercise')})`}</h1>
                            )}
                            {!trophy.isBlockNew && <h1>{trophy.blockName}</h1>}
                            <h3>{trophy.date}</h3>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <div className={CN(styles.empty, styles.emptyState)}>
          {isTeenSE ? (
            <>
              <div className={CN(styles.empty, styles.emptyTrophy)}>
                <div className={styles.icon} />

                <h3 className={styles.teenPpNotice}>
                  I Albert Teens egna Föräldraportal på webben kan du följa framstegen och se vad ditt barn har övat på
                </h3>

                <Button className={styles.button} onClick={handleOpenTeenPPClick}>
                  Till rapporten
                  <Icon name="external_link" size={24} className={styles.externalLinkIcon} />
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className={styles.icon} />
              <h1>{t('parentReport.badges.teen.title')}</h1>

              <h3>{t('parentReport.badges.teen.text')}</h3>
            </>
          )}
        </div>
      )}
    </div>
  );
};

const TrophiesWithErrorFallback = withErrorBoundary(Trophies, {
  FallbackComponent: ErrorFallback,
  onError(error, info) {
    Bugsnag.notify(`ErrorBoundary caught an error on Trophies page. Error — ${error} Info — ${info}`);
  },
});

export default TrophiesWithErrorFallback;
