import type { UnknownAction, Action, ThunkAction } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';
import type { RootState } from 'store';

import { AuthenticationStatus } from '../types';
import app, { actions } from './app/reducers';
import subscription from './subscription/reducers';
import user from './user/reducers';

const combinedReducers = combineReducers({
  app,
  subscription,
  user,
});

const rootReducer = (state: any, action: UnknownAction) => {
  // This indicates that Firebase was loaded without valid authentication.
  // Check the useFirebaseAuth() hook.
  if (action.type === actions.setAuthInitialized.type && action.payload === AuthenticationStatus.Loaded) {
    state = undefined;
  }

  return combinedReducers(state, action);
};

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export default rootReducer;
