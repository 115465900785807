import { apiService } from 'services';
import type { ChildAddSource, ChildInfo } from 'types';

export const passwordChange = (uid: string, currentPassword: string, newPassword: string) =>
  apiService.post('/passwordChange', { uid, currentPassword, newPassword });

export const deleteChild = (childId: string) => apiService.delete(`/children/${childId}`);

export const editChild = (childId: string, childInfo: ChildInfo) => apiService.put(`/children/${childId}`, childInfo);

export const addChild = (childInfo: ChildInfo & { source: ChildAddSource }) => apiService.post('/children', childInfo);
