import CN from 'clsx';
import type { ButtonHTMLAttributes } from 'react';

import styles from './styles.module.scss';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  className?: string;
}

const DeleteButton = ({ onClick, className, title, ...rest }: Props) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <button onClick={handleClick} className={CN(styles.deleteButton, className)} {...rest}>
      <div className={styles.button}>{title}</div>
    </button>
  );
};

export default DeleteButton;
