import type { CampaignName } from '../types';
import { Locale } from '../types';

export enum GrowthbookExperimentID {
  GROWTHBOOK_SHOW_MONTHLY_PLANS_DEFAULT_SE = 'show-monthly-plans-default',
  GROWTHBOOK_NEW_PLAN_ADVANTAGES = 'new-plan-advantages',
  GROWTHBOOK_NEW_CHECKOUT_FLOW_DESIGN = 'new-checkout-flow-design',
  GROWTHBOOK_NEW_CTA_BUTTONS_DESIGN = 'new-cta-buttons-design',
}

export enum GrowthbookFeatureFlagID {
  GROWTHBOOK_SHOW_BOOTCAMP_SE = 'show-bootcamp-se',
}

export enum AbTestsMixpanelKey {
  WEB_SHOW_MONTHLY_PLANS_DEFAULT_SE = 'web_show_monthly_plans_default_se_140125',
  WEB_NEW_PLAN_ADVANTAGES_PL = 'web_new_plan_advantages_pl_310125',
  WEB_NEW_CHECKOUT_FLOW_DESIGN_NO = 'web_new_checkout_flow_design_no_030425',
  WEB_NEW_CHECKOUT_FLOW_DESIGN_UK = 'web_new_checkout_flow_design_uk_030425',
  WEB_NEW_CTA_BUTTONS_DESIGN_RO = 'web_new_cta_buttons_design_ro_030425',
  WEB_NEW_PLAN_ADVANTAGES_DK = 'web_new_plan_advantages_dk_030425',
}

export type AbTestID = GrowthbookExperimentID | AbTestsMixpanelKey;

export type AbTest = {
  [locale in Locale]?: {
    mixpanelId: AbTestsMixpanelKey;
    campaignName?: CampaignName;
  };
};

export type AbTestsData = Record<AbTestsMixpanelKey, number>;

export const AB_TESTS: Partial<Record<AbTestsMixpanelKey, AbTest>> & Record<GrowthbookExperimentID, AbTest> = {
  'show-monthly-plans-default': {
    [Locale.SE]: {
      mixpanelId: AbTestsMixpanelKey.WEB_SHOW_MONTHLY_PLANS_DEFAULT_SE,
    },
  },
  'new-plan-advantages': {
    [Locale.PL]: {
      mixpanelId: AbTestsMixpanelKey.WEB_NEW_PLAN_ADVANTAGES_PL,
    },
    [Locale.DK]: {
      mixpanelId: AbTestsMixpanelKey.WEB_NEW_PLAN_ADVANTAGES_DK,
    },
  },
  'new-checkout-flow-design': {
    [Locale.NO]: {
      mixpanelId: AbTestsMixpanelKey.WEB_NEW_CHECKOUT_FLOW_DESIGN_NO,
    },
    [Locale.UK]: {
      mixpanelId: AbTestsMixpanelKey.WEB_NEW_CHECKOUT_FLOW_DESIGN_UK,
    },
  },
  'new-cta-buttons-design': {
    [Locale.RO]: {
      mixpanelId: AbTestsMixpanelKey.WEB_NEW_CTA_BUTTONS_DESIGN_RO,
    },
  },
};
