import { Trans, useTranslation } from 'react-i18next';

import { ReactComponent as SadCharacters } from '@albert/shared/assets/svg/characters_sad.svg';
import { SAFE_LINK_ATTRIBUTES } from '@albert/shared/constants';

import styles from './styles.module.scss';

const ErrorFallback = () => {
  const { t } = useTranslation(['parent', 'website']);

  return (
    <div className={styles.fallback}>
      <div className={styles.image}>
        <SadCharacters />
      </div>
      <p>
        <Trans
          t={t}
          i18nKey="errorMessages.supportMailTo"
          parent="p"
          components={{
            supportUrl: <a href={t('onboardingStartguide.tips.supportMail')} {...SAFE_LINK_ATTRIBUTES} />,
          }}
        />
      </p>
    </div>
  );
};

export default ErrorFallback;
