export type NotificationProperties = Record<string, string | number | boolean | null>;

export enum NotificationType {
  UpcomingInvoice = 'upcoming_invoice',
}

export interface Notification {
  id: string;
  type: NotificationType | any;
  message: string;
  properties: NotificationProperties;
  showAt: string | null;
  seenAt: string | null;
  expireAt: string | null;
  dismissedAt: string | null;
  userId: string;
  createdAt: string;
  updatedAt: string;
}

export interface NotificationsIds {
  ids: string[];
}
