import { sendEvent } from 'api';
import CN from 'clsx';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { openExercisesBlockEvent } from 'services/analyticsEvents';

import { Icon } from '@albert/shared/components';

import styles from './styles.module.scss';

type ExerciseType = {
  isFinished?: boolean;
  isGood: boolean;
  progress?: string;
  exerciseName: string;
  originalExerciseName: string;
  level: string;
  chapters: Array<number>;
};

const Exercise = ({
  isFinished,
  isGood,
  progress,
  exerciseName,
  originalExerciseName,
  level,
  chapters,
}: ExerciseType) => {
  const { t } = useTranslation('parent');
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleExerceise = () => {
    setIsOpen((isOpen) => !isOpen);

    if (!isOpen) {
      sendEvent(
        openExercisesBlockEvent({
          area_name: originalExerciseName,
        }),
      );
    }
  };

  return (
    <div className={CN(styles.exercise, { [styles.open]: isOpen })} onClick={handleToggleExerceise}>
      <div
        className={CN(
          styles.rate,
          { [styles.finished]: isFinished },
          { [styles.ongoing]: !isFinished },
          { [styles.good]: isGood && isFinished },
          { [styles.ok]: !isGood && isFinished },
        )}
      >
        {isFinished ? (
          <>
            <h1>{progress}</h1>
            <h3>{t('parentReport.progress.rate')}</h3>
          </>
        ) : (
          <h3>{t('parentReport.progress.ongoing')}</h3>
        )}
      </div>
      <div className={styles.text}>
        <div>
          <h1>{level}</h1>
          <h1 className={styles.uppercase}>{exerciseName}</h1>
          <h3>
            {isFinished
              ? t('parentReport.progress.finished')
              : t('parentReport.progress.exercisesLeft', {
                  number: chapters.filter((i) => i === 0).length,
                })}
          </h3>
        </div>
        <Icon className={CN({ [styles.isOpen]: isOpen })} name="arrow_down" size={12} />
      </div>
      <div className={styles.chapters}>
        {chapters.map((item, index) => (
          <div key={index} className={CN(styles.chapter, { [styles.finished]: item !== 0 })}>
            <span className={CN(styles.chapterStatus, { [styles.chapterStatusOkay]: item <= 50 })} />
            {item !== 0 ? (
              <h1>
                <span>{item}%</span> {t('parentReport.progress.rate')}
              </h1>
            ) : (
              <h1>{t('parentReport.progress.notStarted')}</h1>
            )}
            <h3>
              {t('parentReport.progress.exercise')} <span>{index + 1}</span>
            </h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(Exercise);
