import type { Locales } from '@albert/shared/types';
import { Locale } from '@albert/shared/types';

export const getJuniorLocale = (locale: Locales) => {
  switch (locale) {
    case Locale.DK:
      return 'da_DK';
    case Locale.NO:
      return 'nb_NO';
    case Locale.PL:
      return 'pl_PL';
    case Locale.SE:
      return 'sv_SE';
    case Locale.ES:
      return 'es_ES';
    case Locale.FI:
      return 'fi_FI';
    case Locale.RO:
      return 'ro_RO';
    case Locale.CZ:
      return 'cs_CZ';
    case Locale.UK:
    case Locale.IT: // NOTE: Italian locale falls back to English (UK)
    default:
      return 'en_GB';
  }
};
