import type { Locales } from '../types';
import { Locale, App, Subject } from '../types';

export const LOCALES_WITH_TEEN_APP: Partial<Locales>[] = [Locale.SE, Locale.UK];

export const APPS_ORDER: App[] = [App.Junior, App.Teen, App.Jaramba, App.HolyOwly, App.Code, App.Geo];

export const APPS_ORDER_DEFAULT: App[] = [App.Junior, App.Teen, App.Code, App.Geo, App.Jaramba, App.HolyOwly];

export const SUBJECTS_ORDER: Subject[] = [
  Subject.math,
  Subject.abc,
  Subject.english,
  Subject.code,
  Subject.geo,
  Subject.physics,
  Subject.biology,
  Subject.jaramba,
  Subject.holyOwly,
];
