import CN from 'clsx';
import dayjs from 'dayjs';
import { Trans, useTranslation } from 'react-i18next';
import { useLocale } from 'store/hooks';
import type { NotificationProperties } from 'types';
import { NotificationType } from 'types';

import { Icon } from '@albert/shared/components';
import { DATE_FORMAT } from '@albert/shared/constants';

import styles from './styles.module.scss';

interface Props {
  isNew: boolean;
  type: NotificationType;
  createdAt: string;
  properties: NotificationProperties;
}

const NotificationsDropdown = ({ isNew, type, createdAt, properties }: Props) => {
  const locale = useLocale();
  const { t } = useTranslation('parent');
  const dataByNotificationType = {
    [NotificationType.UpcomingInvoice]: {
      icon: 'attention_circle',
      message: (
        <Trans
          t={t}
          i18nKey="notificationCenter.messages.upcomingInvoice"
          components={{ strong: <strong /> }}
          parent="p"
          values={{
            date: dayjs(properties?.paymentDate as string).format(DATE_FORMAT[locale]),
          }}
        />
      ),
      className: styles.attention,
    },
  };

  const notification = dataByNotificationType[type];

  const getNoficationDate = () => {
    if (dayjs(createdAt).isBefore('2024-10-14 10:00:00')) return;
    if (dayjs().isSame(createdAt, 'day')) return t('notificationCenter.today');
    return dayjs(createdAt).format(DATE_FORMAT[locale]);
  };

  const notificationDate = getNoficationDate();

  return (
    <li
      className={CN(styles.notoficationItem, {
        [styles.new]: isNew,
      })}
    >
      <Icon name={notification.icon as any} className={styles.icon} />
      <div>
        <div className={styles.message}>{notification.message}</div>
        {notificationDate && <p className={styles.date}>{notificationDate}</p>}
      </div>
    </li>
  );
};

export default NotificationsDropdown;
