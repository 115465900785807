import styles from './styles.module.scss';

const StarImage = () => {
  return (
    <div className={styles.starImage}>
      <span />
      <span />
    </div>
  );
};

export default StarImage;
