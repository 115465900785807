import Bugsnag from '@bugsnag/js';

import type { GetDiscountRequest, DiscountData } from '../types/discounts';
import type SharedApiService from './api';

export default class DiscountService {
  private api: SharedApiService;

  constructor(apiService: SharedApiService) {
    this.api = apiService;
  }

  async get({ promoCode, priceId, locale }: GetDiscountRequest) {
    const params = new URLSearchParams({ code: promoCode, ...(priceId && { priceId }) });
    const url = `/discounts?${params.toString()}`;
    try {
      const data = await this.api.get<{ discount: DiscountData }>(url, {
        headers: {
          locale: locale.toUpperCase(),
        },
      });
      return data.discount;
    } catch (err: any) {
      Bugsnag.notify(`Failed to fetch the promo code ${encodeURIComponent(promoCode)}. Failed with the error — ${err}`);
      return undefined;
    }
  }
}
