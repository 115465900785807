import CN from 'clsx';
import type { InputHTMLAttributes, ReactNode, Ref } from 'react';
import { useEffect, useState, forwardRef } from 'react';

import styles from './styles.module.scss';

interface Props {
  tabs: ({ label: ReactNode } & InputHTMLAttributes<HTMLInputElement>)[];
  activeIndex?: number;
  containerStyles?: string;
  tabLabelStyles?: string;
  slideStyles?: string;
  onChange: (tabIndex: number) => void;
}

const TabsSelector = forwardRef(
  (
    { tabs, activeIndex, containerStyles, tabLabelStyles, slideStyles, onChange }: Props,
    ref: Ref<HTMLInputElement>,
  ) => {
    const [selectedTab, setSelectedTab] = useState<number>(activeIndex ?? tabs.findIndex((tab) => tab.checked));

    useEffect(() => {
      if (typeof activeIndex === 'number') {
        setSelectedTab(activeIndex);
      }
    }, [activeIndex]);

    const handleSwitchTab = (index: number) => {
      setSelectedTab(index);
      onChange(index);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLLabelElement>, index: number) => {
      if (event.key === ' ' || event.key === 'Enter') {
        handleSwitchTab(index);
      }
    };

    return (
      <div className={CN(styles.container, containerStyles)}>
        {tabs.map((tab, index) => {
          const { label, ...rest } = tab;

          return (
            <label
              key={index}
              tabIndex={0}
              className={CN(styles.tabLabelContainer, tabLabelStyles, {
                [styles.active]: index === selectedTab,
                [styles.disabled]: tab?.disabled,
              })}
              role="button"
              aria-label="Plan slide select button"
              aria-selected={index === selectedTab}
              onKeyDown={(e) => handleKeyDown(e, index)}
            >
              <input
                ref={ref}
                type="radio"
                className={styles.input}
                {...rest}
                checked={selectedTab === index}
                onChange={() => handleSwitchTab(index)}
              />
              {typeof label === 'string' ? <span className={styles.label}>{tab.label}</span> : tab.label}
            </label>
          );
        })}
        <div
          style={{ width: `calc(100% / ${tabs.length})`, left: `calc((100% / ${tabs.length}) * (${selectedTab}))` }}
          className={CN(styles.slide, slideStyles)}
        />
      </div>
    );
  },
);

export default TabsSelector;
