import { sendEvent } from 'api';
import { CancelReasons } from 'components';
import dayjs from 'dayjs';
import { useChurnOffers } from 'hooks';
import { Suspense, lazy, useCallback, useEffect, useState } from 'react';
import { apiService, FirebaseService } from 'services';
import { cancelSubscriptionEvent, discountPopUpCloseEvent } from 'services/analyticsEvents';
import { setCancelReasons } from 'store/app/thunks';
import { useSubscription, useAuth, useLocale, useCancelReasons, useAppDispatch } from 'store/hooks';
import type { ChurnOffersStructureType, SubscriptionUpdateProps } from 'types';
import { CancelReasonCode, CancelSubscriptionState, UserSubscriptionStatus } from 'types';
import { getCancelReasons, getFirebaseRefs, getMembershipFromProductId, sendOfferAppliedEvent } from 'utils';

import { Bugsnag } from '@albert/shared/services';
import type { MembershipsUppercase } from '@albert/shared/types';
import { PaymentProvider } from '@albert/shared/types';

const Loader = lazy(() => import('@albert/shared/components/Loader'));
const CancellationOffer = lazy(() => import('components/account-subscription/subscription/cancel/CancellationOffer'));
const CancelSuccess = lazy(() => import('components/account-subscription/subscription/cancel/CancelSuccess'));
const CancelError = lazy(() => import('components/account-subscription/subscription/cancel/CancelError'));
const OfferAccepted = lazy(() => import('components/account-subscription/subscription/cancel/OfferAccepted'));

const CancelSubscription = () => {
  const subscription = useSubscription();
  const { uid } = useAuth();
  const locale = useLocale();
  const dispatch = useAppDispatch();
  const { churnOffers, churnOfferCoupon } = useChurnOffers();
  const cancelReasons = useCancelReasons();

  const [cancelReason, setCancelReason] = useState<number>(0);
  const [cancelSubreason, setCancelSubreason] = useState<string>('');
  const [cancelSubreasonPosition, setCancelSubreasonPosition] = useState<number>(0);
  const [otherReason, setOtherReason] = useState<string>('');
  const [cancelState, setCancelState] = useState<CancelSubscriptionState | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOfferShown, setIsOfferShown] = useState<boolean>(false);
  const [isOfferAccepted, setIsOfferAccepted] = useState<boolean>(false);

  useEffect(() => {
    if (!cancelReasons.length) {
      (async () => {
        setIsLoading(true);
        const cancelReasonsData = await getCancelReasons(locale);
        dispatch(setCancelReasons(cancelReasonsData));
        setIsLoading(false);
      })();
    }
  }, [locale, cancelReasons]);

  const isTrialUser = !!subscription?.inTrial && dayjs().isBefore(dayjs(subscription?.trialEnd));
  const hasDiscountedPrice = !!(subscription && subscription.planInfo.discountedPrice < subscription.planInfo.price);
  const userStatus = isTrialUser ? UserSubscriptionStatus.Trialing : UserSubscriptionStatus.Paying;
  const billingPeriod = subscription?.billingInterval;
  const offer = churnOffers.find((offer) => offer.userStatus === userStatus && offer.billingPeriod === billingPeriod);

  const cancelEventProps = {
    cancel_reason: Number(cancelReason),
    cancel_sub_reason: cancelSubreason,
    cancel_sub_reason_position: cancelSubreasonPosition,
    subscription_period: subscription!.billingInterval!,
    plan_name: getMembershipFromProductId(
      subscription?.planInfo.productId ?? '',
    )?.toUpperCase() as MembershipsUppercase,
    trial_end_date: subscription?.trialEnd ?? '',
    next_payment_date: subscription?.nextPaymentDate ?? '',
    full_price: subscription?.planInfo.price ?? 0,
  };

  const cancelSubscription = async () => {
    if (subscription && uid) {
      setIsLoading(true);

      let reason = cancelReason;

      // If user left text in the textfield we need to pass it as a reason 11;
      // No matter what reason user has picked from the beginning.
      if (otherReason) {
        reason = CancelReasonCode.OtherReason;
      }

      try {
        await FirebaseService.update(getFirebaseRefs().userRef, {
          cancel: {
            reason: Number(reason),
            subCancelReason: Number(cancelSubreason),
            remark: String(otherReason),
            time: Date.now(),
          },
        });

        await apiService.put<void, SubscriptionUpdateProps>('/subscriptions', {
          provider: PaymentProvider.Stripe,
          active: false,
        });

        sendEvent(
          cancelSubscriptionEvent({
            ...cancelEventProps,
            locale,
            cancel_reason: reason,
            cancel_remark: otherReason ?? '',
            campaign_name: subscription.planInfo.campaign ?? '',
            full_price: subscription.planInfo.price,
            in_trial: !!isTrialUser,
            has_discount: !!hasDiscountedPrice,
          }),
        );

        setCancelState(CancelSubscriptionState.CancelSuccess);
        setIsLoading(false);
        setIsOfferShown(false);
      } catch (e: any) {
        Bugsnag.notify(`Failed to cancel subscription: ${e}`);

        setCancelState(CancelSubscriptionState.CancelFailed);
        setIsLoading(false);
        setIsOfferShown(false);
      }
    }
  };

  const handleNextButtonClick = useCallback(() => {
    window.scrollTo(0, 0);

    if (churnOfferCoupon) {
      setIsOfferShown(true);
    } else {
      cancelSubscription();
    }
  }, [churnOfferCoupon, cancelReason, cancelSubreason, cancelSubreasonPosition, otherReason]);

  const acceptOffer = async () => {
    if (subscription) {
      setIsLoading(true);

      try {
        await apiService.put<void, SubscriptionUpdateProps>('/subscriptions', {
          provider: PaymentProvider.Stripe,
          coupon: churnOfferCoupon,
        });

        sendEvent(
          discountPopUpCloseEvent({
            discount_popup_status: 'discount_accepted',
            discount_type: churnOfferCoupon,
            ...cancelEventProps,
          }),
        );

        sendOfferAppliedEvent(churnOfferCoupon);

        setIsOfferAccepted(true);
      } catch (e: any) {
        Bugsnag.notify(`Failed to apply churn offer discount: ${e}`);
        setCancelState(CancelSubscriptionState.CancelFailed);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const closeOffer = () => {
    sendEvent(
      discountPopUpCloseEvent({
        discount_popup_status: 'discount_cancelled',
        discount_type: churnOfferCoupon,
        ...cancelEventProps,
      }),
    );

    cancelSubscription();
  };

  if (isLoading || (cancelState === CancelSubscriptionState.CancelSuccess && !subscription?.endingAt)) {
    return (
      <Suspense fallback={null}>
        <Loader isFullPage />
      </Suspense>
    );
  }

  if (cancelState === CancelSubscriptionState.CancelSuccess && subscription?.endingAt) {
    return (
      <Suspense fallback={null}>
        <CancelSuccess />
      </Suspense>
    );
  }

  if (cancelState === CancelSubscriptionState.CancelFailed) {
    return (
      <Suspense fallback={null}>
        <CancelError />
      </Suspense>
    );
  }

  if (isOfferShown && !isOfferAccepted) {
    return (
      <Suspense fallback={null}>
        <CancellationOffer offer={offer as ChurnOffersStructureType} onClose={closeOffer} onAccept={acceptOffer} />
      </Suspense>
    );
  }

  if (isOfferAccepted) {
    return (
      <Suspense fallback={null}>
        <OfferAccepted />
      </Suspense>
    );
  }

  return (
    <CancelReasons
      cancelReasons={cancelReasons}
      cancelReason={cancelReason}
      cancelSubreason={cancelSubreason}
      otherReason={otherReason}
      setCancelReason={setCancelReason}
      setCancelSubreason={setCancelSubreason}
      setCancelSubreasonPosition={setCancelSubreasonPosition}
      setOtherReason={setOtherReason}
      onNextButtonClick={handleNextButtonClick}
    />
  );
};

export default CancelSubscription;
