import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type {
  PPActiclesCardType,
  PPUpdateActiclesCardType,
  SubjectStructureType,
  Locales,
  AppData,
} from '@albert/shared/types';
import { getLocaleFromUrl } from '@albert/shared/utils';

import type { CancelReasonType, ChurnOffersStructureType, Notification } from '../../types';
import { AuthenticationStatus } from '../../types';

export interface AppReducer {
  locale: Locales;
  authInitialized: AuthenticationStatus;
  selectedChild: number;
  articles: PPActiclesCardType[];
  updateArticles: PPUpdateActiclesCardType[];
  isArticlesLoaded: boolean;
  subjects: SubjectStructureType[];
  isSubjectsLoaded: boolean;
  churnOffers: ChurnOffersStructureType[];
  isChurnOffersLoaded: boolean;
  churnOfferCodes: string[];
  isChurnOfferCodesLoaded: boolean;
  cancelReasons: CancelReasonType[];
  isCancelReasonsLoaded: boolean;
  notifications: Notification[] | null;
  appsData: AppData[] | null;
  isSidemenuCollapsed?: boolean;
}

export const initialState: AppReducer = {
  locale: getLocaleFromUrl(),
  authInitialized: AuthenticationStatus.Initializing,
  selectedChild: 0,
  articles: [],
  updateArticles: [],
  isArticlesLoaded: false,
  subjects: [],
  isSubjectsLoaded: false,
  churnOffers: [],
  isChurnOffersLoaded: false,
  churnOfferCodes: [],
  isChurnOfferCodesLoaded: false,
  cancelReasons: [],
  isCancelReasonsLoaded: false,
  notifications: null,
  appsData: null,
  isSidemenuCollapsed: false,
};

const reducer = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAuthInitialized: (state, action: PayloadAction<AuthenticationStatus>) => {
      state.authInitialized = action.payload;
    },
    setLocale: (state, action: PayloadAction<Locales>) => {
      state.locale = action.payload;
    },
    setSelectedChild: (state, action: PayloadAction<number>) => {
      state.selectedChild = action.payload;
    },
    setArticles: (state, action: PayloadAction<PPActiclesCardType[]>) => {
      state.articles = action.payload;
    },
    setUpdateArticles: (state, action: PayloadAction<PPUpdateActiclesCardType[]>) => {
      state.updateArticles = action.payload;
    },
    setIsArticlesLoaded: (state, action: PayloadAction<boolean>) => {
      state.isArticlesLoaded = action.payload;
    },
    setSubjects: (state, action: PayloadAction<SubjectStructureType[]>) => {
      state.subjects = action.payload;
    },
    setIsSubjectsLoaded: (state, action: PayloadAction<boolean>) => {
      state.isSubjectsLoaded = action.payload;
    },
    setChurnOffers: (state, action: PayloadAction<ChurnOffersStructureType[]>) => {
      state.churnOffers = action.payload;
    },
    setIsChurnOffersLoaded: (state, action: PayloadAction<boolean>) => {
      state.isChurnOffersLoaded = action.payload;
    },
    setChurnOfferCodes: (state, action: PayloadAction<string[]>) => {
      state.churnOfferCodes = action.payload;
    },
    setIsChurnOfferCodesLoaded: (state, action: PayloadAction<boolean>) => {
      state.isChurnOfferCodesLoaded = action.payload;
    },
    setCancelReasons: (state, action: PayloadAction<CancelReasonType[]>) => {
      state.cancelReasons = action.payload;
    },
    setIsCancelReasonsLoaded: (state, action: PayloadAction<boolean>) => {
      state.isCancelReasonsLoaded = action.payload;
    },
    setNotifications: (state, action: PayloadAction<Notification[] | null>) => {
      state.notifications = action.payload;
    },
    setAppsData: (state, action: PayloadAction<AppData[] | null>) => {
      state.appsData = action.payload;
    },
    setSidemenuCollapsed: (state, action: PayloadAction<boolean>) => {
      state.isSidemenuCollapsed = action.payload;
    },
  },
});

export const actions = reducer.actions;

export default reducer.reducer;
