import { Bugsnag } from '@albert/shared/services';

interface ApiErrorDto {
  status: number;
  error: string;
  message: string;
}

interface SharedApiServiceOptions {
  defaultHeaders?: HeadersInit;
  dynamicHeaders?: () => Promise<HeadersInit>;
  onAuthError?: (err: Error) => Promise<void>;
}

export class SharedApiServiceError extends Error {
  readonly status: number;
  constructor(message: string, status: number) {
    super(message);
    this.status = status;
  }
}

export default class SharedApiService {
  private readonly baseUrl;
  private readonly defaultHeaders?: HeadersInit;
  private readonly dynamicHeaders?: () => Promise<HeadersInit>;
  private readonly onAuthError?: (err: SharedApiServiceError) => Promise<void>;

  constructor(options: SharedApiServiceOptions) {
    const baseUrl = process.env.VITE_API_URI ?? process.env.NEXT_PUBLIC_API_URL ?? process.env.NEXT_PUBLIC_API_URI;
    if (!baseUrl) {
      throw new Error('API_URL variable is not configured');
    }

    this.baseUrl = `${baseUrl}/v1`;
    this.defaultHeaders = options.defaultHeaders;
    this.onAuthError = options.onAuthError;
    this.dynamicHeaders = options.dynamicHeaders;
  }

  private async request<T>(url: string, options: RequestInit): Promise<T> {
    const headers = mergeHeaders(this.defaultHeaders, await this.dynamicHeaders?.(), options.headers);

    const res = await fetch(`${this.baseUrl}${url}`, { ...options, headers });

    // Throw error if response is not ok
    if (!res.ok) {
      const error = await res
        .json()
        .then((dto: ApiErrorDto) => new SharedApiServiceError(dto.message, res.status))
        .catch(() => new SharedApiServiceError(res.statusText, res.status));

      if (res.status === 401 && this.onAuthError) {
        await this.onAuthError(error);
      }

      Bugsnag.notify(error);
      throw error;
    }

    if (res.status === 204) {
      return {} as T;
    }

    // Parse response as JSON.
    try {
      if (res.headers.get('Content-Type')?.includes('application/json')) {
        const data = await res.json();
        return data;
      } else {
        const data = await res.text();
        return data as T;
      }
    } catch (error) {
      if (error instanceof Error) {
        Bugsnag.notify(error);
      }

      throw error;
    }
  }

  async get<ResDto>(url: string, options: RequestInit = {}): Promise<ResDto> {
    return this.request(url, { method: 'GET', ...options });
  }

  async post<ResDto, ReqDto = any>(url: string, data: ReqDto, options: RequestInit = {}): Promise<ResDto> {
    return this.request(url, {
      method: 'POST',
      ...options,
      headers: { 'Content-Type': 'application/json', ...options.headers },
      body: JSON.stringify(data),
    });
  }

  async put<ResDto, ReqDto = any>(url: string, data: ReqDto, options: RequestInit = {}): Promise<ResDto> {
    return this.request(url, {
      method: 'PUT',
      ...options,
      headers: { 'Content-Type': 'application/json', ...options.headers },
      body: JSON.stringify(data),
    });
  }

  async patch<ResDto, ReqDto = any>(url: string, data: ReqDto, options: RequestInit = {}): Promise<ResDto> {
    return this.request(url, {
      method: 'PATCH',
      ...options,
      headers: { 'Content-Type': 'application/json', ...options.headers },
      body: JSON.stringify(data),
    });
  }

  async delete<ResDto>(url: string, options: RequestInit = {}): Promise<ResDto> {
    return this.request(url, { method: 'DELETE', ...options });
  }
}

const mergeHeaders = (...sources: (HeadersInit | undefined)[]): Headers => {
  const result: Record<string, string> = {};
  for (const source of sources) {
    new Headers(source).forEach((value, key) => {
      if (value !== undefined && value !== null) {
        result[key] = value;
      }
    });
  }

  return new Headers(result);
};
