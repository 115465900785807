import type { BillingInterval } from '@albert/shared/types';

import type { UserSubscriptionStatus } from './subscription';

export interface ChurnOffersStructureType {
  id: string;
  alias: string;
  userStatus: UserSubscriptionStatus;
  billingPeriod: BillingInterval;
  offerCode: {
    code: string;
  };
  offerSubtitle: string;
  offerText: string;
  offerTitle: string;
}

export interface SubreasonsListType {
  id: string;
  subreasonCode: string;
  subreasonText: string;
}

export interface CancelReasonType {
  id: string;
  mixpanelName: string;
  reasonCode: number;
  reasonText: string;
  subreasonsList: SubreasonsListType[];
}

export enum CancelSubscriptionState {
  CancelSuccess = 'cancel_success',
  CancelFailed = 'cancel_failed',
}

export enum CancelReasonCode {
  OtherReason = 11,
}
