import CN from 'clsx';
import dayjs from 'dayjs';
import { orderBy } from 'lodash';
import { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';
import type { TransformedProgress } from 'types';

import { OkayImage, SleepImage, StarImage } from '../..';
import styles from './styles.module.scss';

interface Props {
  childName?: string;
  parentReportEnabled?: boolean;
  isJunior?: boolean;
  isNoChildren?: boolean;
  progress: TransformedProgress;
  isActiveUser: boolean;
}

interface History {
  bestBlockInPeriod: {
    average: number;
    blockName: string;
  } | null;
  worstBlockInPeriod: {
    average: number;
    blockName: string;
  } | null;
}

const WEEK = 7;
const MONTH = 30;

const History = ({ isActiveUser, childName, parentReportEnabled, isJunior, isNoChildren, progress }: Props) => {
  const { t } = useTranslation('parent');

  const getJuniorHistory = (period: number) => {
    const neededDates = orderBy(
      Object.keys(progress).filter((date) => dayjs().diff(dayjs(date), 'days') < period),
      [],
      ['desc'],
    );

    const history = neededDates.reduce<Record<string, Record<string, number>>>((acc, date) => {
      Object.entries(progress[date]).forEach(([blockName, item]) => {
        if (!acc[blockName]) {
          acc[blockName] = item;
        }
      });
      return acc;
    }, {});

    return Object.entries(history).reduce<History>(
      (acc, [blockName, item]) => {
        const allExercisesLength = Object.values(item).length;
        const unfinishedExercises = Object.values(item).filter((i) => !i).length;
        const finished = allExercisesLength - unfinishedExercises;

        // If at least 30% of exercises in block are finished
        if (finished / allExercisesLength > 0.3) {
          const average = Object.values(item).reduce((acc: number, item) => (item ? acc + item : acc), 0) / finished;

          if (!acc.bestBlockInPeriod || acc.bestBlockInPeriod.average < average) {
            acc.bestBlockInPeriod = {
              blockName,
              average,
            };
          }

          if (!acc.worstBlockInPeriod || acc.worstBlockInPeriod.average > average) {
            acc.worstBlockInPeriod = {
              blockName,
              average,
            };
          }
        }
        return acc;
      },
      { bestBlockInPeriod: null, worstBlockInPeriod: null },
    );
  };

  const getHistory = (): { history: History | null; hasLastWeekData: boolean } => {
    if (isActiveUser) {
      const historyPerWeek = getJuniorHistory(WEEK);

      if (historyPerWeek.bestBlockInPeriod || historyPerWeek.bestBlockInPeriod) {
        return {
          history: historyPerWeek,
          hasLastWeekData: true,
        };
      }

      const historyPerMonth = getJuniorHistory(MONTH);
      if (historyPerMonth.bestBlockInPeriod || historyPerMonth.worstBlockInPeriod) {
        return {
          history: historyPerMonth,
          hasLastWeekData: false,
        };
      }
    }

    return {
      history: null,
      hasLastWeekData: false,
    };
  };

  const { hasLastWeekData, history } = getHistory();

  // If user has BAS subscription
  if (!parentReportEnabled) {
    return (
      <div className={styles.progressCard}>
        <h2 className={styles.cardTitle}>{t('parentReport.history.title_1')}</h2>
        <div className={styles.history}>
          <div className={styles.noHistory}>
            <StarImage />
            <h3>{t('parentReport.history.basABTest')}</h3>
          </div>
        </div>
      </div>
    );
  }

  // If user has no children
  if (isNoChildren) {
    return (
      <div className={styles.progressCard}>
        <h2 className={styles.cardTitle}>{t('parentReport.history.title_1')}</h2>
        <div className={styles.history}>
          <div className={styles.noHistory}>
            <StarImage />
            <h3>{t('parentReport.history.noChildren')}</h3>
          </div>
        </div>
      </div>
    );
  }

  // If user has a Teen App
  if (!isJunior) {
    return (
      <div className={styles.progressCard}>
        <h2 className={styles.cardTitle}>{t('parentReport.history.title_1')}</h2>
        <div className={styles.history}>
          <div className={styles.noHistory}>
            <SleepImage />
            <h3>{t('parentReport.history.teen')}</h3>
          </div>
        </div>
      </div>
    );
  }

  // If user has PLUS subscription but no activity in App
  if (!isActiveUser) {
    return (
      <div className={styles.progressCard}>
        <h2 className={styles.cardTitle}>{t('parentReport.history.title_1')}</h2>
        <div className={styles.history}>
          <div className={styles.noHistory}>
            <SleepImage />
            <h3>
              {t('parentReport.noData', {
                name: childName,
              })}
            </h3>
          </div>
        </div>
      </div>
    );
  }

  // If user has PLUS subscription but no activity in App for the last month
  if (isActiveUser && history === null) {
    return (
      <div className={styles.progressCard}>
        <h2 className={styles.cardTitle}>{t('parentReport.history.title_2')}</h2>
        <div className={styles.history}>
          <div className={styles.noHistory}>
            <OkayImage />
            <h3>
              {t('parentReport.history.noDataThisWeek', {
                name: childName,
              })}
            </h3>
          </div>
        </div>
      </div>
    );
  }

  // Default render — if user has PLUS subscription and has activity in App
  return (
    <div className={styles.progressCard}>
      <h2 className={styles.cardTitle}>
        {hasLastWeekData ? t('parentReport.history.title_1') : t('parentReport.history.title_2')}
      </h2>
      {history?.bestBlockInPeriod?.average && history?.worstBlockInPeriod?.average && (
        <Fragment>
          {history?.worstBlockInPeriod.average > 50 && (
            <div className={CN(styles.history, styles.oneItem)}>
              <div className={styles.historyItem}>
                <div className={CN(styles.image, styles.good)}>
                  <span />
                </div>
                <div className={styles.text}>
                  <h1>{history?.bestBlockInPeriod.blockName}</h1>
                  <p>{t('parentReport.history.result.good')}</p>
                </div>
              </div>
            </div>
          )}
          {history?.bestBlockInPeriod.average >= 50 && history?.worstBlockInPeriod.average <= 50 && (
            <div className={styles.history}>
              <div className={styles.historyItem}>
                <div className={CN(styles.image, styles.good)}>
                  <span />
                </div>
                <div className={styles.text}>
                  <h1>{history?.bestBlockInPeriod.blockName}</h1>
                  <p>{t('parentReport.history.result.good')}</p>
                </div>
              </div>
              <div className={styles.historyItem}>
                <div className={CN(styles.image, styles.ok)}>
                  <span />
                </div>
                <div className={styles.text}>
                  <h1>{history?.worstBlockInPeriod.blockName}</h1>
                  <p>{t('parentReport.history.result.ok')}</p>
                </div>
              </div>
            </div>
          )}
          {history?.bestBlockInPeriod.average <= 50 && (
            <div className={CN(styles.history, styles.oneItem)}>
              <div className={styles.historyItem}>
                <div className={CN(styles.image, styles.ok)}>
                  <span />
                </div>
                <div className={styles.text}>
                  <h1>{history?.worstBlockInPeriod.blockName}</h1>
                  <p>{t('parentReport.history.result.ok')}</p>
                </div>
              </div>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default memo(History);
